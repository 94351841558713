.buttonFilled {
    border-radius: var(--g-input-radius);

    font-size: 1.6rem;

    background: var(--g-color-primary);
    border: 2px solid transparent;
    color: white;
    cursor: pointer;
    -webkit-appearance: none;

    transition: filter 250ms ease-in-out;
}

.buttonFilled:hover {
    border-color: transparent;
    filter: brightness(90%);
}

.buttonFilled:active {
    border-color: transparent;
    filter: brightness(110%);
}

.buttonFilled:disabled {
    background: var(--g-border-color);
    border-color: transparent;

    cursor: not-allowed;
}

.buttonOutlined {
    border-radius: var(--g-input-radius);

    font-size: 1.6rem;

    background: white;
    border: 2px solid var(--g-color-primary);
    color: var(--g-color-primary);
    cursor: pointer;
    -webkit-appearance: none;

    transition: background-color 250ms ease-in-out, border-color 250ms, color 250ms;
}

.buttonOutlined:hover {
    background: var(--g-color-primary);
    border: 2px solid transparent;
    color: white;
}

.buttonOutlined:active  {
    filter: brightness(110%);
}

.buttonOutlined:disabled {
    background: var(--g-border-color);
    border-color: var(--g-color-primary);
    color: var(--g-color-primary);

    cursor: not-allowed;
}

.filled {
    width: 100%;
}

.defaultPadding {
    padding: .8rem 1.6rem;
}

/* Primary color */

.buttonFilled.colorPrimary {
    background: var(--g-color-primary);
}

.buttonOutlined.colorPrimary {
    color: var(--g-color-primary);
    border-color: var(--g-color-primary);
}

.buttonOutlined:hover.colorPrimary {
    color: white;
    background: var(--g-color-primary);
}

/* Success color */

.buttonFilled.colorSuccess {
    background: var(--g-color-success);
}

.buttonOutlined.colorSuccess {
    color: var(--g-color-success);
    border-color: var(--g-color-success);
}

.buttonOutlined:hover.colorSuccess {
    color: white;
    background: var(--g-color-success);
}

/* Warning color */

.buttonFilled.colorWarning {
    background: var(--g-color-warning);
    //border: 2px solid var(--g-color-warning-hover);
}

.buttonOutlined.colorWarning {
    color: var(--g-color-warning);
    border-color: var(--g-color-warning);
}

.buttonOutlined:hover.colorWarning {
    color: white;
    background: var(--g-color-warning);
}

/* Failure color */

.buttonFilled.colorFailure {
    background: var(--g-color-failure);
    //border: 2px solid var(--g-color-failure-hover);
}

.buttonOutlined.colorFailure {
    color: var(--g-color-failure);
    border-color: var(--g-color-failure);
}

.buttonOutlined:hover.colorFailure {
    color: white;
    background: var(--g-color-failure);
}

/* Neutral color */

.buttonFilled.colorNeutral {
    color: var(--g-font-color);
    background: var(--g-panel);
}

.buttonOutlined.colorNeutral {
    color: var(--g-font-color);
    border: 2px solid var(--g-font-color);
}

.buttonOutlined:hover.colorNeutral {
    color: var(--g-font-color);
    background: var(--g-color-neutral-dark);
    border-color: var(--g-color-neutral-dark);
}

.buttonOutlined:disabled.colorNeutral {
    border-color: transparent;
}

/* All */

.buttonFilled:disabled {
    background: var(--g-border-color);
    border-color: transparent;

    cursor: not-allowed;
}

/* Failure color */

.buttonFilled.colorBlack {
    background: black;
    color: white;
}

.buttonOutlined.colorBlack {
    color: var(--g-color-failure);
    border-color: var(--g-color-failure);
}

.buttonOutlined:hover.colorBlack{
    color: white;
    background: var(--g-color-failure);
}