.forgot_password {
  color: blue;
  margin-left: 1rem;
  cursor: pointer;
}

.iconWithText {
  display: flex;
  align-items: center;
  box-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 3rem;
  margin-top: 1rem;
}
.iconWithText > div {
  margin-left: 5px; /* Adjust this value to move the text further from the icon */
  margin-right: 5px;
}

.info {
  color: gray;
}

.icon {
  width: 40px;
  height: 23px;
  background: url("../../../../../../theme/resource/icon/password.svg");
  background-repeat: no-repeat;
}

.hrStyle {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
