.container {
    display: block;
    position: relative;
    overflow: hidden;
}

.section {
    display: flex;
    width: 100%;
    max-width: 110rem;
    height: calc(100vh - 20px - 87px - 10px);
    margin-bottom: 10rem;
}

.bottom_decoration {
    position: absolute;
    bottom: -50%;
    right: -20rem;
    width: 100rem;
    height: 100rem;
    background-image: url("../../../../theme/resource/image/landing/bottom_decoration.png");
    background-size: contain; /* Ensures the image maintains its aspect ratio */
    background-repeat: no-repeat;
    pointer-events: none; /* Prevents the image from blocking UI elements */
    z-index: 0;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.inner {
    display: block;
    width: 100%;
}


/* Media Query for screens smaller than 1000px */
@media (max-width: 1000px) {
    .section {
        flex-direction: column;
        height: auto;
        margin-bottom: 4rem;
    }

    .box {
        width: 100%;
        height: auto;
    }
}