.item {
    margin-right: 1rem;
    margin-left: 1rem;
    padding-bottom: 1rem;

    .content {
        width: 100%;
        text-align: left;
        height: 4rem;
        display: inline-block;
        transition: opacity .2s ease-in-out;
        cursor: pointer;
        border-radius: 10px;
        padding-top: .75rem;
        padding-right: .75rem;
    }
}

@media only screen and (min-width: 735px) {
    .selected {
        background-color: var(--g-color-primary);
        color: white;
    
    }

    .selected.icon::before{
        filter: brightness(0) invert(1);
    }
}

.icon_profile::before{
    background: url('../../../../../../theme/resource/icon/compass.svg');
}

.icon_affiliate:before{
    background: url('../../../../../../theme/resource/icon/link_gray.svg');
}

.icon_billing::before{
    background: url('../../../../../../theme/resource/icon/billing.svg');
}

.icon_notifications::before{
    background: url('../../../../../../theme/resource/icon/notification.svg');
}

.icon_mail::before{
    background: url('../../../../../../theme/resource/icon/mail.svg');
}

.icon_password::before{
    background: url('../../../../../../theme/resource/icon/password.svg');
    scale: 0.9;
    margin-left: -.5rem;
}

.icon_blocked::before {
    background: url('../../../../../../theme/resource/icon/block.svg');
}

.icon_close::before{
    background: url('../../../../../../theme/resource/icon/delete.svg');
}

.icon_networks::before{
    background: url('../../../../../../theme/resource/icon/network.svg');
}

.icon {
    padding-left: 1.25rem;
}

.icon::before {
    margin-top: .1rem;
    height: 2.2rem;
    width: 2.2rem;
    background-repeat: no-repeat;
    padding-left: 1rem;
}

.icon::after{
    background: none;
}

@media only screen and (max-width: 735px) {
    .icon::after{
        background: url('../../../../../../theme/resource/icon/chevron.svg');
        margin-left: 1rem;
        margin-top: .1rem;
        display: block;
        float:right;
        height: 2rem;
        width: 2rem;
    }
}