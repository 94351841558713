@import './font/index.scss';

* {
    font-family: 'Heebo', 'Roboto', 'Manrope', sans-serif;
    font-weight: 500;
    color: var(--g-font-color)
}

.uppercase {
    text-transform: uppercase;
}

h1 {
    font-weight: 600;
    font-size: 3.4rem
}

h2, .title {
    display: inline-block;
    width: 100%;
    font-size: 2.5rem;
    font-weight: 800;
    color: var(--g-label-color);
}