.profile {
    margin-bottom: -1.5rem;
}


.main {
    width: 60%;
    float: left;
    padding-right: 4rem;
}

.sidebar {
    width: 40%;
    float: right;
}

@media all and (max-width: 850px) {
    .main,
    .sidebar {
        padding: 0;
        width: 100%;
    }
}

/* See facebook as reference */
@media all and (max-width: 850px) {
    .profile {
        max-width: 500px;
    }
}