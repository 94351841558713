@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../../resource/font/heebo/Heebo-Thin.ttf);
}

@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../../resource/font/heebo/Heebo-ExtraLight.ttf);
}

@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../resource/font/heebo/Heebo-Light.ttf);
}

@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../resource/font/heebo/Heebo-Regular.ttf);
}

@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../../resource/font/heebo/Heebo-Medium.ttf);
}

//@font-face {
//    font-family: 'Heebo';
//    font-style: normal;
//    font-weight: 600;
//    font-display: swap;
//    src: url(../../resource/font/heebo/Heebo-SemiBold.ttf);
//}

@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../resource/font/heebo/Heebo-Bold.ttf);
}

@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../../resource/font/heebo/Heebo-ExtraBold.ttf);
}

@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../../resource/font/heebo/Heebo-Black.ttf);
}