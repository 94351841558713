.container {
    border-radius: 15px;
    margin-top: 1rem;
    box-shadow: var(--g-regular-shadow);
    height: 40rem;
    max-width: 69rem;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: white;
}

.panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0rem;
    width: 80%;
    margin-top: 2rem;
    border-radius: 16px;
    padding-bottom: 5rem;
    padding-top: 2.5rem;
    @media (max-width: 800px) {
        width: 100%
    }
}

//this should have astyling for a heaer text
.header {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: var(--g-primary-color);
    text-align: center;
    width: 66%;
    @media (max-width: 800px) {
        font-size: 2rem;
        width: 80%;
    }
}

.topText {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #9b9a9a;
    text-align: center;
    width: 70%;
    font-weight: 375;
    @media (max-width: 800px) {
        font-size: 1.5rem;
        width: 90%;
    }
}

.title_container {
    max-width: 69rem;
    width: 100%;
}

.button_container {
    text-align: left;
    margin-left: 1rem;
}

.info_container {
    text-align: left;
    margin: 1rem;
}

.plansGrid {
    grid-template-columns: repeat(
        auto-fill,
        minmax(250px, 1fr)
    ); // Creates a responsive grid with columns that have a minimum width of 250px
    gap: 20px; // Spacing between grid items
    margin-top: 20px; // Space above the grid
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; // This centers the content vertically if there's enough space
    text-align: center;
    @media (max-width: 800px) {
        flex-direction: column;
    }
}
