.unsubscribePanel {
    background-color: white;
    padding: 25px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
    text-align: left;
    line-height: 2;

    h2 {
        margin-bottom: 20px;
    }

    .emailBox {
        background-color: #f0f0f0;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 15px;
        font-weight: bold;
    }

    p {
        margin: 10px 0;
    }

    a {
        color: #007bff;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    .unsubscribeContainer {
        text-align: right;

        .unsubscribeButton {
            background-color: #007bff;
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;

            &:hover {
                background-color: #0056b3;
            }
        }
    }


}
