/* join-animation.module.scss */
.enter {
    transform: translateX(130%);
    position: absolute;
    top: 0;
}

.enterActive {
    transform: translateX(0%);
    transition: all 500ms ease;
    position: static;
}

.exit {
    transform: translateX(0%);
    transition: all 500ms ease, opacity 200ms;
    opacity: 1;
    position: absolute;
    top: 0;
}

.exitActive {
    transform: translateX(-130%);
    transition: all 500ms ease;
    position: absolute;
    opacity: 0;
}

/* New classes for return animation */
.enterReverse {
    transform: translateX(-130%);
    position: absolute;
    top: 0;
}

.enterActiveReverse {
    transform: translateX(0%);
    transition: all 500ms ease;
    position: static;
}

.exitReverse {
    transform: translateX(0%);
    transition: all 500ms ease, opacity 200ms;
    opacity: 1;
    position: absolute;
    top: 0;
}

.exitActiveReverse {
    transform: translateX(130%);
    transition: all 500ms ease;
    position: absolute;
    opacity: 0;
}
