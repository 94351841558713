.filter_element {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-top: 1px solid;
    cursor: pointer;
}

.dropdown {
    width: 20rem;
    cursor: pointer;
    border: 2px solid var(--g-border-color);
    color: black;
    font-size: 1.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    transition: border-color 0.2s ease-in-out;
    text-align: left;
    border-radius: 2rem;
}

.filter {
    color: black;
    font-size: 1.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    transition: border-color 0.2s ease-in-out;
    text-align: left;
    border-radius: 2rem;

    @media (max-width: 800px) {
        width: 27rem;
    }
}
