.name {
    position: absolute;
    left: 20.8rem;
    top: 1.1rem;
    font-weight: bold;
    font-size: 2.56rem;
}

.name input {
    margin-top: -.2rem;
    margin-left: -1rem;
    padding: 0 .8rem;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    font-size: 2.56rem;
    transition: border-color .2s ease-in-out;
}

.name input:focus {
    border-color: var(--g-color-primary);
}

@media all and (max-width: 850px) {
    .name {
        display: block;
        padding-top: 2rem;
        position: static;
        text-align: center;
    }

    .name input {
        text-align: center;
        margin-bottom: -.4rem;
        margin-left: 0;
    }
}