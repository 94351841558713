.container {
    border-bottom: black solid 1px;
    margin-bottom: 1rem;
    padding: 1rem;
    cursor: pointer;
    font-size: 1.5rem;

}
.selected {
    background-color: #d0e7ff;
    border-color: #007bff;
}

.container:hover {
    background-color: #d0e7ff;
    border-color: #007bff;
}

.topic {
    max-width: 65%;

}

.reason {
    margin-right: 1rem;
    font-weight: bolder;
    color: var(--g-color-blue)
}

.date {
    float: right;
}


