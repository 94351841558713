.avatar_container {
    width: 22rem;
    height: 22rem;
    border-radius: 25px;
    flex-shrink: 0;
    flex-grow: 0;
    background-size: cover;
    background-position: top;
    transition: transform 0.3s ease;
    display: flex;
}

.avatar{
    width: 100%;
    height: 100%;
    border-radius: 25px;
    flex-shrink: 0;
    flex-grow: 0;
    background-size: cover;
    background-position: top;
    transition: transform 0.3s ease;
}

.avatar img {
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.premium {
    margin-left: .3rem;
}

.header {
    text-align: left;
    display: flex;
}


@media (max-width: 800px) {
    .header{
        flex-direction: column;
        width: 100%;
    }
}

.name {
    color: var(--g-font-color);
    margin: .5rem;
    margin-top: 0;
    font-size: 24px !important;
    font-weight: bolder !important;
    text-align: left;
    cursor: pointer;
    overflow: auto;
    white-space: wrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    margin-bottom: 8px;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.icon {
    font-size: 1.4rem;
    font-weight: 450;
    margin-right: 1rem;
    height: 1rem;
}

.location {
    font-size: 1.4rem;
    font-weight: 450;
    margin-right: 1rem;
    height: 1.6rem !important;
    overflow: hidden;
}

.author {
    margin-right: 1rem;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    vertical-align: center;
}

.author:hover {
    cursor: pointer;
    text-decoration: underline;
}

.author::before {
    content: "";
    background: url("../../../../../../../../theme/resource/icon/user.svg");
    vertical-align: top;
    width: 2.5rem;
    margin-right: .5rem;
    height: 2.5rem;
    display: inline-block;
}

.info_bar {
    margin-left: 1.5rem;
    width: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.info_item {
    justify-content: flex-start !important;
    align-items: flex-start;
    text-align: left;
    width: 100% !important;
}

.info_item_labels {
    flex-wrap: wrap;
    gap: 4px 2px;
    flex-direction: row;
    justify-content: flex-start !important;
    text-align: left;
    width: 100% !important;
    border-radius: 8px;
    align-items: start;
}

.product_placement{
    width: 1rem !important;
    height: 1rem !important;
    margin-top: 4px;
}

.product_placement::before {
    vertical-align: center;
    background: url("../../../../../../../../theme/resource/icon/placement.svg");
}

.influencer{
    width: 1rem !important;
    height: 1rem !important;
    margin-top: 4px;
}

.influencer::before {
    vertical-align: center;
    background: url("../../../../../../../../theme/resource/icon/influencer.svg");
}

.free_placement{
    width: 1rem !important;
    height: 1rem !important;
    margin-top: 4px;
}

.free_placement::before {
    background: url("../../../../../../../../theme/resource/icon/deal.svg");
}

.location::before {
    background: url("../../../../../../../../theme/resource/icon/location.svg");
}

.icon::before {
    width: 1.5rem;
    margin-left: 0.2rem;
    height: 1.5rem;
}

.keywords {
    overflow: hidden;
    display: flex;
    justify-content: flex-start !important; /* Align items to the left */
    align-items: flex-start !important;    /* Align items to the start of the container */
    flex-wrap: wrap;
    text-align: left;
    gap: 0 8px;
    padding-top: 8px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    width: fit-content !important;
    margin-left: 0;
    overflow: hidden;
    max-height: 33px;
}




.avatar_container:hover img {
    transform: scale(1.05);
}

.tooltip {
    display: inline-flex;
    background-color: var(--g-color-light-green);
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
    font-size: 1.25rem;
    margin-right: 8px;
    margin-top: 4px;
}


@container (max-width: 300px) {
    .name {
        font-size: medium;
    }
}

@media screen and (max-width: 800px) {
    .name {
        width: 100%;
        font-size: large;
        font-weight: 800;
    }
    .info_bar{
        width: 100%;
        margin-left: 0;
    }
    .avatar_container{
        width: 100%;
        height: auto;
    }
    .avatar{
        width: 100%;
        height: 100%;
        .img{
            width: 100%;
            height: 100%;
        }
    }
}
.target_group {
    font-weight: lighter;
    text-align: left;
    width: 100%;
    margin-top: 4px;
}

.description_short {
    margin-top: 12px;
    margin-left: .5rem;
    width: 100%;
    height: auto; /* Adjusted to fit content, can set specific height if needed */
    text-align: left;
    line-height: 1.5em; /* Example line height, adjust as needed */
    font-weight: 400;
}

.info_item_name{
    vertical-align: center !important;
    color: var(--g-color-green);
    margin-right: .25rem;
}