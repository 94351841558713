.options {
    margin-bottom: 2rem;
    cursor: pointer;

    span {
        color: rgb(50, 50, 50);
        padding: 1.2rem 1.6rem;
        display: block;
        font-weight: 800;
        width: 100%;
        text-align: left;
        height: 4.4rem;
        transition: all 350ms;
        border-radius: 5px;

        &:hover {
            background: rgba(0, 0, 0, .1);
        }
    }
}

.pre_icon::before {
    content: '';
    display: block;
    background: url('../../../theme/resource/icon/rank.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 2.24rem;
    height: 2.24rem;
    float: left;
    margin-top: 0rem;
    margin-right: 1.5rem;
}

.icon_block.pre_icon::before {
    background-image: url('../../../theme/resource/icon/blocked_gradient.svg');
}

.icon_report.pre_icon::before {
    background-image: url('../../../theme/resource/icon/report.svg');
}