.container {
    width: 100%;
    word-break: break-word;
    display: inline-block;
    box-shadow: var(--g-light-shadow);
    padding: 1rem 1.5rem;
    text-align: left;
    margin-bottom: 1rem;
    border-left: 3px solid var(--g-color-primary);
    border-radius: 1rem;
    cursor: pointer;
    transition: .25s ease all;

    .header {
        color: var(--g-font-color);
    }
}

.container:hover {
    box-shadow: 0 0 8px 0 rgba(0,0,0,.25) ;
}

.description {
    font-weight: normal;
    white-space: nowrap; /* Keeps the text in a single line */
    overflow: hidden; /* Hides any overflow */
    text-overflow: ellipsis; /* Adds an ellipsis to any text that overflows */
}

.sender {
    .container {
        background: var(--g-color-primary);
        color: var(--g-font-color);
        border-left: 3px solid white;

    }
    .description {
        color: white
    }
    .header {
        color: white;
    }
}
