.float-none { float: none }
.float-right { float: right }
.float-left { float: left }


.l-float-none { float: none }
.l-float-right { float: right }
.l-float-left { float: left }

@media only screen and (max-width: $g-medium) {
    .m-float-none { float: none }
    .m-float-right { float: right }
    .m-float-left { float: left }
}

@media only screen and (max-width: $g-small) {
    .s-float-none { float: none }
    .s-float-right { float: right }
    .s-float-left { float: left }
}