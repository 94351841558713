.overlay {
    display: block;
    position: fixed;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
    text-align: center;
    padding: 0 1.2rem;
    background-color: rgba(0, 0, 0, .8);
    backdrop-filter: blur(10px);
    --webkit-backdrop-filter: blur(10px);
    opacity: 0;
    transition: opacity .2s ease, width 0s .2s, height 0s .2s, z-index 0s .2s;
    overflow-y: scroll;
}

.show {
    opacity: 1;
    height: 100%;
    width: 100%;
    z-index: 1000;
    transition: opacity .2s ease-in;
}

.panel {
    position: relative;
    top: 15%;
    width: 100%;
    max-width: 44.8rem;
    text-align: left;
    margin-bottom: 30vh;
}

.title {
    font-size: 3.2rem;
    margin-right: 5.6rem;
    margin-top: -.8rem;
}

.close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: 2.32rem;
    height: 2.32rem;
    opacity: .3;
    background: url('../../resource/icon/close.svg');
    transition: opacity .2s ease-in-out;
    cursor: pointer;
}

.close:hover {
    opacity: 1;
}