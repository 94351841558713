.premium_badge {
    background-size: cover;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    top: .2rem;
    margin-left: .3rem;
    background: url("../../resource/icon/premium_badge.svg");

}

.tooltip {
    z-index: 10000 !important;
    font-size: medium !important;
    margin-top: 0.8rem;
}
