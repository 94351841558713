.overlay {
    display: inline-block;
    //background-color: rgb(230, 80, 80);
    background-color: white;
    transition: width .5s ease-in-out, max-width .5s ease-in-out;
    z-index: 100;
    position: absolute;
    left: 7rem;

    width: 20rem;
}



form {
    display: inline-block;
    margin: 0;
    transition: width .5s ease, max-width .5s ease;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 32rem;
}

form:focus-within {
    width: 100%;
    max-width: 40rem;
}

form input {

    //background: var(--g-color-background);


    //background: rgba(151, 173, 192, 0.15);
    //background: #f4f4f9;
    background: rgb(240, 242, 245);

    border: 10px solid rgb(53, 47, 47);

    border-radius: 9px;
    border: none;
    //border: 2px solid rgb(236, 236, 236);
    width: 100%;
    padding: .7rem 1.12rem;

    transition: background-color .25s ease-in-out;
}

form input[type=text]:hover {
    background: rgb(230, 230, 230)
}

form button,
form button:active {
    position: absolute;
    width: 3.2rem;
    padding: 1.6rem;
    
    border: none;

    right: 0;

    height: 100%;

    background: none;
}

form button::after {
    content: '';
    position: absolute;
    top: 1.35rem;
    right: .95rem;

    width: 1.28rem;
    height: 1.28rem;
    
    background-image: url('../../../../../theme/resource/icon/go.svg');
    opacity: .2;

    transition: all .2s ease-in-out;
}


form button:hover::after {
    content: '';
    opacity: .8;
}