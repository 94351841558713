.chatImageUpload {
    position: absolute;
    right: 4rem;
    bottom: 1.5rem;
    width: 3rem;
    height: 3rem;
    opacity: 0.7;
    transition: opacity 250ms ease-in-out;
    border-radius: 100%;
    background: rgb(255, 255, 255);
    cursor: pointer;

    &::after {
        content: '';
        display: inline-block;
        background-image: url('../../../../theme/resource/icon/camera.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        opacity: 1;
    }

    input {
        display: none;
    }
}

.chatImageUploadEdge {
    @extend .chatImageUpload;
    border-radius: 0 0 50% 0;
}

.inputContainer {
    position: relative;
    display: flex;
    align-items: flex-end;
}

.imagePreview {
    margin-top: 0.5rem;
    max-width: 100%;

    img {
        max-width: 100%;
        max-height: 200px;
        object-fit: contain;
        border-radius: 8px;
    }
}

@media all and (max-width: 850px) {
    .chatImageUploadEdge {
        position: absolute;
        left: 0;
        bottom: 100%;
        transform: translateY(50%);
    }
}