.logo{
    position: absolute;
    left: 1rem;
    top: .75rem;
}

.item {
    display: inline-block;
    margin: 0;
    height: 100%;
    width: 3.6rem;
    text-align: left;
    cursor: pointer;
}

.current {
    filter: opacity(100%);
}

.home {
    width: 4rem;
    height: 4rem;
    background: url("../../../../../theme/resource/icon/influspace.svg");
}