.safe_area {
    padding-top: env(safe-area-inset-top, 0);
    background: #ffffff;
}

.navbar {
    position: sticky;
    border-top: 0;
    top: 0;
    left: 0;


    width: 100%;
    height: 5.5rem;

    z-index: 1000;

    /*margin-top: -1.6rem;*/
    margin-bottom: 0;
    border-radius: 0;

    padding: 0;


    text-align: center;


    @media all and (max-width: 850px) {
        position: relative;

        padding-left: .75rem;
        padding-right: .75rem;
        width: 100%;
        margin: 0;
    }

}

.displayname {
    cursor: default;
    margin-top: .6rem;
    transition: color .2s ease-in-out;
    @media all and (max-width: 850px) {
      font-size: 2.5rem;
        margin-top: .9rem;
    }
}

.navbar:hover .displayname {
    color: black;
}

.avatar {
    display: inline-block;
    position: absolute;
    width: 6rem;
    height: 6rem;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;

    transition: opacity .2s ease-out;
    cursor: pointer;
}

.avatar img {
    display: inline-block;
    position: absolute;
    right: 1rem;
    top: .5rem;
    width: 4.5rem;
    height: 4.5rem;
    background: var(--g-color-primary);
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid white;
    transition: border-color .2s ease-in-out;
}

.avatar:hover img {
    border: 3px solid var(--g-color-primary);
}

.back_button {
    display: inline-block;
    position: absolute;
    width: 5.5rem;
    height: 5.5rem;
    left: 0;
    top: 0;
    opacity: .5;
    transition: opacity .2s ease-out;
    cursor: pointer;
}

.back_button:hover {
    opacity: 1;
}

.back_button span {
    background: url('../../../../theme/resource/icon/chevron.svg');
    width: 3rem;
    height: 3rem;
    transform: rotate(180deg);
    margin-top: 1.25rem;
}

.premium_badge {
    scale: 1.5;
    margin-left: .8rem;
    top: 0rem;
}