.container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 30px #0000001a;
    border-radius: 34px;
    width: fit-content;
    cursor: pointer;
    padding: 1rem;
}

.option {
    border-radius: 28px;
    background: #ffffff;
    user-select: none;
    font-weight: bold;
    padding: 1rem 4rem;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out; /* Add this line */
}
