.container {
    text-align: center;
    margin-bottom: 10rem;
    max-width: 140rem;
    margin-top: 15rem;

    h2 {
        font-weight: 600;
        color: var(--g-font-color);
    }

    @media (max-width: 1300px) {
        margin-top: 0rem;
    }
}

.support_bar {
    width: 100%;
    margin-top: 2rem;
    display: flex; /* Aktiviert Flexbox für diesen Container */
    justify-content: space-around; /* Verteilt die Span-Elemente gleichmäßig im Container */
    @media (max-width: 800px) {
        flex-direction: column;
    }
}

.support_tile {
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 25px 45px #00000027;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2), 0 8px 16px rgba(0, 0, 0, .1);
    border-radius: 25px;
    padding: 3rem;
    transition: transform 0.2s ease-in-out;
    width: 30rem;
    &:hover {
        transform: translateY(-0.5rem);
    }
    @media (max-width: 800px) {
        margin-top: 3rem;
    }
}

.icon_letter {
    background: url("../../../../theme/resource/icon/letter_gradient.svg");
    width: 4rem;
    height: 4rem;
}

.icon_chat {
    background: url("../../../../theme/resource/icon/support_chat_gradient.svg");
    width: 4rem;
    height: 4rem;
}

.support_header {
    margin-top: 2rem;
    font-weight: bolder;
}

.support_description {
    margin-top: 1.5rem;
    font-size: 1.3rem;
}

.icon_faq {
    width: 4rem;
    height: 4rem;
    -webkit-mask: url("../../../../theme/resource/icon/questionmark.svg") no-repeat center;
    mask: url("../../../../theme/resource/icon/questionmark.svg") no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
    background: transparent linear-gradient(167deg, #00c0fc 0%, #03ff00 100%);;
}