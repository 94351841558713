.menu {
    position: absolute;
    z-index: 1;
    bottom: 0;
}

.menu li {
    display: inline-block;
    margin-right: 2rem;
    transition: opacity .2s ease-in-out;
    cursor: pointer;
    opacity: .75;
    
}

.menu li:hover {
    opacity: 1;
}

.icon::before {
    opacity: .75;
}

.archive_icon::before{
    background: url('../../../../../../theme/resource/icon/archive.svg');
    height: 1.65rem;
    width: 1.65rem;
    margin-top: .3rem;
}

.block_icon::before{
    background: url('../../../../../../theme/resource/icon/block.svg');
    height: 1.8rem;
    width: 1.8rem;
    margin-top: .15rem;
}

.report_icon::before{
    background: url('../../../../../../theme/resource/icon/report.svg');
    height: 1.8rem;
    width: 1.8rem;
    margin-top: .15rem;
}

.delete_icon::before{
    background: url('../../../../../../theme/resource/icon/delete.svg');
    height: 1.8rem;
    width: 1.8rem;
    margin-top: .15rem;
}



@media only screen and (max-width: 735px) {
    .menu_toggle {
        position: absolute;
        top: 0;
        right: -.75rem;
        padding: .5rem 1rem;
        opacity: .75;
        transition: opacity .2s ease-in-out;
    }

    .menu_toggle:hover {
        opacity: 1;
    }

    .menu_toggle span {
        background: url('../../../../../../theme/resource/icon/dots.svg');
        height: 2rem;
        width: 2rem;
    }

    .menu {
        position: absolute;
        top: 3.5rem;
        right: .75rem;
        bottom: auto;
        background: white;
        border-radius: var(--g-input-radius);
        box-shadow: 0px 2px 10px 0px rgba(0,0,0,.25);
        padding: .75rem .75rem;
        display: inline-block;
    }
    
    .menu li {
        display: block;
        padding: .6rem 1.5rem;
        border-radius: var(--g-input-radius);
        transition: background .2s ease-in-out;
        margin-right: 0;
    }
    
    .menu li:hover {
        background: var(--g-color-background);
    }

    .icon::before {
        margin-right: 1rem;
    }

    .closed {
        display: none;
    }
}

.highlighted {
    color: var(--g-color-failure);
}