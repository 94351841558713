.panel {
    border-radius: var(--g-image-radius);
    height: 8rem;
    position: relative;
    color: var(--g-font-color);
    background: white;
    box-shadow: var(--g-regular-shadow);
    overflow-wrap: break-word;
    text-align: left;
    margin: 0 1.5rem;
    margin-bottom: 1.5em;
    border: 3px solid white;
    cursor: pointer;
}

.disabled {
    opacity: .5;
}

.selected {
    border-color: var(--g-color-primary);
    background: var(--g-color-primary);

    .displayname, .latest_message {
        color: white;
    }
}

.details {
    position: absolute;
    max-width: 100%;
    width: calc(100% - 10.5rem);
    height: 6.5rem;
    bottom: 0;
    top: 0;
    margin: auto 0;
    left: 9rem;
}

.displayname {
    font-size: 1.75rem;
}

.latest_message {
    font-size: 1.6rem;
    height: 2.7rem;
    overflow: hidden;
    font-weight: normal;
}

.chevron {
    background: url("../../../../../../theme/resource/icon/chevron.svg");

    height: 4rem;
    width: 4rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1rem;
    margin: auto 0;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
}

.panel:hover .chevron {
    opacity: 1;
}