.tag_view {
    border-radius: 1.5rem;
    padding: .1rem .8rem;
    margin-right: .6rem;
    border: 2px solid var(--g-font-color);
    border-color: var(--g-border-color);
    margin-bottom: .5rem;
}

.tag_view::before {
    content: '#';
}

.tag_view:hover {
    cursor: pointer;
    background-color: var(--g-font-color);
    border-color: var(--g-font-color);
    color: white;
}

.tag_edit {
    border-radius: 1.5rem;
    padding: .1rem .8rem;
    margin-right: .6rem;
    margin-top: .5rem;

    background-color: var(--g-font-color);
    color: white;
}
.keyword_input {
    padding: 0;
    background-color: var(--g-font-color);
    color: white;
}

.close {
    background: url('../../../../../../../theme/resource/icon/close_white.svg');
    height: 1.2rem;
    width: 1.2rem;
    margin-right: .5rem;
    cursor: pointer;
}

.close:hover {
    opacity: .5;
}