.create_button {
    width: 100%;
    margin-bottom: 2rem;
}

.menu {
    vertical-align: top;
    width: 26%;
    text-align: left;
    margin-right: 4%;
}

.offers {
    width: 70%;
}

.filter_panel {
    padding: 0;
    width: 100%;
    border: 1px solid;
}

.filter_header {
    font-weight: bold;
    padding: 1rem;
    //lower border fat
}

.filter_element {
    padding: 1rem 1rem 1rem 3rem;
    border-top: 1px solid;
}

@media (max-width: 800px) {
    .menu {
        vertical-align: top;
        width: 100%;
        text-align: left;
        margin-right: 4%;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .offers {
        width: 100%;
    }
}
