.panel {
    padding: 3rem 5rem;
    @media (max-width: 800px) {
        padding: 1rem 2.5rem;
    }

    h2 {
        color: var(--g-font-color);
        opacity: .8;
    }

    p {
        margin-top: 0rem;
        line-height: 1.9;
    }
}
