@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../resource/font/roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../../resource/font/roboto/Roboto-Italic.ttf);
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../../resource/font/roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../../resource/font/roboto/Roboto-MediumItalic.ttf);
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../../resource/font/roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../../resource/font/roboto/Roboto-BoldItalic.ttf);
}