.characterCount {
    color: var(--g-label-color);
}

.sendButton {
    padding: .8rem 3.2rem;
}

.connect_cta {
    flex-direction: column; /* Vertikal anordnen */
}

.connect_cta_container {
    min-width: 40%;
}