@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-Thin.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-ThinItalic.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-ExtraLight.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-ExtraLightItalic.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-LightItalic.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-Italic.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-MediumItalic.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-SemiBoldItalic.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-BoldItalic.ttf);
}


@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-ExtraBoldItalic.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-Black.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(../../resource/font/poppins/Poppins-BlackItalic.ttf);
}