.filter_panel {
  padding: 0;
  width: 100%;
  border: 1px solid;
    cursor: pointer;
}

.filter_header {
  font-weight: bold;
  padding: 1rem;
  //lower border fat
}


.filter_element:hover {
  background-color: var(--g-color-extra-light-gray);
}


.chevron {
  content: '';
  display: inline-block;
  float: right;
  margin-top: .5rem;
  width: 1.4rem;
  height: 1.4rem;

  background: url('../../../theme/resource/icon/chevron.svg');
  transform: rotate(90deg);

}


.closed {
  transform: rotate(0deg);
}

