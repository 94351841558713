.m0{ margin:0 }
.mt0{ margin-top:0 }
.mr0{ margin-right:0 }
.mb0{ margin-bottom:0 }
.ml0{ margin-left:0 }
.mx0{ margin-left:0; margin-right:0 }
.my0{ margin-top:0; margin-bottom:0 }

.m1{ margin: .8rem }
.mt1{ margin-top: .8rem }
.mr1{ margin-right: .8rem }
.mb1{ margin-bottom: .8rem }
.ml1{ margin-left: .8rem }
.mx1{ margin-left: .8rem; margin-right: .8rem }
.my1{ margin-top: .8rem; margin-bottom: .8rem }

.m2{ margin: 1.6rem }
.mt2{ margin-top: 1.6rem }
.mr2{ margin-right: 1.6rem }
.mb2{ margin-bottom: 1.6rem }
.ml2{ margin-left: 1.6rem }
.mx2{ margin-left: 1.6rem; margin-right: 1.6rem }
.my2{ margin-top: 1.6rem; margin-bottom: 1.6rem }

.m3{ margin: 3.2rem }
.mt3{ margin-top: 3.2rem }
.mr3{ margin-right: 3.2rem }
.mb3{ margin-bottom: 3.2rem }
.ml3{ margin-left: 3.2rem }
.mx3{ margin-left: 3.2rem; margin-right: 3.2rem }
.my3{ margin-top: 3.2rem; margin-bottom: 3.2rem }

.m4{ margin: 6.4rem }
.mt4{ margin-top: 6.4rem }
.mr4{ margin-right: 6.4rem }
.mb4{ margin-bottom: 6.4rem }
.ml4{ margin-left: 6.4rem }
.mx4{ margin-left: 6.4rem; margin-right: 6.4rem }
.my4{ margin-top: 6.4rem; margin-bottom: 6.4rem }

.mxn1{ margin-left: -.8rem; margin-right: -.8rem; }
.mxn2{ margin-left: -1.6rem; margin-right: -1.6rem; }
.mxn3{ margin-left: -3.2rem; margin-right: -3.2rem; }
.mxn4{ margin-left: -6.4rem; margin-right: -6.4rem; }

.ml-auto{ margin-left:auto }
.mr-auto{ margin-right:auto }
.mx-auto{ margin-left:auto; margin-right:auto; }


.l-m0{ margin:0 }
.l-mt0{ margin-top:0 }
.l-mr0{ margin-right:0 }
.l-mb0{ margin-bottom:0 }
.l-ml0{ margin-left:0 }
.l-mx0{ margin-left:0; margin-right:0 }
.l-my0{ margin-top:0; margin-bottom:0 }

.l-m1{ margin: .8rem }
.l-mt1{ margin-top: .8rem }
.l-mr1{ margin-right: .8rem }
.l-mb1{ margin-bottom: .8rem }
.l-ml1{ margin-left: .8rem }
.l-mx1{ margin-left: .8rem; margin-right: .8rem }
.l-my1{ margin-top: .8rem; margin-bottom: .8rem }

.l-m2{ margin: 1.6rem }
.l-mt2{ margin-top: 1.6rem }
.l-mr2{ margin-right: 1.6rem }
.l-mb2{ margin-bottom: 1.6rem }
.l-ml2{ margin-left: 1.6rem }
.l-mx2{ margin-left: 1.6rem; margin-right: 1.6rem }
.l-my2{ margin-top: 1.6rem; margin-bottom: 1.6rem }

.l-m3{ margin: 3.2rem }
.l-mt3{ margin-top: 3.2rem }
.l-mr3{ margin-right: 3.2rem }
.l-mb3{ margin-bottom: 3.2rem }
.l-ml3{ margin-left: 3.2rem }
.l-mx3{ margin-left: 3.2rem; margin-right: 3.2rem }
.l-my3{ margin-top: 3.2rem; margin-bottom: 3.2rem }

.l-m4{ margin: 6.4rem }
.l-mt4{ margin-top: 6.4rem }
.l-mr4{ margin-right: 6.4rem }
.l-mb4{ margin-bottom: 6.4rem }
.l-ml4{ margin-left: 6.4rem }
.l-mx4{ margin-left: 6.4rem; margin-right: 6.4rem }
.l-my4{ margin-top: 6.4rem; margin-bottom: 6.4rem }

.l-mxn1{ margin-left: -.8rem; margin-right: -.8rem; }
.l-mxn2{ margin-left: -1.6rem; margin-right: -1.6rem; }
.l-mxn3{ margin-left: -3.2rem; margin-right: -3.2rem; }
.l-mxn4{ margin-left: -6.4rem; margin-right: -6.4rem; }

.l-ml-auto{ margin-left:auto }
.l-mr-auto{ margin-right:auto }
.l-mx-auto{ margin-left:auto; margin-right:auto; }


@media only screen and (max-width: $g-medium) {
    .m-m0{ margin:0 }
    .m-mt0{ margin-top:0 }
    .m-mr0{ margin-right:0 }
    .m-mb0{ margin-bottom:0 }
    .m-ml0{ margin-left:0 }
    .m-mx0{ margin-left:0; margin-right:0 }
    .m-my0{ margin-top:0; margin-bottom:0 }
    
    .m-m1{ margin: .8rem }
    .m-mt1{ margin-top: .8rem }
    .m-mr1{ margin-right: .8rem }
    .m-mb1{ margin-bottom: .8rem }
    .m-ml1{ margin-left: .8rem }
    .m-mx1{ margin-left: .8rem; margin-right: .8rem }
    .m-my1{ margin-top: .8rem; margin-bottom: .8rem }
    
    .m-m2{ margin: 1.6rem }
    .m-mt2{ margin-top: 1.6rem }
    .m-mr2{ margin-right: 1.6rem }
    .m-mb2{ margin-bottom: 1.6rem }
    .m-ml2{ margin-left: 1.6rem }
    .m-mx2{ margin-left: 1.6rem; margin-right: 1.6rem }
    .m-my2{ margin-top: 1.6rem; margin-bottom: 1.6rem }
    
    .m-m3{ margin: 3.2rem }
    .m-mt3{ margin-top: 3.2rem }
    .m-mr3{ margin-right: 3.2rem }
    .m-mb3{ margin-bottom: 3.2rem }
    .m-ml3{ margin-left: 3.2rem }
    .m-mx3{ margin-left: 3.2rem; margin-right: 3.2rem }
    .m-my3{ margin-top: 3.2rem; margin-bottom: 3.2rem }
    
    .m-m4{ margin: 6.4rem }
    .m-mt4{ margin-top: 6.4rem }
    .m-mr4{ margin-right: 6.4rem }
    .m-mb4{ margin-bottom: 6.4rem }
    .m-ml4{ margin-left: 6.4rem }
    .m-mx4{ margin-left: 6.4rem; margin-right: 6.4rem }
    .m-my4{ margin-top: 6.4rem; margin-bottom: 6.4rem }
    
    .m-mxn1{ margin-left: -.8rem; margin-right: -.8rem; }
    .m-mxn2{ margin-left: -1.6rem; margin-right: -1.6rem; }
    .m-mxn3{ margin-left: -3.2rem; margin-right: -3.2rem; }
    .m-mxn4{ margin-left: -6.4rem; margin-right: -6.4rem; }
    
    .m-ml-auto{ margin-left:auto }
    .m-mr-auto{ margin-right:auto }
    .m-mx-auto{ margin-left:auto; margin-right:auto; }
}

@media only screen and (max-width: $g-small) {
    .s-m0{ margin:0 }
    .s-mt0{ margin-top:0 }
    .s-mr0{ margin-right:0 }
    .s-mb0{ margin-bottom:0 }
    .s-ml0{ margin-left:0 }
    .s-mx0{ margin-left:0; margin-right:0 }
    .s-my0{ margin-top:0; margin-bottom:0 }
    
    .s-m1{ margin: .8rem }
    .s-mt1{ margin-top: .8rem }
    .s-mr1{ margin-right: .8rem }
    .s-mb1{ margin-bottom: .8rem }
    .s-ml1{ margin-left: .8rem }
    .s-mx1{ margin-left: .8rem; margin-right: .8rem }
    .s-my1{ margin-top: .8rem; margin-bottom: .8rem }
    
    .s-m2{ margin: 1.6rem }
    .s-mt2{ margin-top: 1.6rem }
    .s-mr2{ margin-right: 1.6rem }
    .s-mb2{ margin-bottom: 1.6rem }
    .s-ml2{ margin-left: 1.6rem }
    .s-mx2{ margin-left: 1.6rem; margin-right: 1.6rem }
    .s-my2{ margin-top: 1.6rem; margin-bottom: 1.6rem }
    
    .s-m3{ margin: 3.2rem }
    .s-mt3{ margin-top: 3.2rem }
    .s-mr3{ margin-right: 3.2rem }
    .s-mb3{ margin-bottom: 3.2rem }
    .s-ml3{ margin-left: 3.2rem }
    .s-mx3{ margin-left: 3.2rem; margin-right: 3.2rem }
    .s-my3{ margin-top: 3.2rem; margin-bottom: 3.2rem }
    
    .s-m4{ margin: 6.4rem }
    .s-mt4{ margin-top: 6.4rem }
    .s-mr4{ margin-right: 6.4rem }
    .s-mb4{ margin-bottom: 6.4rem }
    .s-ml4{ margin-left: 6.4rem }
    .s-mx4{ margin-left: 6.4rem; margin-right: 6.4rem }
    .s-my4{ margin-top: 6.4rem; margin-bottom: 6.4rem }
    
    .s-mxn1{ margin-left: -.8rem; margin-right: -.8rem; }
    .s-mxn2{ margin-left: -1.6rem; margin-right: -1.6rem; }
    .s-mxn3{ margin-left: -3.2rem; margin-right: -3.2rem; }
    .s-mxn4{ margin-left: -6.4rem; margin-right: -6.4rem; }
    
    .s-ml-auto{ margin-left:auto }
    .s-mr-auto{ margin-right:auto }
    .s-mx-auto{ margin-left:auto; margin-right:auto; }
}

