.container, .inputContainer {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: left;
}

.input {
    width: 100%;
}

.container:hover .input {
    border-color: var(--g-light-gray);
}

.input:disabled {
    background: var(--g-border-color);
    border-color: var(--g-border-color);
    cursor: not-allowed;
}

.error input, 
.error:focus-within input, 
.error:hover input {
    border-color: var(--g-color-failure);
}

.label {
    display: inline-block;
    color: var(--g-light-gray);
    margin-bottom: .32rem;
    padding-left: .4rem;
    transition: color .2s ease-in-out;
}

.container:hover .label {
    color: var(--g-font-color)
}

.container:focus-within .label {
    color: var(--g-color-primary)
}

.error .label,
.error:focus-within .label {
    color: var(--g-color-failure)
}

.errorMessage {
    display: inline-block;
    color: var(--g-color-failure);
    font-weight: 400;
    font-size: 1.52rem;
    margin-top: .4rem;
    padding-left: .4rem;
    text-align: left;
}

.infoMessage {
    display: inline-block;
    color: var(--g-color-primary);
    font-weight: 400;
    font-size: 1.52rem;
    margin-top: .4rem;
    margin-bottom: -.72rem;
    padding-left: .4rem;
    text-align: left;
}

.infoIcon {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: 1.4rem;
    right: 1.2rem;
    background: url('../../resource/icon/questionmark.svg');
    opacity: .5;
    transition: opacity .2s ease-in-out;
}

.infoIcon:hover {
    opacity: 1;
}
