.panel {
  width: 100%;
  height: fit-content;
  text-align: right;
}

.application_area {
  text-align: left;
}

