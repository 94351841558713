.item {
    display: block;
    margin-bottom: 0.8rem;
    cursor: pointer;
}

.network {
    position: relative;
    margin-left: 4rem;
    color: var(--g-font-color);
    font-weight: bold;
    font-size: 1.8rem;
}

.network::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -4rem;
    width: 2.4rem;
    height: 2.4rem;
}

.twitch::before {
    background: url("../../../../../../theme/resource/icon/twitch.svg");
}
.tiktok::before {
    background: url("../../../../../../theme/resource/icon/tiktok.svg");
}
.youtube::before {
    background: url("../../../../../../theme/resource/icon/youtube.svg");
}
.twitter::before {
    background: url("../../../../../../theme/resource/icon/twitter.svg");
}
.snapchat::before {
    background: url("../../../../../../theme/resource/icon/snapchat.svg");
}
.instagram::before {
    background: url("../../../../../../theme/resource/icon/instagram.svg");
}
.facebook::before {
    background: url("../../../../../../theme/resource/icon/facebook.svg");
}

.link {
    position: relative;
    float: right;
    font-style: italic;
    color: var(--g-border-color);
    font-weight: 400;
    font-size: 1.8rem;
    margin-right: 1.6rem;
    transition: color 0.2s ease-in-out;
}

.link::after {
    content: "";
    display: inline-block;
    position: absolute;
    right: -1.92rem;
    bottom: 0.72rem;
    width: 1.4rem;
    height: 1.4rem;
    background: url("../../../../../../theme/resource/icon/chevron.svg");
    opacity: 0.25;
    transition: opacity 0.2s ease-in-out;
}

.item:hover .link,
.item:hover .link::after {
    color: var(--g-font-color);
    opacity: 1;
}

.highlighted {
    color: var(--g-color-failure);
}

.account a {
    width: auto;
}
