.icon {
    background: url("../../resource/icon/gradient_checkmark.svg");
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
}

.container {
    width: 100%;
    padding: 3rem;
    text-align: center;
}