:root {
    font-size: 62.5%;
}

* {
    margin: 0 auto;
    box-sizing: border-box;
    outline: none;
}

body, #body {
    text-align: center;
    font-family: 'Heebo';
    font-size: 1.6rem;
    //background: var(--g-color-background);
    background: #f4f4f9;
    //background: white;
    color: var(--g-color-font);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    /* min-height: 100vh; */
    min-width: 320px;
}

span {
    display: inline-block;
}

main {
    display: block;
    width: 100%;
    text-align: center;
    padding-bottom: 400px;
    min-height: 50vh;
    position: relative;

    min-height: calc(100vh - 340px);
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
}

section {
    display: block;
    text-align: left;
    width: 100%;
    max-width: 1044px;
    padding: 0 3.2rem;
    position: relative;
}

@media (max-width: 780px) {
    section {
        padding: 0 1.6rem;
    }

    main {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
}