.date {
    position: sticky;
    top: 3rem;
    display: block;
    height: 1rem;
    margin: 3rem 0;
    margin-bottom: 5rem;
    text-align: center;
    z-index: 1;
}

.date span {
    border-radius: 10px;
    text-align: center;
    padding: .3rem 2rem;
    background-color: rgb(211, 243, 255);
    color: var(--g-font-color)
}