.notification {
    z-index: 9999999;
    padding: 1.5rem 1.6rem;
    box-shadow: var(--g-dark-shadow);
    border-radius: 10px;
    width: 100%;
    background: white;
    margin: 0 0 2rem;
    position: relative;
    overflow: hidden;
    text-align: right;
}

.message {
    width: calc(100% - 3.4rem);
    text-align: left;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes disappear {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.loading {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    background: white;
    width: 100%;
    height: .4rem;
    animation: decreaseWidth linear forwards;
}

@keyframes decreaseWidth {
    to {
        width: 0;
    }
}

.message {
    color: white;
}

.info_background {
    background: var(--g-color-info);
}

.success_background {
    background: var(--g-color-success);
}

.warning_background {
    background: var(--g-color-warning);
}

.failure_background {
    background: var(--g-color-failure);
}

.directMessage_background {
    background: white;

    .message {
        color: var(--g-font-color);
    }

    .loading {
        background: var(--g-font-color);
    }
}

.icon {
    position: absolute;
    top: 1.4rem;
    left: 1.6rem;
    width: 2.4rem;
    height: 2.4rem;
}

.info_icon {
    background: url('../../../../resource/icon/info.svg');
}

.success_icon {
    background: url('../../../../resource/icon/success.svg');
}

.warning_icon {
    background: url('../../../../resource/icon/warning.svg');
}

.failure_icon {
    background: url('../../../../resource/icon/failure.svg');
}

.directMessage_icon {
    background: url('../../../../resource/icon/inbox.svg');
}