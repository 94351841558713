/* Updated tile.module.scss */

.tile {
    cursor: pointer;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        transform: translateY(-5px);
    }

    img {
        width: 100%;
        height: 0%;
        object-fit: cover;
        display: block;
        transition: transform 0.3s ease;
    }

    &.big {
        grid-column: span 6;
        grid-row: span 2;
        width: 100%;

        &:hover img {
            transform: none;
        }
    }

    &.medium {
        width: 100%;
        grid-column: span 3;
        grid-row: span 2;
    }

    &.small {
        width: 100%;
        grid-column: span 2;
        grid-row: span 1;
    }

    .tileContent {
        padding: 1rem;
        background-color: white;
        h6.status {
            font-size: 1rem;
            color: #666;
        }

        h3.title {
            font-size: 1.5rem;
            color: #000;
        }

        h6.createdAt {
            font-size: 0.8rem;
            color: #666;
        }
    }
}
