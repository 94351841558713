.container {
    height: 25rem;
    border-radius: var(--g-panel-radius);
    background-color: pink;
    position: relative;
}

.pagination {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    text-align: center;
}

.navigate {
    height: 100%;
    width: 5rem;
    opacity: 0;
    transition: opacity 0.3s;
    position: relative;
    cursor: pointer;
}

.navigate_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.navigate_right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.navigate_left::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-55%, -50%) rotate(180deg);
    width: 3.5rem;
    height: 3.5rem;
    background: url('../../resource/icon/chevron.svg')
}

.navigate_right::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-40%, -50%);
    width: 3.5rem;
    height: 3.5rem;
    background: url('../../resource/icon/chevron.svg')
}

.container:hover .navigate {
    opacity: .8;
}