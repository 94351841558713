.option {
    padding: 1.4rem 2rem;
    transition: background-color .1s ease-in, transform .3s ease-in-out, height .3s ease-in-out;
    transform: translateY(0);
}

.option:hover {
    background-color: var(--g-light-regular-white);
    color: var(--g-color-primary);
}

.option.closed {
    display: none; /* workaround */
    height: 2.6rem;
    transform: translateY(-100%);
}

.pre_icon::before {
    content: '';
    display: block;
    background-size: cover;
    background: url('../../../../resource/icon/rank.svg') no-repeat center;
    width: 2.5rem;
    height: 2.5rem;
    float: left;
    margin-top: 0;
    margin-right: 1.5rem;
}

.youtube.pre_icon::before {
    background-image: url('../../../../resource/icon/youtube.svg');
}

.instagram.pre_icon::before {
    background-image: url('../../../../resource/icon/instagram.svg');
}

.twitter.pre_icon::before {
    background-image: url('../../../../resource/icon/twitter.svg');
}

.tiktok.pre_icon::before {
    background-image: url('../../../../resource/icon/tiktok.svg');
}

.twitch.pre_icon::before {
    background-image: url('../../../../resource/icon/twitch.svg');
}

.facebook.pre_icon::before {
    background-image: url('../../../../resource/icon/facebook.svg');
}