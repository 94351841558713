.profile_container {
    //box-shadow: var(--g-regular-shadow);
    border: 2px solid rgb(220,220,220);
    border: 1px solid rgb(233,233,233);
    border-radius: 1.5rem;
    padding: 1rem;
    padding-top: 0.25rem;
    cursor: pointer;
    margin-bottom: 1.5rem;
    background: white;
}

.profile_container:hover {
    .title {
        text-decoration: underline;
    }
}

.title {
    font-weight: bolder;
    font-size: 1.75rem;
    margin-right: .3rem;
}

.avatar {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    border: 2px solid rgb(220, 220, 220);

    margin-right: 2rem;
    margin-left: 1rem;
}

.tag_view {
    font-size: 1.5rem;
    border-radius: 1.5rem;
    padding: 0.1rem 0.8rem;
    margin-right: 0.6rem;
    border: 2px solid var(--g-font-color);
}

.tag_view::before {
    content: "#";
}

.tag_view:hover {
    cursor: pointer;
    background-color: var(--g-font-color);
    color: white;
}

.description {
    width: 100%;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.content {
    margin: 0 !important;
}
.profile_container {
    display: flex;
    justify-content: start;
    //box-shadow: var(--g-regular-shadow);
    border-radius: 1.5rem;
    padding: 1rem;
    padding-left: 0.5rem;
    cursor: pointer;
    height: 10rem;
}
