
.keywords_icon::before {
  background: url('../../../../../../theme/resource/icon/billing.svg');
  height: 2.5rem;
  width: 2.5rem;
}

.keywords_icon:hover {
  cursor: pointer;
  opacity: 0.75;
}

.edit_icon::before {
  background: url('../../../../../../theme/resource/icon/edit.svg');
  height: 2.5rem;
  width: 2.5rem;
}

