.rainbow {
    animation: rainbow 10s infinite; 
}

@keyframes rainbow {
    0% {color: orange;}
    20% {color: red;}
    30% {color: CadetBlue;}
    50% {color: coral;}
    60% {color: green;}
    80% {color: DeepPink;}
    90% {color: DodgerBlue;}
    100% {color: orange;}
}

.orange_red_gradient {
    animation: orange_red_gradient 2s infinite ease-in-out;
}

@keyframes orange_red_gradient {
    0% {color: black;}
    25% {color: red;}
    75% {color: red;}
    100% {color: black;}
}