.container {
    position: relative;
    display: block;
    padding-top: 7rem;
    padding-bottom: 7rem;
    margin-bottom: 10rem;
    background: rgb(230,233,241);
    background: rgb(244, 243, 246);

    @media (max-width: 1050px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
        margin-bottom: 5rem;
    }
}

.benefitsContainer {
    display: grid;
    grid-template-columns: 2fr auto 2fr; /* Three columns: left, phone, right */
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    max-width: 120rem;

    @media (max-width: 1050px) {
        /* On mobile, stack the items */
        grid-template-columns: 1fr;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.title {
    grid-column: 1 / -1; /* Span the title across all columns */
    text-align: center;
    margin-bottom: 2rem;
    font-size: 3rem;
    font-weight: 600;
    color: var(--g-font-color);

    @media (max-width: 1050px) {
        margin-bottom: 0;
    }
}

.benefitsGrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15rem;

    @media (max-width: 1050px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

.benefitItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px; /* Adjust width accordingly */
    height: 100px;  /* Adjust height accordingly */
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    background: white;
    text-align: left;
    border-radius: 15px;
    cursor: pointer;

    transition: transform 0.2s ease-in-out;
    &:hover {
        transform: translateY(-5px);
    }

    @media (max-width: 1050px) {
        box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2);
        cursor: auto;
    }
}

.benefit_icon {
    width: 50px;
    height: 50px;
    background: var(--g-color-primary);
    margin-left: 2rem;
    margin-right: 1.5rem;
}

.login_icon {
    background: url("../../../../theme/resource/icon/sign_in_gradient.svg")
}

.discover_icon {
    background: url("../../../../theme/resource/icon/explore_gradient.svg")
}

.connect_icon {
    background: url("../../../../theme/resource/icon/link_gradient.svg")
}

.cooperation_icon {
    width: 40px;
    height: 40px;
    background: url("../../../../theme/resource/icon/statistics_gradient.svg")
}


.benefitText {
    max-width: 70%;

    h3 {
        font-size: 2rem;
        color: var(--g-font-color);
    }

    p {
        font-size: 1.6rem;
        color: var(--g-label-color);
    }
}

.phoneMockup {
    display: flex;
    justify-content: center;

    img {
        max-width: 100%;
        width: 300px;
    }

    @media (max-width: 1050px) {
        margin: 2rem 0;
        display: none;
    }
}