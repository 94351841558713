.menuWrapper {
    .desktopMenu {
        display: block;

        .quickactions {
            display: flex;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 30rem;
            justify-content: space-between;
        }

        .item {
            display: inline-block;
            margin: 0;
            height: 100%;
            width: 11.2rem;
            text-align: left;
            cursor: pointer;
        }

        .icon {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 1.3rem;
            width: 2.96rem;
            height: 2.96rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .inbox_dot {
            position: absolute;
            bottom: 0.9rem;
            right: 0.2rem;
            width: 1rem;
            height: 1rem;
            background: red;
            border-radius: 100%;
        }

        .lineIndicator {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0rem;
            width: 11.2rem;
            width: 100%;
            height: 3px;
            border-radius: 100px;
            background-color: var(--g-color-primary);
        }
    }

    .mobileMenu {
        display: none;
    }

    .safe_area {
        //padding-bottom: env(safe-area-inset-bottom, 0);
        //z-index: 1000;
    }
    @media (max-width: 850px) {
        .desktopMenu {
            display: none;
        }

        .mobileMenu {
            display: flex;
            position: fixed;
            bottom: 0;
            padding-bottom: env(safe-area-inset-bottom, 0);
            left: 0;
            right: 0;
            background-color: #fff;
            box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
            z-index: 1000;
            height: calc(56px + env(safe-area-inset-bottom, 0));

            .item {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 5px 0;
                text-decoration: none;
                color: #333;
                position: relative;

                &.current {
                    color: var(--g-color-success);
                }

                .home, .inbox, .explore, .marketplace {
                    width: 24px;
                    height: 24px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .inbox_dot {
                    position: absolute;
                    top: 5px;
                    right: calc(50% - 18px);
                    width: 8px;
                    height: 8px;
                    background: red;
                    border-radius: 100%;
                }

                span {
                    margin-top: 4px;
                    font-size: 12px;
                }
            }
        }

        .mobiletooltip {
            display: none;
        }
    }
}