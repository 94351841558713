.loading {
    position: relative;
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 3rem;
}

.loading .loader {
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background: linear-gradient(315deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
    position: relative;
    display: inline-block;
    animation: mainload 1.5s ease-in-out infinite;
}

.loading .loader::before,
.loading .loader::after {
    content: '';
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background: linear-gradient(315deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
    position: absolute;
    animation: load 1.5s ease-in-out infinite;
}

.loading .loader::before {
    top: -35px;
    left: 0;
    animation-delay: .4s;
}

.loading .loader::after {
    bottom: -35px;
    left: 0;
    animation-delay: .2s;
}

@keyframes mainload {
    0% {
        transform: translateX(36px) translateY(20px) rotate(30deg);
    }
    50% {
        transform: translateX(-36px) translateY(-20px) rotate(30deg);
    }
    100% {
        transform: translateX(36px) translateY(20px) rotate(30deg);
    }
}

@keyframes load {
    0% {
        left: -40px;
    }
    50% {
        left: 40px;
    }
    100% {
        left: -40px;
    }
}
