.landing {
    background: white;
}

.container {
    width: 100% !important;
    max-width: 100%;
    background: #ffffff;
}

//section {
//    max-width: 1500px;
//}

.header {
}

.nav_bar {
    padding-top: 2rem;
    text-align: right;
}

.login_button {
    margin-left: 2.5rem;
    background: #333539;
    width: 13rem;
    height: 5rem;
    text-align: center;
    color: white;
    padding: 1.3rem;

    border-radius: 3rem;
}

.signup_button {
    background: transparent linear-gradient(167deg, #00c0fc 0%, #03ff00 100%);
    width: 16rem;
    height: 5rem;
    text-align: center;
    color: white;
    padding: 1.3rem;

    border-radius: 3rem;
}

.navbar_option {
    font: normal normal medium 22px/33px Poppins;
    letter-spacing: 0;
    color: #333539;
    text-transform: uppercase;
    margin-right: 2.5rem;
}

.small_text {
    font: normal normal normal 26px/42px Poppins;
    color: #333539;
    margin-bottom: 2rem;
}

.big_text {
    font-size: 5rem;
    margin-bottom: 1rem;
    //line-height: 7rem;
}

.nav_bar {
    margin-bottom: 8rem;
}

.see_more_button {
    margin-left: 2.5rem;
    width: 12rem;
    height: 5rem;
    text-align: center;
    padding: 1.2rem;
    font-weight: bolder;

    border-radius: 3rem;
    border: black solid 0.2rem;
}

.icon {
    background: url("../../theme/resource/icon/influspace.svg");
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 3rem;
}

.logo {
    float: left;
}

.logo_influspace {
    margin-top: 1.3rem;
    margin-left: 3.5rem;
}
