.container {
    text-align: center !important;
    margin-bottom: 10rem;
    max-width: 140rem;

}

.header {
    font-size: 3rem;
    font-weight: 600;
}

.switch {
    margin-top: 2rem;
}

.benefit {
    height: 35rem;
    text-align: left;
}

.line_business {
    width: 5rem;
    height: 1rem;
    border-top: #03ff00 0.2rem solid;
}

.line_influencer {
    width: 7rem;
    float: left;
    height: 1rem;
    border-top: #00c0fc 0.2rem solid;
}

.benefit_text {
    width: 90%;
}

.benefit_tile {
    width: 50%;
    vertical-align: top;
}

.subscriptionTile {
    position: relative;
    background-image: linear-gradient(
            to bottom left,
            rgb(250, 250, 250),
            rgb(255, 255, 255)
    );
    box-shadow: 0px 25px 45px #00000029;
    border-radius: 2.5rem;
    padding: 30px 0px;
    margin: 10px 0;
    max-width: 42rem;
    transition: transform 0.2s ease-in-out;
    &:hover {
        transform: translateY(-5px);
    }


}

.subscriptionTileTitle {
    font-size: 20px;
    color: #333539;
    font-weight: bold;
}
.price {
    font-size: 45px;
    font-weight: bold;
    color: #333539;
}

.plansGrid {
    display: grid;
    grid-template-columns: repeat(
        auto-fill,
            minmax(250px, 1fr)
    ); // Creates a responsive grid with columns that have a minimum width of 250px
    gap: 30px; // Spacing between grid items
    margin-top: 50px; // Space above the grid
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; // This centers the content vertically if there's enough space
    text-align: center;
    @media (max-width: 1380px) {
        flex-direction: column;
    }
}

.benefits {
    list-style: none;
    padding: 0.5rem;
    margin: 1rem 0;
    margin-bottom: 15rem;
    font-weight: 400;
    font-size: 17px;

    @media (max-width: 1380px) {
        margin-bottom: 6rem;
    }

    li {
        margin-bottom: 5px;
        margin: 0 4rem;
        padding: 1rem;
        position: relative;
        color: black;
        text-align: left;
        padding-left: 1.5rem;
    }
}

.action {
    margin-top: 3rem;
    font-size: 2.25rem;
    color: #333539;
    margin-bottom: 15px;
    font-weight: bolder;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

.premiumSubscriptionTile {
    background: transparent linear-gradient(138deg, #00c0fc 0%, rgb(105, 225, 110) 100%) 0%
        0% no-repeat padding-box;
    box-shadow: 0px 25px 45px #00000029;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    background-filter: blur(50px);
    opacity: 1;
    border-radius: 2.5rem;
    padding: 30px 0;
    margin: 10px 0;
    transition: transform 0.2s ease-in-out;
    max-width: 42rem;
    min-height: 45rem;

    &:hover {
        transform: translateY(-5px);
    }

}

.premiumSubscriptionTileTitle {
    font-size: 20px;
    color: white;
    font-weight: bold;
}

.premiumPrice {
    font-size: 45px;
    font-weight: bold;
    color: white;
}

.priceOld {
    display: inline-block;
    font-size: 2.8rem;
    color: #DC143C;
    //background: rgba(220, 20, 60, .1);
    padding: .5rem 1rem;
    border-radius: 1rem;
    text-decoration: line-through;
    position: relative;
    bottom: 1rem;
    right: 1rem;

    @media (max-width: 420px) {
        display: block;
        bottom: -1rem;
        right: 0rem;
    }
}

.discount {
    display: inline-block;
    font-size: 2.8rem;
    color: #EDC375;
    background: rgba(237, 195, 117, .1);
    padding: .5rem 1rem;
    border-radius: 1rem;
    position: relative;
}

.price_disclaimer {
    color: white;
    margin-bottom: 1rem;
}

.premiumBenefits {
    list-style: none;
    padding: 0.5rem;
    margin: 1rem 0;
    margin-bottom: 15rem;
    min-height: 25rem;
    font-weight: 400;
    font-size: 17px;

    li {
        margin-bottom: 5px;
        margin: 0 4rem;
        padding: 1rem;
        position: relative;
        color: white;
        text-align: left;
        padding-left: 1.5rem;
    }

    @media (max-width: 1380px) {
        margin-bottom: 0;
    }
}

.donut {
    position: absolute;
    left: -1.5rem;
    top: 1.2rem;
}



.premiumAction {
    margin-top: 3rem;
    font-size: 2.25rem;
    color: white;
    margin-bottom: 15px;
    font-weight: bolder;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

.promoBanner {
    text-align: right;
    padding-right: 20px; /* Adjust based on your design */
    @media (max-width: 1380px) {
        display: none;
    }
}

.triangle {
    width: 18rem;
    height: 18rem;
    background-image: linear-gradient(
            to bottom right,
            #00c0fc,
            #03ff00
    );
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    border-top-right-radius: 3rem;
    box-shadow: 0px 25px 45px #00000029;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    @media (max-width: 1380px) {
        display: none;
    }
}

.promoBanner span {
    position: absolute;
    left: 24rem; /* Adjust based on the size of your triangle */
    top: 13rem; /* Adjust for visual alignment */
    color: white; /* Text color */
    font-size: 12px; /* Adjust based on your design */
    transform: rotate(45deg); /* Rotate text */
    transform-origin: top right; /* Adjust rotation origin if needed */
    white-space: nowrap; /* Prevent text from wrapping */
    font-weight: 600;
    z-index: 10;
    @media (max-width: 1380px) {
        display: none;
    }
}

.promotion {
    display: none;
    font-size: 24px;
    font-weight: 800;
}
