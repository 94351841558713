.item {
    display: block;
    margin-top: .8rem;
    cursor: pointer;
}

.name {
    position: relative;
    margin-left: 4rem;
    color: var(--g-font-color);
    font-weight: normal;
    font-size: 1.8rem;
}

.name::before {
    content: '';
    display: inline-block;
    position: absolute;
}

.icon_link::before { 
    left: -4rem;
    width: 2.4rem;
    height: 2.4rem;
    background: url('../../../../../../theme/resource/icon/link.svg') 
}

.icon_pencil::before { 
    left: -3.6rem;
    width: 2rem;
    height: 2rem;
    background: url('../../../../../../theme/resource/icon/edit.svg') 
}

.link {
    position: relative;
    color: var(--g-font-color);
    font-weight: bold;
    font-size: 1.8rem;
}

.link {
    position: relative;
    float: right;
    font-style: italic;
    color: var(--g-border-color);
    font-weight: 400;
    font-size: 1.8rem;
    margin-right: 1.6rem;
    transition: color .2s ease-in-out;
}

.link::after {
    content: '';
    display: inline-block;
    position: absolute;
    right: -1.92rem;
    bottom: .72rem;
    width: 1.4rem;
    height: 1.4rem;
    background: url('../../../../../../theme/resource/icon/chevron.svg');
    opacity: .25;
    transition: opacity .2s ease-in-out;
}

.item:hover .link,
.item:hover .link::after {
    color: var(--g-font-color);
    opacity: 1;
}

.highlighted {
    color: var(--g-color-failure);
}

.account a {
    width: auto;
}