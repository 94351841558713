.container {
    text-align: left;
    font-size: 1.3rem;
  position: relative;
  padding-left: 2.25rem;
  margin-bottom: .5rem;
  color: var(--g-font-color);
}

.checked {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('../../../theme/resource/icon/green_checkmark.svg');
  position: absolute;
  top: .175rem;
  bottom: 0;
  left: 0.4rem;
}

.unchecked {
  width: 1.5rem;
  height: 1.5rem;
  background: url('../../../theme/resource/icon/ring.svg');
  position: absolute;
  top: .175rem;
  bottom: 0;
  left: 0.4rem;
}