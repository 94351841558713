.panel {
    width: 28.8rem;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    text-align: center;
}

.icon {
    display: inline-block;
    width: 6.4rem;
    height: 6.4rem;
    background: url('../../../../theme/resource/icon/tools.svg');
}

.icon_verified {
    display: inline-block;
    width: 6.4rem;
    height: 6.4rem;
    background: url('../../../../theme/resource/icon/verified.svg');
}

.title {
    font-size: 2.4rem;
    margin-top: 1.6rem;
}

.description {
    margin-top: 1.6rem;
    line-height: 1.5;
    display: block;
    color: rgb(110,110,110);
    font-weight: 500;
}

.button {
    display: inline-block;
    background: var(--g-color-primary);
    color: white;
    padding: 1.2rem 2rem;
    border-radius: 8px;
    margin-top: 2.4rem;
    font-weight: 500;
}

.large {
    width: calc(100% - 3.2rem);
}

.buttonFilled {
    width: calc(100% - 3.2rem);
    display: inline-block;
    background: var(--g-color-primary);
    color: #ffffff;
    padding: 1.2rem 1.25rem;
    border-radius: 8px;
    margin-top: 2.4rem;
    font-weight: 500;
}

.buttonOutlined {
    width: calc(100% - 3.2rem);
    display: inline-block;
    background: white;
    border: 2px solid var(--g-color-primary);
    color: var(--g-color-primary);
    padding: 1.2rem 3.2rem;
    border-radius: 8px;
    margin-top: 2.4rem;
    font-weight: 500;
}