.centered { margin-left: auto; margin-right: auto; float: none }
.center-absolute { position: absolute; left: 0; right: 0; }
.uncentered { margin-left: 0; margin-right: 0 }
.vertical-top { vertical-align: top; }
.vertical-middle { vertical-align: middle; }
.max-width { width: 100%; }

.l-centered { margin-left: auto; margin-right: auto; float: none }
.l-uncentered { margin-left: 0; margin-right: 0 }

.clear {
    clear: both;
    line-height: 0;
}

@media only screen and (max-width: $g-medium) {
    .m-centered { margin-left: auto; margin-right: auto; float: none }
    .m-uncentered { margin-left: 0; margin-right: 0 }
}

@media only screen and (max-width: $g-small) {
    .s-centered { margin-left: auto; margin-right: auto; float: none }
    .s-uncentered { margin-left: 0; margin-right: 0 }
}