// index.module.scss
.section {
    margin-bottom: 10px;
}

.header {
    cursor: pointer;
    background-color: #ffffff;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        background-color: #f9f9f9;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
}

.icon {
    transition: transform 0.3s ease;
}

.contentClosed {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
}

.contentOpen {
    max-height: 1000px; /* You can set a max height to ensure a smooth transition */
    transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
    opacity: 1;
}

.contentInner {
    padding: 15px;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #fafafa;
}
