.iconWithText {
    display: flex;
    align-items: center;
    box-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.07);
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.iconWithText > div {
    margin-left: 5px; /* Adjust this value to move the text further from the icon */
    margin-right: 5px;
}

.icon {
    width: 40px;
    height: 29px;
    background: url("../../../../../../theme/resource/icon/delete.svg");
    background-repeat: no-repeat;
}

.iconMail {
    width: 40px;
    height: 29px;
    background: url("../../../../../../theme/resource/icon/mail.svg");
    background-repeat: no-repeat;
}

.info {
    color: gray;
}

.buttonL {
    position: absolute;
    bottom: 1rem;
    left: 23rem;
}

.button_close {
    text-align: center;
}

@media only screen and (max-width: 735px) {
    .buttonL {
        bottom: 1rem;
        left: 1rem;
    }

    .buttonR {
        bottom: 1rem;
        right: 1rem;
    }
}
