.keywords {
  margin-top: .5rem;
  text-align: left;
  display: inline-block;

  border-radius: 5px;
  color: white;
  float: left;
  width: 90%;
}

.keyword {
  border-radius: 2rem;
  padding: .1rem .8rem;
  font-size: 1.4rem;
  margin-right: .6rem;
  border: 2px solid var(--g-font-color);
  color: var(--g-font-color);
  cursor: pointer;
  user-select: none;
}