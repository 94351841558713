/* create-blog.module.scss */

.createBlogContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.formGroup {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column; /* Arrange items vertically */
}

.label {
    font-weight: bold;
}

.inputField,
.selectField {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 10px; /* Add some spacing between form fields */
}

.createButton {
    padding: 10px 20px;
    width: 8rem;
    height: 4rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
}

.imageUploadContainer {
    height: 10rem;
    position: relative; /* Position the container relative to its parent */
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageUpload {
    max-width: 100%; /* Ensure the image upload fits within its container */
    max-height: 300px; /* Set a maximum height for the uploaded image */
}

.blogDetail {
    padding: 3rem 5rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 800px;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 5px;
        margin: 1rem 0;
    }

    h3 {
        font-size: 1.5rem;
        color: #333;
    }

    p {
        font-size: 1rem;
        line-height: 1.6;
        color: #666;
        margin-bottom: 1rem;
    }

    .status {
        font-weight: bold;
    }

    .createdAt {
        font-style: italic;
        color: #999;
    }

    // Media query for responsive padding
    @media (max-width: 800px) {
        padding: 1rem 2.5rem;
    }
}

.previewSection {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    margin: 20px;
}
