.follower {
    display: inline-block;
    font-weight: bold;
    margin-top: 1.45rem;
    margin-right: 1.2rem;
    font-size: 2.08rem;
    position: relative;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
    cursor: default;
}

.follower::before {
    display: inline-block;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    left: -1.92rem;
    top: 0.8rem;
    margin-right: 0.32rem;
    background-image: url("../../../../../../theme/resource/icon/rank.svg");
}

.follower:hover {
    opacity: 1;
}

@media all and (max-width: 800px) {
    .follower {
        margin: 0;
        margin-left: 3rem;
    }
}
