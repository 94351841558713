.navigationButton {
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    img {
        width: 20px;
        height: auto;
    }
}

.chevron {
    background: url("../../../../resource/icon/chevron.svg") no-repeat center
        center;
    background-size: contain;
    height: 2rem;
    width: 2em;
    margin: auto 0;
    border: none;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
}

.chevron--double {
    background: url("../../../../resource/icon/chevron.svg") no-repeat left
            center,
        url("../../../../resource/icon/chevron.svg") no-repeat right center;
    background-size: contain;
    width: 2.15em;
}

.chevron--prev {
    transform: rotate(180deg);
}

// ToDo @Yannick war das wichtig? Alle disabled buttons sind ausgeblendet gewesen deswegen hab ich das auskommentiert
//button:disabled {
//    opacity: 0;
//}