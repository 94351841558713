.panel {
    text-align: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    border-radius: 16px;
    padding-bottom: 5rem;
    padding-top: 2.5rem;
}

.boost_count {
    margin-top: 2rem;
    color: rgb(22, 222, 153);
}

.boost_button {
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 5rem;
    display: flex;
    height: 5rem;
    width: 16rem;
    padding: 1rem;
    background: rgba(25,222, 149, .3);
    align-items: center;
    justify-content: left;
    transition: width 0.3s ease, background 0.3s ease; /* Add transition properties */
}

.boost_button_text {
    font-weight: bold;
}

.boost_button:active {
    filter: brightness(150%);
}

.boost_icon {
    background: url("../../theme/resource/icon/rocket.svg");
    width: 3rem;
    height: 3rem;
}

.choices_container {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    padding: 0;
    width: 70%;
    @media (max-width: 800px) {
        flex-direction: column;
    }
}

.header {
    font-weight: bolder;
}

.info {
    color: var(--g-color-light-gray);
}

.boost_icon_1 {
    background: url('../../theme/resource/icon/booster.svg');
    width: 10rem;
    height: 10rem;
}

.boost_icon_5 {
    background: url('../../theme/resource/icon/booster_stack_5.svg');;
    width: 10rem;
    height: 15.4rem;
}

.boost_icon_10 {
    background: url('../../theme/resource/icon/booster_stack_10.svg');
    width: 10rem;
    height: 20.3rem;
}

.boost_icon_1,
.boost_icon_5,
.boost_icon_10 {
    background-repeat: no-repeat;
    background-position-x: .2rem;
}

.name {
    margin-top: 2rem;
}

.purchase_button {
    margin-top: 1rem;
    border-radius: 2rem;
    padding: .5rem 1.5rem;
}

.price {
    color: var(--g-color-light-gray);
}

.booster_item {
    align-self: flex-end;
    @media (max-width: 800px) {
        margin-bottom: 5rem;
    }
}