.frame, .image, .placeholder, .error {
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    background-repeat: no-repeat;
}

.error {
    background: linear-gradient(315deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
}