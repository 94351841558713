.dropdown {
    background-color: white;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    box-shadow: var(--g-regular-shadow);
    width: 40%;
    position: absolute;
    overflow: hidden;
    text-align: left;

    transition: height 350ms, opacity 350ms, border-radius 350ms;
    opacity: 1;

    z-index: 1000000;

    max-height: 25rem;
}

.hint {
    cursor: pointer;
    padding-left: 3rem;
}

.selected_hint {
    cursor: pointer;
    padding-left: 3rem;
    background: var(--g-border-color);
}

.hint:hover {
    background: var(--g-border-color);
}

.hint::before .selected_hint::before {
    content: '';
    position: absolute;
    left: .5rem;

    width: 1.28rem;
    height: 1.28rem;
    margin-top: .5rem;
    
    background-image: url('../../../../../../../theme/resource/icon/go.svg');
    opacity: .8;

}