.hidden_menu {
    opacity: 0;
    transition: opacity .2s ease-in-out;
}

.avatar {
    width: 8rem;
    height: 8rem;
    position: absolute;
    top: 0;
    left: 1rem;
    bottom: 0;
    margin: auto 0;
}

.panel:hover .hidden_menu {
    opacity: 1;
}

.panel {
    border-radius: var(--g-image-radius);
    height: 10rem;
    position: relative;
    color: var(--g-font-color);
    background: white;
    box-shadow: var(--g-regular-shadow);
    overflow-wrap: break-word;
    text-align: left;
    margin-bottom: 1.5em;
}

.details {
    position: absolute;
    max-width: 100%;
    width: calc(100% - 16rem);
    height: 9rem;
    bottom: 0;
    top: 0;
    margin: auto 0;
    left: 11rem;
}

.info_bar {
    position: absolute; /* Keep absolute positioning */
    top: 20%; /* Vertically center the badge */
    transform: translateY(-50%); /* Adjust for perfect centering */
    opacity: .75;
}

.info_bar_premium {
    margin-left: 2rem;
}

.info_bar span:last-child {
    margin-left: .75rem;
}

.icon_rank::before {
    background-image: url('../../../../theme/resource/icon/rank.svg');
    width: 1.35rem;
    height: 1.35rem;
    margin-top: .45rem;
}

.icon_dot::before {
    background-image: url('../../../../theme/resource/icon/ball.svg');
    width: .75rem;
    height: .75rem;
    margin-top: .75rem;
}

.displayname {
    font-size: 2.4rem;
    margin-right: 1rem;
    display: inline-block;
    max-height: 2.4rem;
}

.displaynameText, .displaynameTextPremium {
    display: inline-block; /* Ensure it is inline-block to apply text-overflow */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.premiumBadgeContainer {
    position: absolute; /* Position the badge absolutely */
    top: 20%; /* Vertically center the badge */
    transform: translateY(-50%); /* Adjust for perfect centering */
    flex-shrink: 0; /* Prevent the icon from shrinking */
}

.latest_message {
    font-size: 1.8rem;
    height: 2.7rem;
    overflow: hidden;
}

.chevron {
    background: url('../../../../theme/resource/icon/chevron.svg');
    height: 4rem;
    width: 4rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1rem;
    margin: auto 0;
    opacity: .5;
    transition: opacity .2s ease-in-out;
}

.panel:hover .chevron {
    opacity: 1;
}

@media (max-width: 735px) {
    .hidden_menu {
        opacity: 1;
    }

    .displayname {
        margin-right: 3rem;
        display: block;
    }

    .displaynameText {
        max-width: calc(100% - 1rem); /* Adjust the width as needed for non-premium users */
    }

    .displaynameTextPremium {
        max-width: calc(100% - 2.3rem); /* Adjust the width as needed for premium users */
    }

    .info_bar_premium{
        margin-left: 0;
    }

    .info_bar {
        position: absolute; /* Keep absolute positioning */
        top: 50%; /* Vertically center the badge */
        transform: translateY(-50%); /* Adjust for perfect centering */
        opacity: .75;
    }

    .chevron {
        display: none;
    }

    .details {
        width: calc(100% - 12.5rem);
    }
    .latest_message{
        margin-top: 3.5rem;
    }
}
