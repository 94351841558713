.message {
    margin-bottom: 1rem;
}

.bubble {
    max-width: 70%;
    word-break: break-word;
    display: inline-block;
    background: white;
    box-shadow: var(--g-regular-shadow);
    padding: 1rem 1.5rem;
    text-align: left;
}

.sender {
    text-align: right;
}

.sender {
    .bubble {
        border-radius: 15px 15px 0 15px;
        background: var(--g-color-primary);
    }

    .timestamp {
        float: right;
        color: var(--g-border-color);
    }

    .content {
        color: white;
    }
}

.receiverLink {
    text-decoration: underline;
}

.senderLink {
    color: white;
    text-decoration: underline;
}


.receiver .bubble {
    border-radius: 15px 15px 15px 0;
}

.timestamp {
    display: block;
    margin-top: -.1em;
    font-size: 1.25rem;
    color: rgba(0,0,0,.5);
}
.imageContainer {
    display: inline-block;
    margin: 4px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.uploadedImage {
    width: 400px;
    height: auto;
    object-fit: cover;
    display: block;
}

// For a group of images
.imageGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
}

// Responsive adjustments
@media (max-width: 600px) {
    .uploadedImage {
        width: 200px;
        height: auto;
    }
}

// Optional: Add a subtle overlay on hover
.imageContainer::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.imageContainer:hover::after {
    opacity: 1;
}