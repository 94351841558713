.panel {
    position: relative;
    border: 2px solid transparent;
    border-radius: var(--g-image-radius);
    color: var(--g-font-color);
    background: white;
    background: linear-gradient(
        to bottom right,
        #fff,
        #fcfcfc
    ); /* Gradient from left to right */

    overflow-wrap: break-word;
    margin-bottom: 1.5em;
    width: 100%;
    padding: 16px !important;
    text-align: left;
    cursor: pointer;
    transition: background 0.3s;
    container-type: inline-size;

    .name {
        //  TODO fix make offer author name lighter to improve readability of important information
        color: var(--g-color-profile-mid-gray);
    }

}

.detailsContainer{
    position: relative;
}

.panel:hover {
    .delete_icon {
        display: block;
    }

    .edit_icon {
        display: block;
    }

    .name {
        text-decoration: underline;
    }
}

.description {
    margin-top: 1rem;
    text-align: left;
    height: fit-content;
    margin-bottom: 2rem;
}

.description_short {
    margin-left: 1rem;
    width: 100%;
    height: auto; /* Adjusted to fit content, can set specific height if needed */
    text-align: left;
    white-space: normal; /* Allows the text to wrap */
    overflow: hidden; /* Hides any overflow */
    text-overflow: ellipsis; /* This may need to be adjusted or removed */
    line-height: 1.5em; /* Example line height, adjust as needed */
    max-height: 4rem; /* Example max height, adjust as needed */
    margin-bottom: 2rem;
    font-weight: 400;
}

.description_expanded {
    margin-top: 0;
    text-align: left;
}

.date {
    top: 1rem;
    right: 1rem;
    float: left;
    font-weight: 200;
}

.date::before {
    display: none;
    background-image: url("../../../../theme/resource/icon/ball.svg");
    width: 0.4rem;
    height: 0.4rem;
    margin-top: 0.75rem;
}

.context_menu {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    margin: 1rem;

    .menu {
        font-size: 1.6rem;
        margin-left: 1.6rem;
        background: pink;
        position: relative;
        top: -.5rem;
    }
}

.bookmark {
    height: 2rem;
    margin-left: 1.5rem;

}

.application {
    margin-top: 2rem;
}

.applied_tag {
    user-select: none;
    font-size: 1.4rem;
    text-align: center;
    color: white;
    padding: 0.1rem 1.8rem;
    border-radius: 5rem;
    background-color: var(--g-color-green);
    margin-top: 0.4rem;
    margin-left: 1rem;
}

.delete_icon {
    display: none;
    float: right;
    width: 2rem;
    height: 2rem;
    background-image: url("../../../../theme/resource/icon/delete.svg");
}

.delete_icon:active {
    scale: 1.2;
}

.edit_icon {
    margin-right: 1rem;
    display: none;
    float: right;
    width: 2rem;
    height: 2rem;
    background-image: url("../../../../theme/resource/icon/edit.svg");
}

.edit_icon:hover {
    scale: 1.2;
}

.delete_icon:hover {
    scale: 1.2;
}

.target_group {
    font-weight: lighter;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

@media screen and (max-width: 800px) {
    .date {
        position: relative;
        top: 0;
        right: 0;
        float: left;
        font-weight: 200;
    }
    .date::before {
        display: block;
    }
    .name {
        width: 100% !important;
    }
    .panel {
        height: fit-content;
    }
}

@container (max-width: 1000px) {
    .date {
        display: none;
    }
    .date::before {
        display: none;
    }
    .name {
        width: 100% !important;
    }
}
