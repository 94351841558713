
.upload {
    display: inline-block;
    padding: 4rem;
    width: 4rem;
    height: 4rem;
    opacity: .7;
    transition: opacity 250ms ease-in-out;
    left: 0;
    right: 0;
    top: calc(50% - 4rem);
    position: absolute;
    border-radius: 100%;

    background: rgb(255, 255, 255);
}

.upload::after {
    content: '';
    display: inline-block;
    background-image: url('../../../../../../theme/resource/icon/camera.svg');
    width: 4rem;
    height: 4rem;
    left: 0;
    right: 0;
    transform: translate(-50%, -50%);
}

.upload:hover {
    opacity: 1;
}

.upload input {
    display: none;
}

@media all and (max-width: 850px) {
    .uploadEdge {
        border-radius: 0 0 0 50%;
        transform: translateY(-50%);
        right: 0;
        left: auto;
        top: 3.2rem;
    }
}
