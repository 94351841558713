.p0{ padding:0 }
.pt0{ padding-top:0 }
.pr0{ padding-right:0 }
.pb0{ padding-bottom:0 }
.pl0{ padding-left:0 }
.px0{ padding-left:0; padding-right:0 }
.py0{ padding-top:0;  padding-bottom:0 }

.p1{ padding: .8rem }
.pt1{ padding-top: .8rem }
.pr1{ padding-right: .8rem }
.pb1{ padding-bottom: .8rem }
.pl1{ padding-left: .8rem }
.py1{ padding-top: .8rem; padding-bottom: .8rem }
.px1{ padding-left: .8rem; padding-right: .8rem }

.p2{ padding: 1.6rem }
.pt2{ padding-top: 1.6rem }
.pr2{ padding-right: 1.6rem }
.pb2{ padding-bottom: 1.6rem }
.pl2{ padding-left: 1.6rem }
.py2{ padding-top: 1.6rem; padding-bottom: 1.6rem }
.px2{ padding-left: 1.6rem; padding-right: 1.6rem }

.p3{ padding: 3.2rem }
.pt3{ padding-top: 3.2rem }
.pr3{ padding-right: 3.2rem }
.pb3{ padding-bottom: 3.2rem }
.pl3{ padding-left: 3.2rem }
.py3{ padding-top: 3.2rem; padding-bottom: 3.2rem }
.px3{ padding-left: 3.2rem; padding-right: 3.2rem }

.p4{ padding: 4.8rem }
.pt4{ padding-top: 4.8rem }
.pr4{ padding-right: 4.8rem }
.pb4{ padding-bottom: 4.8rem }
.pl4{ padding-left: 4.8rem }
.py4{ padding-top: 4.8rem; padding-bottom: 4.8rem }
.px4{ padding-left: 4.8rem; padding-right: 4.8rem }

.p5{ padding: 6.4rem }
.pt5{ padding-top: 6.4rem }
.pr5{ padding-right: 6.4rem }
.pb5{ padding-bottom: 6.4rem }
.pl5{ padding-left: 6.4rem }
.py5{ padding-top: 6.4rem; padding-bottom: 6.4rem }
.px5{ padding-left: 6.4rem; padding-right: 6.4rem }


.l-p0{ padding:0 }
.l-pt0{ padding-top:0 }
.l-pr0{ padding-right:0 }
.l-pb0{ padding-bottom:0 }
.l-pl0{ padding-left:0 }
.l-px0{ padding-left:0; padding-right:0 }
.l-py0{ padding-top:0;  padding-bottom:0 }

.l-p1{ padding: .8rem }
.l-pt1{ padding-top: .8rem }
.l-pr1{ padding-right: .8rem }
.l-pb1{ padding-bottom: .8rem }
.l-pl1{ padding-left: .8rem }
.l-py1{ padding-top: .8rem; padding-bottom: .8rem }
.l-px1{ padding-left: .8rem; padding-right: .8rem }

.l-p2{ padding: 1.6rem }
.l-pt2{ padding-top: 1.6rem }
.l-pr2{ padding-right: 1.6rem }
.l-pb2{ padding-bottom: 1.6rem }
.l-pl2{ padding-left: 1.6rem }
.l-py2{ padding-top: 1.6rem; padding-bottom: 1.6rem }
.l-px2{ padding-left: 1.6rem; padding-right: 1.6rem }

.l-p3{ padding: 3.2rem }
.l-pt3{ padding-top: 3.2rem }
.l-pr3{ padding-right: 3.2rem }
.l-pb3{ padding-bottom: 3.2rem }
.l-pl3{ padding-left: 3.2rem }
.l-py3{ padding-top: 3.2rem; padding-bottom: 3.2rem }
.l-px3{ padding-left: 3.2rem; padding-right: 3.2rem }

.l-p4{ padding: 4.8rem }
.l-pt4{ padding-top: 4.8rem }
.l-pr4{ padding-right: 4.8rem }
.l-pb4{ padding-bottom: 4.8rem }
.l-pl4{ padding-left: 4.8rem }
.l-py4{ padding-top: 4.8rem; padding-bottom: 4.8rem }
.l-px4{ padding-left: 4.8rem; padding-right: 4.8rem }

.l-p5{ padding: 6.4rem }
.l-pt5{ padding-top: 6.4rem }
.l-pr5{ padding-right: 6.4rem }
.l-pb5{ padding-bottom: 6.4rem }
.l-pl5{ padding-left: 6.4rem }
.l-py5{ padding-top: 6.4rem; padding-bottom: 6.4rem }
.l-px5{ padding-left: 6.4rem; padding-right: 6.4rem }

@media only screen and (max-width: $g-medium) {
    .m-p0{ padding:0 }
    .m-pt0{ padding-top:0 }
    .m-pr0{ padding-right:0 }
    .m-pb0{ padding-bottom:0 }
    .m-pl0{ padding-left:0 }
    .m-px0{ padding-left:0; padding-right:0 }
    .m-py0{ padding-top:0;  padding-bottom:0 }
    
    .m-p1{ padding: .8rem }
    .m-pt1{ padding-top: .8rem }
    .m-pr1{ padding-right: .8rem }
    .m-pb1{ padding-bottom: .8rem }
    .m-pl1{ padding-left: .8rem }
    .m-py1{ padding-top: .8rem; padding-bottom: .8rem }
    .m-px1{ padding-left: .8rem; padding-right: .8rem }
    
    .m-p2{ padding: 1.6rem }
    .m-pt2{ padding-top: 1.6rem }
    .m-pr2{ padding-right: 1.6rem }
    .m-pb2{ padding-bottom: 1.6rem }
    .m-pl2{ padding-left: 1.6rem }
    .m-py2{ padding-top: 1.6rem; padding-bottom: 1.6rem }
    .m-px2{ padding-left: 1.6rem; padding-right: 1.6rem }
    
    .m-p3{ padding: 3.2rem }
    .m-pt3{ padding-top: 3.2rem }
    .m-pr3{ padding-right: 3.2rem }
    .m-pb3{ padding-bottom: 3.2rem }
    .m-pl3{ padding-left: 3.2rem }
    .m-py3{ padding-top: 3.2rem; padding-bottom: 3.2rem }
    .m-px3{ padding-left: 3.2rem; padding-right: 3.2rem }
    
    .m-p4{ padding: 4.8rem }
    .m-pt4{ padding-top: 4.8rem }
    .m-pr4{ padding-right: 4.8rem }
    .m-pb4{ padding-bottom: 4.8rem }
    .m-pl4{ padding-left: 4.8rem }
    .m-py4{ padding-top: 4.8rem; padding-bottom: 4.8rem }
    .m-px4{ padding-left: 4.8rem; padding-right: 4.8rem }
    
    .m-p5{ padding: 6.4rem }
    .m-pt5{ padding-top: 6.4rem }
    .m-pr5{ padding-right: 6.4rem }
    .m-pb5{ padding-bottom: 6.4rem }
    .m-pl5{ padding-left: 6.4rem }
    .m-py5{ padding-top: 6.4rem; padding-bottom: 6.4rem }
    .m-px5{ padding-left: 6.4rem; padding-right: 6.4rem }
}

@media only screen and (max-width: $g-small) {
    .s-p0{ padding:0 }
    .s-pt0{ padding-top:0 }
    .s-pr0{ padding-right:0 }
    .s-pb0{ padding-bottom:0 }
    .s-pl0{ padding-left:0 }
    .s-px0{ padding-left:0; padding-right:0 }
    .s-py0{ padding-top:0;  padding-bottom:0 }
    
    .s-p1{ padding: .8rem }
    .s-pt1{ padding-top: .8rem }
    .s-pr1{ padding-right: .8rem }
    .s-pb1{ padding-bottom: .8rem }
    .s-pl1{ padding-left: .8rem }
    .s-py1{ padding-top: .8rem; padding-bottom: .8rem }
    .s-px1{ padding-left: .8rem; padding-right: .8rem }
    
    .s-p2{ padding: 1.6rem }
    .s-pt2{ padding-top: 1.6rem }
    .s-pr2{ padding-right: 1.6rem }
    .s-pb2{ padding-bottom: 1.6rem }
    .s-pl2{ padding-left: 1.6rem }
    .s-py2{ padding-top: 1.6rem; padding-bottom: 1.6rem }
    .s-px2{ padding-left: 1.6rem; padding-right: 1.6rem }
    
    .s-p3{ padding: 3.2rem }
    .s-pt3{ padding-top: 3.2rem }
    .s-pr3{ padding-right: 3.2rem }
    .s-pb3{ padding-bottom: 3.2rem }
    .s-pl3{ padding-left: 3.2rem }
    .s-py3{ padding-top: 3.2rem; padding-bottom: 3.2rem }
    .s-px3{ padding-left: 3.2rem; padding-right: 3.2rem }
    
    .s-p4{ padding: 4.8rem }
    .s-pt4{ padding-top: 4.8rem }
    .s-pr4{ padding-right: 4.8rem }
    .s-pb4{ padding-bottom: 4.8rem }
    .s-pl4{ padding-left: 4.8rem }
    .s-py4{ padding-top: 4.8rem; padding-bottom: 4.8rem }
    .s-px4{ padding-left: 4.8rem; padding-right: 4.8rem }
    
    .s-p5{ padding: 6.4rem }
    .s-pt5{ padding-top: 6.4rem }
    .s-pr5{ padding-right: 6.4rem }
    .s-pb5{ padding-bottom: 6.4rem }
    .s-pl5{ padding-left: 6.4rem }
    .s-py5{ padding-top: 6.4rem; padding-bottom: 6.4rem }
    .s-px5{ padding-left: 6.4rem; padding-right: 6.4rem }
}