.globe {
    background: url("../../resource/icon/globe.svg");
    width: 1.5rem;
    height: 1.5rem;
    margin-right: .5rem;
    
}

.container {
    width: 10rem;
    padding-left: 1rem;
    text-align: left;
    cursor: pointer;
}

.container:hover {
    background: rgba(0,0,0,.1);
    border-radius: 5px;
}


.language {
    font-size: 15px;
    color: var(--g-label-color);
}
