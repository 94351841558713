.offerContainer {
    position: relative;
    max-width: 1080px !important;
    max-height: 1350px !important;
    font-family: Arial, sans-serif;
    overflow: hidden;
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}

.backgroundBox {
    opacity: .5;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(155deg, rgb(0, 177, 255) 0%, rgb(0, 255, 121) 100%);
}

.content {
    position: relative;
    padding: 6rem 15rem;
    border-radius: 20px;
}

.header {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    text-align: left;
}

.icon {
    font-size: 40px;
    margin-right: 20px;
}

.headerText {
    color: white;
    font-size: 28px;
    font-weight: bold;
}

.mainContent {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    background-color: white;
    text-align: left;
    padding: 2rem;
    border-radius: 20px;
    border: 3px solid gray;
}

.imageAndDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title {
    padding-left: 2rem;
    color: black;
    max-height: 7rem;
    font-size: 5rem;
    padding-bottom: 2rem;
    overflow: hidden;
    margin-bottom: 2rem;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.imageBox {
    flex: 1;
    position: relative;
}

.mainImage {
    width: 30rem;
    height: 30rem;
    border-radius: 20px;
    object-fit: cover;
}

.descriptionBox {
}

.description {
    font-size: 28px;
    font-weight: 400;
    overflow: hidden;
    white-space: normal;
    max-height: 28.5rem;
    width: 30rem;
    padding: 0 4rem;
    text-align: left;
    vertical-align: top;
    text-overflow: ellipsis !important;
    display: inline-block;
}

.infoItemLabels {
}

.label {
    display: inline-block;
    background-color: #e0f7fa;
    color: #00796b;
    border-radius: 8px;
    padding: 4px 16px;
    margin-right: 10px;
    font-size: 24px;
}

.footerImageBox {
    width: 100%;
    height: auto;
}

.footerImage {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.downloadContainer {
    text-align: center;
    margin-top: 40px;
}

.downloadButton {
    margin-top: 40px;
    padding: 20px 40px;
    font-size: 32px;
    background-color: #3897f0;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.downloadButton:hover {
    background-color: #3186e3;
}

.avatar_container {
    width: 44rem;
    height: 44rem;
    border-radius: 50px;
    flex-shrink: 0;
    flex-grow: 0;
    background-size: cover;
    background-position: top;
    transition: transform 0.3s ease;
    max-width: 70rem;
    max-height: 70rem;
    display: flex;
}

.avatar {
    width: 44rem;
    height: 44rem;
    max-width: 60rem;
    max-height: 60rem;
    border-radius: 50px;
    flex-shrink: 0;
    flex-grow: 0;
    background-size: cover;
    background-position: top;
    transition: transform 0.3s ease;
}

.avatar img {
    max-width: 70rem;
    max-height: 70rem;
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.premium {
    margin-left: .6rem;
}

.header {
    text-align: center;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, .9);
    height: 10rem;
    border-radius: 3rem;
    border: 6px solid gray;
    padding: 1rem;
}

.headerIcon {
    position: relative;
    padding-top: 1.5rem;
}

.headerSmallIcon {
    position: absolute;
    bottom: -0.2rem;
    right: -1rem;
}

.name {
    color: var(--g-font-color);
    margin: 1rem;
    margin-top: 0;
    font-size: 48px !important;
    font-weight: bolder !important;
    text-align: left;
    cursor: pointer;
    overflow: auto;
    white-space: wrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    margin-bottom: 16px;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.icon {
    font-size: 2.8rem;
    font-weight: 450;
    margin-right: 2rem;
    height: 2rem;
}

.location {
    font-size: 2.8rem;
    font-weight: 450;
    margin-right: 2rem;
    height: 3.2rem !important;
    overflow: hidden;
}

.author {
    margin-right: 2rem;
    font-size: 40px;
    font-weight: 400;
    width: 100%;
    vertical-align: center;
}

.author:hover {
    cursor: pointer;
    text-decoration: underline;
}

.author::before {
    content: "";
    background: url("../../../../../../theme/resource/icon/user.svg");
    vertical-align: top;
    width: 5rem;
    margin-right: 1rem;
    height: 5rem;
    display: inline-block;
}

.info_bar {
    margin-left: 3rem;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.info_item {
    justify-content: flex-start !important;
    align-items: flex-start;
    text-align: left;
    width: 100% !important;
}

.info_item_labels {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 4px;
    justify-content: flex-start !important;
    text-align: left;
    width: 100% !important;
    border-radius: 16px;
}

.info_item_name {
    vertical-align: center !important;
    color: var(--g-color-green);
    margin-right: 1rem;
    margin-left: 1rem;
    display: inline-block;
}

.tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    font-size: 2.2rem;
    margin-top: 8px;
    text-align: center; /* Ensure text alignment */
    background-color: var(--g-color-light-green);
}

.keywords {
    overflow: hidden;
    display: flex;
    justify-content: center !important; /* Align items to the left */
    align-items: center !important; /* Align items to the start of the container */
    flex-wrap: wrap;
    text-align: center;
    gap: 0 16px;
    padding-top: 2rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    width: fit-content !important;
    overflow: hidden;
    max-height: 88px;
}

.avatar_container:hover img {
    transform: scale(1.05);
}

@container (max-width: 300px) {
    .name {
        font-size: medium;
    }
}

@media screen and (max-width: 800px) {
    .name {
        width: 100%;
        font-size: large;
        font-weight: 800;
    }
    .info_bar {
        width: 100%;
        margin-left: 0;
    }
    .avatar_container {
        width: 100%;
        height: auto;
    }
    .avatar {
        width: 100%;
        height: auto;
        .img {
            width: 100%;
            height: auto;
        }
    }
}

.target_group {
    font-weight: lighter;
    text-align: left;
    width: 100%;
    margin-top: 8px;
}

.description_short {
    margin-top: 24px;
    margin-left: 1rem;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: 4em;
    line-height: 1.5em;
    display: block;
    vertical-align: top;
    font-weight: 400;
}

.authorContainer {
    font-size: 28px;
    text-align: center;
    padding-top: 2rem;
}

.iconWrapper {
    padding-top: .5rem;
    vertical-align: middle;
}

.authorContainer span {
    margin-left: 8px; /* Reduce space between icon and text */
}

.tag_view {
    border-radius: 2rem;
    padding: .5rem 1rem;
    //margin-right: 1.2rem;
    color: var(--g-color-profile-gray);
    background: var(--g-color-profile-dark-gray);
    font-size: 2.5rem;
}

.tag_view::before {
    content: '#';
}
