.container {
    display: flex;
    align-items: center;
    width: 100%;
}

.icon {
    mask: url("../../../../theme/resource/icon/network.svg") no-repeat center / contain;
    -webkit-mask: url("../../../../theme/resource/icon/network.svg") no-repeat center / contain;
    background-color: var(--g-color-green);
    width: 6rem !important;
    height: 6rem !important;
    flex-shrink: 0;
    margin-right: 1rem;
}

.content {
    flex-grow: 1;
    margin-right: 1rem;
}

.title {
    padding: 0 1rem !important;
    strong {
        font-weight: 700; // Or use a specific weight that matches your design
    }
}

.description {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: #4B5563;
}

.buttons {
    display: flex;
    gap: 1rem;
    flex-shrink: 0;
    margin: 0 0.5rem;
    width: fit-content;
}

.dropdown, .connect_button {
    white-space: nowrap;
}

@media (max-width: 800px) {
    .buttons {
        flex-direction: column;
    }

    .title {
        text-align: center;
        margin-bottom: 1rem;
    }

    .select_button, .connect_button {
        max-width: 100%;
    }

    .connect_button {
        margin-top: 0.5rem;
    }

    .container {
        flex-direction: column;
        padding: 1rem;
    }

    .icon {
        display: none;
    }
}
