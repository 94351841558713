.pageNumberButton,
.currentPageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    border-radius: 100px;
    border: none;
    background-color: transparent;
    transition: opacity 0.2s ease-in-out;
    padding: 1.1rem;
    &:hover {
        opacity: 0.8;
    }
}

.currentPageButton {
    background-color: var(--g-color-blue);
    color: #ffffff;
    opacity: 1 !important;
    &:hover {
        opacity: 1;
    }
}

.pillShapeButton {
    border-radius: 100px !important;
}
