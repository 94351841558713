.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin: 0.5rem;
}

.left {
    display: flex;
    align-items: center;
    margin: 0;
}

.container:hover {
    opacity: 0.7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.followerCount {
    margin-right: 0;
    margin-left: 1rem;
    font-weight: bold;
    color: #1a8cff;
}

.profilePicture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.name {
    display: flex;
    flex-direction: column;
}
