.section {
    position: relative;
    max-width: 140rem;
    padding: 0;
    margin-top: 15rem;
    text-align: center;
}

.testimonial {
    border-radius: 2.5rem;
    width: 40rem;
    padding: 5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2), 0 8px 16px rgba(0, 0, 0, .1);
}

.testimonial_text {
    font-style: italic;
    min-height: 24rem;
}

@media (max-width: 1300px) {
    .testimonial_text {
        min-height: auto;
    }
}

.testimonial_container {
    margin-top: 8rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

}

.star {
    border-radius: 100%;
    height: 2.5rem;
    width: 2.5rem;
    background: linear-gradient(315deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
}

.icon {
    margin-top: 3rem;
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.giorgio {
    background: url("../../../../theme/resource/image/landing/giorgio.png");
}
.mugdesignhub {
    background: url("../../../../theme/resource/image/landing/mugdesignhub.jpeg");
}
.frank {
    background: url("../../../../theme/resource/image/landing/frank.jpeg");
}


.star_container {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    width: 80%;
}


@media (max-width: 1300px) {
    .testimonial_container {
        flex-direction: column; /* Stellt sicher, dass die Testimonials untereinander angeordnet sind */
        align-items: center; /* Zentriert die Testimonials horizontal */
    }

    .testimonial {
        margin-bottom: 8rem; /* Abstand zwischen Testimonials */
    }
}

@media (max-width: 500px) {
    .testimonial {
        width: 90%;
        padding-top: 4rem;
    }
}