.textbox {
    position: absolute;
    bottom: 0;
    width: calc(100% - 4rem);
    margin: 0 2rem;
}

.sendButton {
    position: absolute;
    right: 1rem;
    bottom: 1.75rem;
    background-color: var(--g-color-primary);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
}

.sendButton::before {
    content: '';
    background: url('../../../../theme/resource/icon/send.svg');
    margin-top: .55rem;
    margin-left: .6rem;
    width: 1.45rem;
    height: 1.45rem;
}

.sendButton:hover {
    filter: brightness(90%);
}

.sendDisabled {
    background-color: var(--g-label-color);
}

.imagePreview {
    position: relative;
    width: 200px;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px; /* Add space between images */

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.removeImageButton {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
    border: none;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    cursor: pointer;
    padding: 0;

    &::after {
        content: '';
        display: inline-block;
        background-image: url('../../../../theme/resource/icon/close.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        background: rgba(255, 255, 255, 0.9);
    }
}

.imagePreviewContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}