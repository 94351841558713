.chat_main {
    padding-top: 0;
    padding-bottom: 0;
}

.section_container {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
    height: calc(100vh - 7.1rem - 2rem - env(safe-area-inset-top, 0));
}

.inbox_container {
    width: 30%;
    vertical-align: top;
    padding-top: 0;
    overflow-y: scroll;
    scrollbar-width: none;
    height: 100%;
    box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,0.05);
}

.chat_container {
    display: inline-block;
    position: relative;
    width: 70%;
    text-align: left;
    height: 100%;
}

.chat_loading, .chat_not_found {
    height: calc(50vh - 7.1rem);
    margin-top: -3.2rem;
    min-height: calc(100vh - 340px);
}

@media all and (max-width: 1020px) {
    .inbox_container {
        display: none;
    }

    .chat_container {
        width: 100%;
        max-width: 680px;
    }
}