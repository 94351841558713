.container {
    text-align: center;
}

.orange_cat {
    background: white;
    background: url('../../theme/resource/icon/orange_cat.svg');
    height: 8rem;
    width: 8rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}