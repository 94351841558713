.container {
    background-color: black;
    border-radius: 8px;
    color: white;
    position: relative;
    width: 20rem;
    height: 6.8rem;
    cursor: default;
}

.icon {
    position: absolute;
    background-image: url(../../resource/icon/influspace.svg);
    width: 4.7rem;
    height: 4.7rem;
    left: 1.2rem;
    top: 1rem;
}

.cta {
    position: absolute;
    top: .8rem;
    font-size: 1.7rem;
    left: 6.7rem;
}

.label {
    position: absolute;
    vertical-align: top;
    top: 2.5rem;
    
    font-size: 2.6rem;
    left: 6.5rem;
}