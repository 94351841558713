/* 
    Known Bug: Items get bigger and spread 
    if profile quantity is less than 4 
    in desktop mode 
*/

.grid {
    display: grid;
    grid-column-gap: 2.4rem;
    grid-row-gap: 4.8rem;
    justify-content: space-between;
    grid-template-columns: fit-content(100%) fit-content(100%) fit-content(100%) fit-content(100%);
    flex-wrap: wrap;

    width: 100%;
    max-width: 1044px;
    padding: 0;
    position: relative;
}

@media (max-width: 1050px) {
    .grid {
        grid-template-columns: repeat(3, 1fr)
    }
}
    
@media (max-width: 780px) {
    .grid {
        padding: 0 1.6rem;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 500px) {
    .grid {
        grid-template-columns: repeat(1, 1fr); 
    }
}