.avatar {
    background: none;
    cursor: pointer;
}

.avatar img {
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.unread_messages {
    position: absolute;
    right: 0;
    top: 0;
    color: white;
    border-radius: 50%;
    box-shadow: var(--g-regular-shadow);
    background: red;
    width: 2.8rem;
    height: 2.8rem;
    font-size: 2rem;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
}