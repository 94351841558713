.selector {
    margin-bottom: 3.2rem;
}

.selector span {
    display: inline-block;
    width: 3.2rem;
    height: 3.2rem;
}

.selector .primary {
    background-color: var(--g-color-primary);
}

.selector .success {
    background-color: var(--g-color-success);
}

.selector .warning {
    background-color: var(--g-color-warning);
}

.selector .failure {
    background-color: var(--g-color-failure);
}

.selector .neutral {
    background-color: white;
}