.user {
    position: absolute;

    top: 1.2rem;
    right: 0;

    color: var(--g-font-color);
}

.label {
    position: absolute;
    padding: .16rem .96rem;
    right: 6.4rem;
    top: 0rem;
    font-size: 1.92rem;
    border-radius: 8px;
    margin: 0;
    transition: background-color .2s ease-in-out;
    white-space: nowrap;
}

.icon_user {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 1.5rem;
    width: 2.88rem;
    height: 2.88rem;
}

.icon_sign_in {
    display: inline-block;
    position: absolute;
    right: 3.2rem;
    top: .0625;
    background-color: aquamarine;
    width: 2.88rem;
    height: 2.88rem;
    background: url('../../../../../theme/resource/icon/sign_in.svg');
}

.user, .clickable {
    cursor: pointer;
}

.user:hover .label {
    background: rgb(225, 225, 225);
}



.option {
    display: inline-block;
    position: absolute;
    right: 0;
}

.options {
    display: inline-block;
    position: absolute;
    background-color: white;
    border-radius: 8px;
    margin-top: 0;
    right: 0;
    overflow: hidden;
    min-width: 140px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .1);
    border-top: none;
    z-index: 200;
    top: 6.4rem;
    right: 1.6rem;
    transition: height 350ms, opacity 350ms, border-radius 350ms; /* height 350ms, opacity 500ms,  */
    opacity: 1;
}

.options.closed {
    height: 0;
    opacity: 0;
    border-radius: 20px;
}

.options.closed a {
    height: 2.08rem;
    transform: translateY(-300%);
}

.options a {
    color: rgb(50, 50, 50);
    padding: 1.2rem 1.6rem;
    display: block;
    font-weight: 800;
    width: 100%;
    text-align: left;
    height: 4.4rem;
    transition: all 350ms;
}

.options a:hover {
    background: rgba(0, 0, 0, .1);
    border-radius: 0;
}

.pre_icon::before {
    content: '';
    display: block;
    background: url('../../../../../theme/resource/icon/rank.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 2.24rem;
    height: 2.24rem;
    float: left;
    margin-top: 0rem;
    margin-right: .96rem;
}

.icon_settings.pre_icon::before {
    background-image: url('../../../../../theme/resource/icon/setting.svg');
}

.icon_bookmarks.pre_icon::before {
    background-image: url('../../../../../theme/resource/icon/bookmark_filled_dark.svg');
}

.icon_account.pre_icon::before {
    background-image: url('../../../../../theme/resource/icon/account.svg');
}

.icon_premium.pre_icon {
    color: rgb(217, 173, 93);
}

.icon_booster.pre_icon {
    color: rgb(22, 222, 153);
}

.icon_premium.pre_icon::before {
    background-image: url('../../../../../theme/resource/icon/premium_badge.svg');
    width: 2.4rem;
    height: 2.4rem;
    margin-left: -.1rem;
    margin-top: -.05rem;
}

.icon_booster.pre_icon::before {
    background-image: url('../../../../../theme/resource/icon/booster_menu.svg');
    width: 2.4rem;
    height: 2.4rem;
    margin-left: -.1rem;
    margin-top: -.05rem;
}

.icon_sign_out.pre_icon::before {
    background-image: url('../../../../../theme/resource/icon/sign_out.svg');
}

@media all and (max-width: 850px) {
    .label {
        display: none;
    }

    .icon_user, .icon_sign_in {
        right: 1.6rem;
    }
}

.avatar_image{
    border-radius: 50%;
    width: 36px !important;
    height: 36px !important;
    position: absolute;
    top: -.5rem;
    right: 1.5rem;
    border: .5px solid rgba(22, 222, 153, 0.5)
}