.inline { display: inline }
.inline-block { display: inline-block }
.block { display: block }
.none { display: none }

.l-inline { display: inline }
.l-inline-block { display: inline-block }
.l-block { display: block }

@media only screen and (max-width: $g-medium) {
    .m-inline { display: inline }
    .m-inline-block { display: inline-block }
    .m-block { display: block }
}

@media only screen and (max-width: $g-small) {
    .s-inline { display: inline }
    .s-inline-block { display: inline-block }
    .s-block { display: block }
}