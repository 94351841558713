.iconWithText {
    display: flex;
    align-items: center;
    box-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.07);
    margin-bottom: 3rem;
    margin-top: 1rem;
}
.iconWithText > div {
    margin-left: 5px; /* Adjust this value to move the text further from the icon */
    margin-right: 5px;
}

.info {
    margin-top: .4rem;
    padding-left: .4rem;
    font-weight: normal;
    font-size: 1.52rem;
    text-align: left;
    color: var(--g-color-light-gray);
}

.icon {
    width: 30px;
    height: 30px;
    background: url("../../../../../../theme/resource/icon/notification.svg");
    background-repeat: no-repeat;
}

.info {
    color: gray;
}

.input {
    float: right;
}

.slider {
    height: 3rem;
    margin-top: 1rem;
    width: 45%;
}

.buttonL {
    position: absolute;
    bottom: 1rem;
    left: 23rem;
}

@media only screen and (max-width: 735px) {
    .input {
        width: 100%;
    }
    .slider {
        width: 90%;
    }
    .buttonL {
        bottom: 1rem;
        left: 1rem;
    }
}
