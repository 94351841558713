.loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    background: rgb(0, 68, 255);
    background: linear-gradient(315deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
}

.loading .loader {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background-color: white;
    position: absolute;
    transform: rotate(30deg);
    animation: mainload 1.5s ease-in-out infinite;
}

.loading .loader::before {
    content: '';
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background-color: white;
    position: absolute;
    top: -35px;
    left: 0;
    animation: load 1.5s ease-in-out infinite;
    animation-delay: .4s;
}

.loading .loader::after {
    content: '';
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background-color: white;
    position: absolute;
    bottom: -35px;
    left: 0;
    animation: load 1.5s ease-in-out infinite;
    animation-delay: .2s;
}

@keyframes mainload {
    0% {
        transform: translateX(36px) translateY(20px) rotate(30deg);
    }
    50% {
        transform: translateX(-36px) translateY(-20px) rotate(30deg);
    }
    100% {
        transform: translateX(36px) translateY(20px) rotate(30deg);
    }
}

@keyframes load {
    0% {
        left: -40px;
    }
    50% {
        left: 40px;
    }
    100% {
        left: -40px;
    }
}
