.keyword_suggestions {
    text-align: left;
    margin-bottom: 2rem;
}

.tag_view {
    border-radius: 1.5rem;
    padding: .1rem .8rem;
    margin-right: .6rem;

    margin-bottom: .5rem;
    background-color: var(--g-font-color);
    border: 2px solid var(--g-font-color);

    color: white;
}

.tag_view::before {
    content: '#';
}

.tag_view:hover {
    cursor: pointer;
    border: 2px solid var(--g-font-color);
    background-color: white;
    color: black;
    border-color: var(--g-border-color);
}