.container {
    padding: 10rem;
    border-radius: 30px;
    text-align: center;
    //box-shadow: 0 25px 45px #00000064;
    margin-bottom: 10rem;
    margin-top: 5rem;
    background: url("../../../../theme/resource/image/landing/newsletter_background.svg")
        no-repeat center;
    background-size: 100%;
    max-width: 1400px;
    @media (max-width: 800px) {
        padding: 3rem;
        margin-top: 0;
        margin-bottom: 3rem;
        background-size: 200%;
    }

    h2 {
        color: var(--g-font-color);
        font-weight: 600;
    }

    p {
        font-size: 1.8rem;
        margin-bottom: 2rem;
        max-width: 30rem;
    }
}

.input_email {
    width: 50%;
    margin-right: 1rem;
    @media (max-width: 800px) {
        width: 100%;
        margin: 0;
        margin-bottom: 1rem;
    }
}

.button_send {
    background: #333539;
    width: 13rem;
    height: 5rem;
    text-align: center;
    color: white;
    padding: 1.3rem;
    cursor: pointer;
    border-radius: 3rem;
}

.button_send:hover {
    background-color: white;
    color: #333539;
}

