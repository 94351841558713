.avatar_container {
    width: 22rem;
    height: 22rem;
    border: 1px solid rgb(233,233,233);
    border-radius: .8rem;
    flex-shrink: 0;
    flex-grow: 0;
    background-size: cover;
    background-position: top;
    transition: transform 0.3s ease;
    max-width: 35rem;
    max-height: 35rem;
}

.avatar{
    position: relative;
    width: 22rem;
    height: 22rem;

    border-radius: .8rem;
    overflow: hidden;
    flex-shrink: 0;
    flex-grow: 0;
    background-size: cover;
    background-position: top;
}

.premium_lock_container {
    position: absolute;
    background: white;
    border-radius: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Zentriert das Element und skaliert es auf 50% */
    z-index: 9999; /* Stellt sicher, dass es ganz vorne ist */
    width: 70%; /* Beispielbreite, skaliert auf 50% des Elternteils */
    height: 65%; /* Beispielhöhe, skaliert auf 50% des Elternteils */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Zentriert den Inhalt vertikal */
    text-align: center; /* Optional, falls der Text zentriert werden soll */
}

.premium_lock {
    background: url("../../../../../../theme/resource/icon/block.svg");
    width: 5rem;
    height: 5rem;
}

.avatar img {
    max-width: 35rem;
    max-height: 35rem;
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.premium {
    margin-left: .3rem;
}

.header {
    text-align: left;
    display: flex;
}


@media (max-width: 800px) {
    .header{
        flex-direction: column;
        width: 100%;
    }
}

.container {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    max-width: 95%;
}

.name {
    color: var(--g-font-color);
    font-size: 24px !important;
    font-weight: bolder !important;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    margin-right: 0.5rem;
}

.nameWithOwned {
    max-width: calc(100% - 4rem); /* Adjust max-width to fit the "Owned" tag */
}

.nameWithApplied {
    max-width: calc(100% - 4rem); /* Adjust max-width to fit the "Applied" tag */
}


.icon {
    font-size: 1.4rem;
    font-weight: 450;
    margin-right: 1rem;
    height: 1rem;
}

.location {
    font-size: 1.4rem;
    font-weight: 450;
    margin-right: 1rem;
    height: 1.6rem !important;
    overflow: hidden;
}

.author {
    margin-right: 1rem;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    vertical-align: center;
}

.author:hover {
    cursor: pointer;
    text-decoration: underline;
}

.author::before {
    content: "";
    background: url("../../../../../../theme/resource/icon/user.svg");
    vertical-align: top;
    width: 2.5rem;
    margin-right: .5rem;
    height: 2.5rem;
    display: inline-block;
}

.info_bar {
    margin-left: 1.5rem;
    width: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.info_item {
    justify-content: flex-start !important;
    align-items: flex-start;
    text-align: left;
    width: 100% !important;
}

.info_item_labels {
    flex-wrap: wrap;
    gap: 4px 2px;
    flex-direction: row;
    justify-content: flex-start !important;
    text-align: left;
    width: 100% !important;
    border-radius: 8px;
    align-items: start;
}

.product_placement{
    width: 1rem !important;
    height: 1rem !important;
    margin-top: 4px;
}

.product_placement::before {
    vertical-align: center;
    background: url("../../../../../../theme/resource/icon/placement.svg");
}

.influencer{
    width: 1rem !important;
    height: 1rem !important;
    margin-top: 4px;
}

.influencer::before {
    vertical-align: center;
    background: url("../../../../../../theme/resource/icon/influencer.svg");
}

.free_placement{
    width: 1rem !important;
    height: 1rem !important;
    margin-top: 4px;
}

.free_placement::before {
    background: url("../../../../../../theme/resource/icon/deal.svg");
}

.location::before {
    background: url("../../../../../../theme/resource/icon/location.svg");
}

.icon::before {
    width: 1.5rem;
    margin-left: 0.2rem;
    height: 1.5rem;
}

.keywords {
    overflow: hidden;
    display: flex;
    justify-content: flex-start !important; /* Align items to the left */
    align-items: flex-start !important;    /* Align items to the start of the container */
    flex-wrap: wrap;
    text-align: left;
    gap: 0 8px;
    padding-top: 8px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    width: fit-content !important;
    margin-left: 0;
    overflow: hidden;
    max-height: 33px;
}




.avatar_container:hover img {
    transform: scale(1.05);
}

.tooltip {
    display: inline-flex;
    background-color: var(--g-color-light-green);
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
    font-size: 1.25rem;
    margin-right: 8px;
    margin-top: 4px;
}


@container (max-width: 300px) {
    .name {
        font-size: medium;
    }
}

@media screen and (max-width: 800px) {
    .avatar_container {
        margin-top: 2.2rem;
    }
}

@media screen and (max-width: 800px) {
    .name {
        width: 100%;
        font-size: large;
        font-weight: 800;
    }
    .info_bar{
        width: 100%;
        margin-left: 0;
    }
    .avatar_container{
        width: 100%;
        height: auto;
    }
    .avatar{
        width: 100%;
        height: 100%;
        .img{
            width: 100%;
            height: auto;
        }
    }
    .container {
        max-width: 100%;
    }
}


.description_short {
    margin-top: 12px;
    margin-left: .5rem;
    width: 100%;
    height: auto; /* Adjusted to fit content, can set specific height if needed */
    text-align: left;
    white-space: normal; /* Allows the text to wrap */
    overflow: hidden; /* Hides any overflow */
    text-overflow: ellipsis; /* This may need to be adjusted or removed */
    line-height: 1.5em; /* Example line height, adjust as needed */
    max-height: 8rem; /* Example max height, adjust as needed */
    text-overflow: ellipsis;
    font-weight: 400;
}

.get_premium_button {
    margin-top: 40px;
}

.premium_button {
    border-radius: 22px;
    padding: 1rem 3rem;
}

.info_item_name{
    vertical-align: center !important;
    color: var(--g-color-green);
    margin-right: .25rem;
}