.panel {
    padding: 3.2rem;
    width: 100%;
}

.title {
    font-size: 2.56rem;
    line-height: 3rem;
    margin-bottom: 1rem;
    letter-spacing: .025em;
}

.footer {
    text-align: center;
}

.more_button {
    border-radius: 2rem;
    padding: .1rem 1rem .1rem 1rem;
}
