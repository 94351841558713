.meta {
    background: white;
    width: 100%;
    height: 60px;
    border-radius: 0 0 8px 8px;
    //box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(233,233,233);
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, .1); */
    position: relative;
    margin-bottom: 4.8rem;
}

.sub {
    float: right;
    align-items: center;
}

.boost_button {
    float: right;
    vertical-align: center;
    margin-top: .8rem;
    margin-right: 1.5rem;
}

@media all and (max-width: 850px) {
    .meta {
        height: auto;
        text-align: center;
        margin-bottom: 1.6rem;
    }

    .sub {
        display: inline-block;
        float: none;
        text-align: center;
    }

    .boost_button {
        margin-bottom: 2rem;
        float: none;
        margin-right: 0;
    }
}
