.pagination span {
    display: inline-block;
    color: rgb(165,165,165);
    margin: 0rem .8rem;
    position: relative;
}

.pagination span:before {
    position: absolute;
    content: '•';
    display: inline-block;
    color: rgb(165,165,165);
    top: -2.5rem;
    font-size: 3rem;
}

.done:before {
    color: rgb(66, 66, 66) !important;
    font-size: 2.7rem;
}