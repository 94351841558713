.loading {
    position: relative;
    margin-top: 20px;
    text-align: center;
}

.title {
    margin-bottom: 2rem;
}

.loading .loader {
    width: 25px;
    height: 12.5px;
    border-radius: 25px;
    background: linear-gradient(315deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
    position: relative;
    display: inline-block;
    animation: mainload 1.5s ease-in-out infinite;
}

.loading .loader::before,
.loading .loader::after {
    content: '';
    width: 25px;
    height: 12.5px;
    border-radius: 25px;
    background: linear-gradient(315deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
    position: absolute;
    animation: load 1.5s ease-in-out infinite;
}

.loading .loader::before {
    top: -17.5px;
    left: 0;
    animation-delay: .4s;
}

.loading .loader::after {
    bottom: -17.5px;
    left: 0;
    animation-delay: .2s;
}

.feed_end {
    margin-top: 5rem;
}

@keyframes mainload {
    0% {
        transform: translateX(18px) translateY(10px) rotate(30deg);
    }
    50% {
        transform: translateX(-18px) translateY(-10px) rotate(30deg);
    }
    100% {
        transform: translateX(18px) translateY(10px) rotate(30deg);
    }
}

@keyframes load {
    0% {
        left: -20px;
    }
    50% {
        left: 20px;
    }
    100% {
        left: -20px;
    }
}
