.container {
    background: white;
    border-radius: var(--g-panel-radius);
    width: 100%;
    height: fit-content;
    background: linear-gradient(155deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
    padding: 4rem;
    background: var(--g-color-primary);
}

.inner_container {
    background: white;
    width: 100%;
    border-radius: .5rem;
    //display: flex;
    height: fit-content;
    //flex-direction: row; /* Horizontal anordnen */
    gap: .5rem; /* Abstand zwischen den Spalten */
}

.left {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    //margin-right: 2rem;

    margin: auto 0;
    width: 50%;
}

.left > h3 {
    width: 100%;
}

.reason {
    font-weight: 500;
    margin-bottom: 2rem;
    max-width: 40rem;
    width: calc(100% - 5.2rem);
    display: inline-block;
}

.border_none {
    background: white;
}

.right {
    height: 100%;
    width: 50%;
    padding-bottom: 2rem;
    vertical-align: middle;
    margin: auto 0;
    display: inline-block;
    text-align: center;
}

.pre_icon::before {
    content: '';
    display: block;
    background: url('../../../../../../../../theme/resource/icon/rank.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 2.24rem;
    height: 2.24rem;
    float: left;
    margin-top: 0rem;
    margin-right: .96rem;
}

.cta.pre_icon::before {
    background: url('../../../../../../../../theme/resource/icon/influencer_gradient.svg');
}


.icon {
    display: inline-block;
    margin-right: 2rem;
    width: 2.24rem;
    height: 2.24rem;
    background-size: cover;
    background: url('../../../../../../../../theme/resource/icon/influencer_gradient.svg');
    vertical-align: top;
    margin-top: .5rem;
    width: 3rem;
    height: 3rem;
    background-color: #4CAF50; /* Green */
}

.attention {
    background: url('../../../../../../../../theme/resource/icon/warning_red.svg');
}

.verification {
    background: url('../../../../../../../../theme/resource/icon/premium_badge.svg');
}

.community {
    background: url('../../../../../../../../theme/resource/icon/community_gradient.svg');
}

.network_cta {
    display: inline-block;
    min-width: 40rem;
    text-align: left;
    margin: 0;
}

/* Media Query für kleinere Bildschirme */
@media (max-width: 768px) {
    .inner_container {
        //flex-direction: column; /* Vertikal anordnen */
        padding: 1rem;
    }

    .left {
        //order: 2;
        //align-items: flex-start; /* Zentrieren im mobilen Modus */
    }


    .right {
        order: 1;
        width: 100%;
        align-items: center;
    }

}




.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 12rem;
    width: 100%;
    background: var(--g-font-color);
    background: linear-gradient(155deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
    border-radius: 1.25rem;
    transition: background-color 0.3s;
    padding: .3rem;
    text-align: left;
    margin: 0;

    .inner_container {
        height: 100%;
        width: 100%;
        border-radius: 1rem;
        background: white;
        margin: auto 0;
        padding: 4rem;
        text-align: left;

        .networks {
            text-align: center;

            .network {
                height: 3rem;
                width: 3rem;
                display: inline-block;
                text-align: left;
                margin: 0 .5rem;
            }

            .youtube {
                background: url('../../../../../../../../theme/resource/icon/youtube.svg');
            }

            .twitch {
                background: url('../../../../../../../../theme/resource/icon/twitch.svg');
            }

            .x {
                background: url('../../../../../../../../theme/resource/icon/twitter.svg');
            }

            .tiktok {
                background: url('../../../../../../../../theme/resource/icon/tiktok.svg');
            }

            .instagram {
                background: url('../../../../../../../../theme/resource/icon/instagram.svg');
            }

            .facebook {
                background: url('../../../../../../../../theme/resource/icon/facebook.svg');
            }

        }

        .container_networks {
            margin: 0;
            text-align: center;
            display: inline-block;
            max-width: 35rem;
            width: 100%;
        }

        .cta_box {
            font-size: 2rem;
            font-weight: 500;
            margin-top: .5rem;
            color: var(--g-font-color);
            width: 100%;
            display: block;
        }

        .learn_more {
            font-size: 1.5rem;
            color: var(--g-label-color);
            top: 1.2rem;
            position: relative;

            &:hover {
                color: var(--g-color-primary);
            }
        }
    }
}

.border_none {
    background: white;
}

.connect {
    width: 100%;
    display: inline-block;
    max-width: 35rem;
}

@media (max-width: 850px) {
    .left, .right {
        display: block;
        width: 100%;
    }

    .left {
        text-align: center;

        .authenticate_cta {
            display: inline-block;
            max-width: 40rem;
            text-align: left;
            margin: 0 auto;
        }

        .reason_container {
            display: inline-block;
            text-align: left;

        }
    }

    .left {
        margin-bottom: 5rem;
    }
}

@media (max-width: 500px) {
    .container {
        padding: 0rem;
        border-radius: 5px;

        .inner_container {
            padding: 1.5rem;
        }
    }
}