/* index.module.scss */

.section {
    padding: 20px;
}

.panel {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.header h3 {
    font-size: 1.2rem;
    color: #666;
}

.metrics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.number {
    color: var(--g-color-primary);
}

.metric {
    flex: 1;
    min-width: 200px;
    padding: 10px;
    margin: 10px;
    background: #f9f9f9;
    border-radius: 4px;
    text-align: center;
}

.last_signups {
    margin-top: 20px;
}

.additional_data {
    margin-top: 20px;
    padding: 10px;
    background: #f1f1f1;
    border-radius: 4px;
}
