.container {
    width: 100%;
    max-width: 35rem;
}

.icon {
    width: 6.8rem;
    height: 6.8rem;
    background: url('../../theme/resource/icon/key.svg');
}

.signup a {
    margin-left: .8rem;
}

.or {
    &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 2px;
        background: var(--g-border-color);
        position: relative;
        top: .6rem;
        opacity: .5;
    }

    .label {
        display: inline-block;
        padding: 0 .8rem;
        background: white;
        position: relative;
        top: -1.5rem;
        text-transform: uppercase;
        font-size: 1.2rem;
    }
}

.google_authentication_button {
    padding: .8rem 1.2rem;
    cursor: pointer;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    position: relative;
    transition: border-color .2s ease-in-out;

    &:hover {
        border-color: var(--g-primary-color);
    }

    .icon {
        position: absolute;
        left: 1.2rem;
        top: .8rem;
        bottom: .8rem;
        width: 2.4rem;
        height: 2.4rem;
    }

    .label {
        vertical-align: center;
    }
}

.apple_authentication_button {
    padding: .8rem 1.2rem;
    cursor: pointer;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    position: relative;
    transition: border-color .2s ease-in-out;

    &:hover {
        border-color: var(--g-primary-color);
    }

    .icon {
        position: absolute;
        left: 1.2rem;
        top: .3rem;
        bottom: .8rem;
        font-size: 2.6rem;
        color: black;
        width: 2.4rem;
        text-align: center;
    }

    .label {
        vertical-align: center;
    }
}

.line {
    width: 100%;
    height: 2px;
    background: var(--g-border-color);
    opacity: .5;
    position: relative;
    top: 0rem;
    text-align: center;
}

.icon_error {
    display: inline-block;
    width: 6.4rem;
    height: 6.4rem;
    background: url('../../theme/resource/icon/tools.svg');
}

.icon_verified {
    display: inline-block;
    width: 6.4rem;
    height: 6.4rem;
    background: url('../../theme/resource/icon/verified.svg');
}

.title {
    font-size: 2.4rem;
    margin-top: 1.6rem;
}

.description {
    margin-top: 1.6rem;
    line-height: 1.5;
    display: block;
    color: rgb(110,110,110);
    font-weight: 500;
}

.button {
    display: inline-block;
    background: var(--g-color-primary);
    color: white;
    padding: 1.2rem 2rem;
    border-radius: 8px;
    margin-top: 2.4rem;
    font-weight: 500;
}

.large {
    width: calc(100% - 3.2rem);
}

.buttonFilled {
    width: calc(100% - 3.2rem);
    display: inline-block;
    background: var(--g-color-primary);
    color: #ffffff;
    padding: 1.2rem 1.25rem;
    border-radius: 8px;
    margin-top: 2.4rem;
    font-weight: 500;
}

.criteria_container {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
}