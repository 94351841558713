.bookmark_enabled,
.bookmark_disabled {
    background-size: cover;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: relative; /* Ensure the tooltip is positioned relative to the bookmark */
}

.bookmark_enabled {
    background: url("../../resource/icon/bookmark_filled.svg");
}

.bookmark_disabled {
    background: url("../../resource/icon/bookmark_outline.svg");
}

.bookmark_tooltip {
    z-index: 10000 !important; /* Ensure the tooltip is on top */
    font-size: 1.6rem;
    margin-top: 0.8rem;
    position: absolute; /* Keep the tooltip positioned absolutely */
    top: 100%; /* Position it below the bookmark */
    left: 50%; /* Center it horizontally */
    transform: translateX(-50%); /* Center it horizontally */
    white-space: nowrap; /* Prevent text from wrapping */
    background-color: black; /* Tooltip background color */
    color: white; /* Tooltip text color */
    padding: 0.5rem; /* Tooltip padding */
    border-radius: 0.25rem; /* Tooltip border radius */
    visibility: hidden; /* Initially hide the tooltip */
    opacity: 0; /* Initially hide the tooltip */
}

/* Show the tooltip when hovering over the bookmark */
.tooltip:hover .bookmark_tooltip {
    visibility: visible;
    opacity: 1;
}
