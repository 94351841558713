.support_icon {
    height: 1.6rem;
    width: 10rem;
    height: 10rem;
    background: url('../../theme/resource/icon/account.svg');
    margin-bottom: 2rem;
}

.panel {
    text-align: center;
    width: 40%;
    padding: 4rem;
}
.success {
    text-align: center;
}

.success_icon {
    background: url('../../theme/resource/icon/gradient_checkmark.svg');
    width: 5rem;
    height: 5rem;
}

@media all and (max-width: 800px) {
    .panel {
        width: 100%;
    }
}
