.card {
    text-align: center;
    border-radius: 36px;
    width: 212px;
    padding: 0;
    margin: 0;
    height: 330px !important;
    cursor: pointer;
    transition: 0.25s ease all;

    @media all and (max-width: 1050px) {
        align-self: center;
        justify-self: center;
        width: 80%;
    }
}

@media (hover: hover) {
    .card:hover {
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
    }

    .card:hover .name {
        margin-right: .3rem;
        color: var(--g-color-primary);
    }

    .card:hover .avatar {
        padding: 0;
    }
}

.banner {
    display: inline-block;
    width: 100%;
    height: 100px;
    border-radius: 36px 36px 0 0;
    overflow: hidden;
    background: gray;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    transition: 0.25s ease all;
}

.banner img {
    position: absolute;
    height: 100%;
    left: -100%;
    right: -100%;
    margin: auto;
}

.avatar {
    display: inline-block;
    width: 110px;
    height: 110px;
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    border-radius: 100%;
    overflow: hidden;
    padding: 5px;
    transition: 0.25s ease-in-out all;
}

.avatar_background {
    display: inline-block;
    width: 110px;
    height: 110px;
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 1.5rem;
    border-radius: 100%;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.avatar > * {
    width: 100%;
    height: 100%;
    background: gray;
    border-radius: 100%;
}

.name {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    display: inline-block;
    width: 92.5%;
    margin: 0 8px;
    margin-bottom: 0px;
    margin-top: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.follower {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    color: var(--g-color-orange);
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 15px;
    min-width: 6.5rem;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding: .125rem 1rem;
    width: fit-content;
}

.type_tag {
    position: absolute;
    border-radius: 100%;
    z-index: 1;
    top: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
    background: linear-gradient(155deg, rgb(0, 177, 255) 0%, rgb(0, 255, 121) 100%);
    transform: translate(30%, -30%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.type_tag_brand {
    width: 2.5rem;
    height: 2.5rem;
    background: url("../../resource/icon/brand_white.svg") no-repeat center center;
}

.type_tag_influencer {
    width: 2.2rem;
    height: 2.2rem;
    background: url("../../resource/icon/user_white.svg") no-repeat center center;
}

.follower::before {
    display: inline-block;
    content: "";
    margin-top: 0.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.25rem;
    opacity: 0.6;
    background: url("../../resource/icon/rank_orange.svg");
}

.keywords {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
    text-align: left;
    gap: 8px 12px;
    padding: 8px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    width: 100%;
    padding-top: 12px;
    max-height: 76px;
}

.reject_button {
    display: inline-block;
    background: rgb(245, 22, 22);
    border-radius: 5px;
    color: white;
    padding: 0.8rem 2.4rem;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    left: 0;
    right: 0;
    width: 66%;
    margin-bottom: 1.6rem;
}

.profile_badge {
    position: absolute;
    background: white;
    width: 3.4rem;
    height: 3.4rem;
    top: 9.3rem;
    right: 2.4rem;
    z-index: 10;
    border-radius: 100%;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 0.4rem;
    text-align: center;
}

.profile_badge span {
    display: inline-block;
    width: 2.64rem;
    height: 2.64rem;
    opacity: 1;
    background-repeat: no-repeat;
}

.platform_badge div {
    display: inline-block;
    width: 20px;
    height: 20px;
    opacity: 1;
    background-repeat: no-repeat;
}

.platform_badge {
    z-index: 10;
    border-radius: 100%;
    text-align: center;
}

.platform_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    gap: 0.25rem;
    color: white;
    position: relative;
    width: auto;
    height: 20px;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 8px;
}

.collaboration_container {
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    gap: 0.25rem;
    color: white;
    position: relative;
    width: auto;
    margin-left: 3rem;
    margin-right: 3rem;
}

.product_placement {
    border-radius: 15px;
    width: 20px;
    height: 20px;
    background-color: rgb(231, 249, 230);
    margin: 0 .5rem;
}

.product_placement::before {
    margin-top: 4px;
    margin-left: 4px;
    height: 12px;
    width: 12px;
    background: url("../../resource/icon/placement.svg");
}

.influencer {
    border-radius: 15px;
    width: 20px;
    height: 20px;
    background-color: rgb(231, 249, 230);
    margin: 0 .5rem;
}

.influencer::before {
    margin-top: 4px;
    margin-left: 4px;
    height: 12px;
    width: 12px;
    background: url("../../resource/icon/influencer.svg");
}

.free_placement {
    border-radius: 15px;
    width: 20px;
    height: 20px;
    background-color: rgb(231, 249, 230);
    margin: 0 .5rem;
}

.free_placement::before {
    margin-top: 4px;
    margin-left: 4px;
    height: 12px;
    width: 12px;
    background: url("../../resource/icon/deal.svg");
}

.info_item_labels {
    height: 20px;
    justify-content: flex-start !important;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 0.75rem;
}

.youtube {
    background: url("../../resource/icon/youtube.svg");
}

.twitter {
    background: url("../../resource/icon/twitter.svg");
}

.tiktok {
    background: url("../../resource/icon/tiktok.svg");
}

.twitch {
    background: url("../../resource/icon/twitch.svg");
}

.instagram {
    background: url("../../resource/icon/instagram.svg");
}

.facebook::before {
    background-image: url("../../resource/icon/facebook.svg");
}

.divider {
    width: 100%;
    height: 20rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 50px 50px 0 inset;
}

@media all and (max-width: 500px) {
    .card {
        max-width: 100%;
    }

    .follower {
        display: block;
    }
}