.section {
    display: inline-block;
    max-width: 100%;
    text-align: right;
    padding: 0;
    padding-top: 3rem;
    padding-right: 4rem;

    @media all and (max-width: 750px) {
        margin-top: 0;
        text-align: center;
    }
}

.top_decoration {
    position: absolute;
    background: url("../../../../theme/resource/image/landing/top_decoration.svg");
    background-repeat: no-repeat;
    left: -3.2rem;
    top: -50%;
    width: 100rem;
    height: 100rem;
    z-index: 0;

    @media (max-width: 800px) {
        overflow-x: hidden;
        width: 90%;
        top: -40%;
    }

}

.navbar_option {
    position: relative;
    font: normal normal medium 22px/33px Poppins;
    color: #333539;
    text-transform: uppercase;
    margin: 0 1.25rem;
    height: 2rem;
    transition: opacity 0.3s; /* Hover effect transition */
    letter-spacing: 2px;
    font-weight: bold;
    white-space: nowrap;
    cursor: pointer;

    @media (max-width: 470px) {
        font-size: 1.5rem;
        margin: auto .5rem;
        letter-spacing: normal;
    }
}




