.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(315deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
    mask: radial-gradient(farthest-side, transparent 85%, #000 86%);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}