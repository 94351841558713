.flex {
    display: flex;
    justify-content: space-between;
}

.inline {
    display: inline-block;
    margin-bottom: 1.6rem;
}

.cell {
    width: 16rem;
    display: inline-block;
}

.pseudoPanel {
    display: inline-block;
    max-width: 22.4rem;
}

.container {
    display: inline-block;
}

.item {
    display: inline-block;
    vertical-align: top;
}