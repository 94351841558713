.menu {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: white;
    padding-bottom: 1rem;
    //box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    height: 100%;
    padding-top: 1rem;
}
