.location_icon {
    background: url('../../../../theme/resource/icon/location.svg') no-repeat center;
    height: 2.5rem;
    width: 2.5rem;
    scale: 1.3;
    display: inline-block;
    vertical-align: middle;
}

.location_container {
    align-items: center;
    justify-content: flex-start;
}

.edit_icon:hover {
    cursor: pointer;
    opacity: 0.75;
}

.edit_icon::before {
    content: '';
    display: inline-block;
    background: url('../../../../theme/resource/icon/edit.svg') no-repeat center;
    height: 2rem;
    width: 2rem;
    vertical-align: middle;
    margin-right: 0.95rem;
}

.location_input,
.location_label {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;
    text-align: left;
}

@media (max-width: 800px) {
    .location_input,
    .location_label {
        width: calc(95% - 3rem); /* Subtracting icon width to maintain layout */
    }
}

