.inline_toast {
    color: white;
    padding: .8rem 1.6rem;
    border-radius: var(--g-input-radius);
}

.type_info { background: var(--g-color-info); }
.type_success { background: var(--g-color-success); }
.type_warning { background: var(--g-color-warning); }
.type_failure { background: var(--g-color-failure); }

.pre_icon::before {
    margin-top: .1rem;
    margin-right: .8rem;
    width: 2rem;
    height: 2rem;
}

.content {
    color: white;
}

.icon_info::before { background: url('../../resource/icon/info.svg'); }
.icon_success::before { background: url('../../resource/icon/success.svg'); }
.icon_warning::before { background: url('../../resource/icon/warning.svg'); }
.icon_failure::before { background: url('../../resource/icon/failure.svg'); }