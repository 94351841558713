
.l-offset-0 { margin-left: 0 }
.l-push-0 { left: auto; right: auto }
.l-pull-0 { right: auto; left: auto }

.l-1 { width: 8.33333% }
.l-offset-1 { margin-left: 8.33333% }
.l-push-1 { left: 8.33333%; right: auto }
.l-pull-1 { right: 8.33333%; left: auto }

.l-2 { width: 16.66667% }
.l-offset-2 { margin-left: 16.66667% }
.l-push-2 { left: 16.66667%; right: auto }
.l-pull-2 { right: 16.66667%; left: auto }

.l-3 { width: 25% }
.l-offset-3 { margin-left: 25% }
.l-push-3 { left: 25%; right: auto }
.l-pull-3 { right: 25%; left: auto }

.l-4 { width: 33.33333% }
.l-offset-4 { margin-left: 33.33333% }
.l-push-4 { left: 33.33333%; right: auto }
.l-pull-4 { right: 33.33333%; left: auto }

.l-5 { width: 41.66667% }
.l-offset-5 { margin-left: 41.66667% }
.l-push-5 { left: 41.66667%; right: auto }
.l-pull-5 { right: 41.66667%; left: auto }

.l-6 { width: 50% }
.l-offset-6 { margin-left: 50% }
.l-push-6 { left: 50%; right: auto }
.l-pull-6 { right: 50%; left: auto }

.l-7 { width: 58.33333% }
.l-offset-7 { margin-left: 58.33333% }
.l-push-7 { left: 58.33333%; right: auto }
.l-pull-7 { right: 58.33333%; left: auto }

.l-8 { width: 66.66667% }
.l-offset-8 { margin-left: 66.66667% }
.l-push-8 { left: 66.66667%; right: auto }
.l-pull-8 { right: 66.66667%; left: auto }

.l-9 { width: 75% }
.l-offset-9 { margin-left: 75% }
.l-push-9 { left: 75%; right: auto }
.l-pull-9 { right: 75%; left: auto }

.l-10 { width: 83.33333% }
.l-offset-10 { margin-left: 83.33333% }
.l-push-10 { left: 83.33333%; right: auto }
.l-pull-10 { right: 83.33333%; left: auto }

.l-11 { width: 91.66667% }
.l-offset-11 { margin-left: 91.66667% }
.l-push-11 { left: 91.66667%; right: auto }
.l-pull-11 { right: 91.66667%; left: auto }

.l-12 { width: 100% }
.l-offset-12 { margin-left: 100% }
.l-push-12 { left: 100%; right: auto }
.l-pull-12 { right: 100%; left: auto }

@media only screen and (max-width: $g-medium) {

  .m-offset-0 { margin-left: 0 }
  .m-push-0 { left: auto; right: auto }
  .m-pull-0 { right: auto; left: auto }

  .m-1 { width: 8.33333% }
  .m-offset-1 { margin-left: 8.33333% }
  .m-push-1 { left: 8.33333%; right: auto }
  .m-pull-1 { right: 8.33333%; left: auto }

  .m-2 { width: 16.66667% }
  .m-offset-2 { margin-left: 16.66667% }
  .m-push-2 { left: 16.66667%; right: auto }
  .m-pull-2 { right: 16.66667%; left: auto }

  .m-3 { width: 25% }
  .m-offset-3 { margin-left: 25%   }
  .m-push-3 { left: 25%; right: auto }
  .m-pull-3 { right: 25%; left: auto }

  .m-4 { width: 33.33333% }
  .m-offset-4 { margin-left: 33.33333% }
  .m-push-4 { left: 33.33333%; right: auto }
  .m-pull-4 { right: 33.33333%; left: auto }

  .m-5 { width: 41.66667% }
  .m-offset-5 { margin-left: 41.66667% }
  .m-push-5 { left: 41.66667%; right: auto }
  .m-pull-5 { right: 41.66667%; left: auto }

  .m-6 { width: 50% }
  .m-offset-6 { margin-left: 50% }
  .m-push-6 { left: 50%; right: auto }
  .m-pull-6 { right: 50%; left: auto }

  .m-7 { width: 58.33333% }
  .m-offset-7 { margin-left: 58.33333% }
  .m-push-7 { left: 58.33333%; right: auto }
  .m-pull-7 { right: 58.33333%; left: auto }

  .m-8 { width: 66.66667% }
  .m-offset-8 { margin-left: 66.66667% }
  .m-push-8 { left: 66.66667%; right: auto }
  .m-pull-8 { right: 66.66667%; left: auto }

  .m-9 { width: 75% }
  .m-offset-9 { margin-left: 75% }
  .m-push-9 { left: 75%; right: auto }
  .m-pull-9 { right: 75%; left: auto }

  .m-10 { width: 83.33333% }
  .m-offset-10 { margin-left: 83.33333% }
  .m-push-10 { left: 83.33333%; right: auto }
  .m-pull-10 { right: 83.33333%; left: auto }

  .m-11 { width: 91.66667% }
  .m-offset-11 { margin-left: 91.66667% }
  .m-push-11 { left: 91.66667%; right: auto }
  .m-pull-11 { right: 91.66667%; left: auto }

  .m-12 { width: 100% }
  .m-offset-12 { margin-left: 100% }
  .m-push-12 { left: 100%; right: auto }
  .m-pull-12 { right: 100%; left: auto }

}

@media only screen and (max-width: $g-small) {

  .s-offset-0 { margin-left: 0 }
  .s-push-0 { left: auto; right: auto }
  .s-pull-0 { right: auto; left: auto }

  .s-1 { width: 8.33333% }
  .s-offset-1 { margin-left: 8.33333% }
  .s-push-1 { left: 8.33333%; right: auto }
  .s-pull-1 { right: 8.33333%; left: auto }

  .s-2 { width: 16.66667% }
  .s-offset-2 { margin-left: 16.66667% }
  .s-push-2 { left: 16.66667%; right: auto }
  .s-pull-2 { right: 16.66667%; left: auto }

  .s-3 { width: 25% }
  .s-offset-3 { margin-left: 25% }
  .s-push-3 { left: 25%; right: auto }
  .s-pull-3 { right: 25%; left: auto }

  .s-4 { width: 33.33333% }
  .s-offset-4 { margin-left: 33.33333% }
  .s-push-4 { left: 33.33333%; right: auto }
  .s-pull-4 { right: 33.33333%; left: auto }

  .s-5 { width: 41.66667% }
  .s-offset-5 { margin-left: 41.66667% }
  .s-push-5 { left: 41.66667%; right: auto }
  .s-pull-5 { right: 41.66667%; left: auto }

  .s-6 { width: 50% }
  .s-offset-6 { margin-left: 50% }
  .s-push-6 { left: 50%; right: auto }
  .s-pull-6 { right: 50%; left: auto }

  .s-7 { width: 58.33333% }
  .s-offset-7 { margin-left: 58.33333% }
  .s-push-7 { left: 58.33333%; right: auto }
  .s-pull-7 { right: 58.33333%; left: auto }

  .s-8 { width: 66.66667% }
  .s-offset-8 { margin-left: 66.66667% }
  .s-push-8 { left: 66.66667%; right: auto }
  .s-pull-8 { right: 66.66667%; left: auto }

  .s-9 { width: 75% }
  .s-offset-9 { margin-left: 75% }
  .s-push-9 { left: 75%; right: auto }
  .s-pull-9 { right: 75%; left: auto }

  .s-10 { width: 83.33333% }
  .s-offset-10 { margin-left: 83.33333% }
  .s-push-10 { left: 83.33333%; right: auto }
  .s-pull-10 { right: 83.33333%; left: auto }

  .s-11 { width: 91.66667% }
  .s-offset-11 { margin-left: 91.66667% }
  .s-push-11 { left: 91.66667%; right: auto }
  .s-pull-11 { right: 91.66667%; left: auto }

  .s-12 { width: 100% }
  .s-offset-12 { margin-left: 100% }
  .s-push-12 { left: 100%; right: auto }
  .s-pull-12 { right: 100%; left: auto }

}