.panel {
    padding: 2rem;
    width: 100%;
    cursor: pointer;
    :hover{
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.2));
    }
}

.title {
    font-size: 2.56rem;
    line-height: 3rem;
    margin-bottom: 1rem;
    letter-spacing: 0.025em;
}

.offerText {
    font-size: 2.56rem;
    line-height: 3rem;
    letter-spacing: 0.025em;
    text-align: center;
}
.offerTextImage{
    font-size: 2.56rem;
    line-height: 3rem;
    letter-spacing: 0.025em;
    text-align: center;
    &::after {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        position: absolute;
        margin-top: .5rem;
        margin-left: 1rem;
        background: url('../../../../theme/resource/icon/go.svg');

    }
}



.campaignImage{
    width: 300px;
    height: auto;
    align-content: center;
    margin: 1rem;
}

.footer {
    text-align: center;
}

.more_button {
    border-radius: 2rem;
    padding: 0.1rem 1rem 0.1rem 1rem;
}

.box {
    margin-top: 1rem;
}
