.dropdown {
    background-color: white;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    box-shadow: var(--g-regular-shadow);
    position: absolute;
    left: 0;
    overflow: hidden;
    text-align: left;

    width: 100%;
    opacity: 1;

    z-index: 1000000;
    max-height: 30rem;

}

.icon {
    background: url("../../../../../theme/resource/icon/location.svg");
    width: 2rem;
    height: 2rem;
    margin-top: .2rem;
    margin-right: 1rem;
    float: left;
}

.hint {
    cursor: pointer;
    padding: 1rem;
    height: 5rem;
}

.hint_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
    max-width: 92%;
    float: right;
}


.selected_hint {
    cursor: pointer;
    padding-left: 3rem;
    background: var(--g-border-color);
}

.hint:hover {
    background: var(--g-border-color);
}

.hint::before .selected_hint::before {
    content: '';
    position: absolute;
    left: .5rem;

    width: 1.28rem;
    height: 1.28rem;
    margin-top: .5rem;
    
    background-image: url('../../../../resource/icon/go.svg');
    opacity: .8;

}

@media (max-width: 800px) {
    .hint_text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 85%;
        float: right;
    }
}