.input_box {
    padding: 0 1rem .5rem .5rem;
    border-radius: var(--g-input-radius);

    font-size: 1.6rem;

    min-height: 10rem;

    background: white;
    border: 2px solid var(--g-border-color);
    color: black;
    -webkit-appearance: none;

    transition: border-color 250ms ease-in-out;
}

.keyword_input {
    margin-top: .5rem;
}

.input_box:hover {
    border-color: var(--g-color-primary-hover);
    cursor: text;
}

.input_box:focus {
    border-color: var(--g-color-primary);
}

.info {
    margin-top: .4rem;
    margin-left: .4rem;
    font-size: smaller;
    color: var(--g-color-light-gray);
}

.label {
    vertical-align: top;
    margin-top: .5rem;
}

input {
    border: none;
    resize: none;
    width: auto;
}

.errorMessage {
    display: inline-block;
    color: var(--g-color-failure);
    font-weight: 400;
    font-size: 1.52rem;
    margin-top: .4rem;
    padding-left: .4rem;
    text-align: left;
}

@media only screen and (max-width: 1068px) {

    .input_box {
        margin-top: .2rem;
        margin-left: 0;
    }

    .info {
        margin-left: 0;
    }

    .label {
        margin-top: 0;
    }

}