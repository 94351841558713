.checkbox {
    display: block;
    position: relative;
    padding-left: 2.88rem;
    cursor: pointer;
    text-align: left;
}

/* Hide the browser's default checkbox */
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: .192rem;
    left: 0;
    height: 1.8rem;
    width: 1.8rem;
    background-color: var(--g-border-color);
    transition: background-color .2s ease-in-out;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
    background-color: #aaa;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
    background-color: rgb(0, 83, 255);
    transition: background-color .2s ease-in-out;
}

.checkbox input:checked:hover ~ .checkmark {
    background-color: rgb(0, 0, 0);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
    left: .55rem;
    top: .1rem;
    width: .36rem;
    height: 1.0rem;
    border: solid white;
    border-width: 0 .36rem .36rem 0;
    transform: rotate(45deg);
}

.label {
    font-weight: normal;
}

.bold {
    font-weight: bold;
}