.label {
    position: relative;
    margin-left: 4rem;
    color: var(--g-font-color);
    font-weight: normal;
    font-size: 1.8rem;
    margin-top: .8rem;
}

.setting {
    position: relative;
    color: var(--g-font-color);
    font-weight: normal;
    font-size: 1.8rem;
    margin-top: .8rem;
}

.label::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -4rem;
    width: 2.4rem;
    height: 2.4rem;
    background: url('../../../../../theme/resource/icon/link.svg')
}

.freeProductPlacement::before { background: url('../../../../../theme/resource/icon/deal.svg') }
.productPlacement::before { background: url('../../../../../theme/resource/icon/placement.svg') }
.influencerCollaboration::before { background: url('../../../../../theme/resource/icon/influencer.svg') }