.errorMessage {
    display: inline-block;
    color: var(--g-color-failure);
    font-weight: 400;
    font-size: 1.52rem;
    margin-top: .4rem;
    padding-left: .4rem;
    text-align: left;
}

.infoMessage {
    display: inline-block;
    color: var(--g-color-primary);
    font-weight: 400;
    font-size: 1.52rem;
    margin-top: .4rem;
    margin-bottom: -.72rem;
    padding-left: .4rem;
    text-align: left;
}

.infoIcon {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: 1.4rem;
    right: 1.2rem;
    //background: url('../../resource/icon/questionmark.svg');
    opacity: .5;
    transition: opacity .2s ease-in-out;
}