.container {
    position: fixed;
    bottom: 2.4rem;
    left: 0;
    right: 0;
    z-index: 1000;
}

.panel {
    width: 100%;
    max-width: 40rem + 13rem + 4rem + 1rem;
    padding: 1.2rem 2rem;
    background: var(--g-transparent-black);
    border-radius: var(--g-panel-radius);
    animation: fadeIn 200ms ease-in;
}

.description {
    display: inline-block;
    max-width: 40rem;
    width: 100%;
    max-width: calc(100% - 1rem - 13rem);
    text-align: left;
    color: white;
    line-height: 1.8rem;
    line-height: 2.2rem;
    font-weight: normal;
    margin-right: 1rem;

    a {
        font-weight: normal;
        color: white;
        text-decoration: underline;
    }
}

.accept_button_container {
    display: inline-block;
    width: calc(13rem);
    vertical-align: top;
    text-align: right;
}

.accept_button {
    width: 13rem;
    padding: .8rem 1.8rem;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(.2rem);
    }
    100% { 
        opacity: 1;
        transform: translateY(0);
    }
}

@media all and (max-width: 550px) {
    .panel {
        text-align: right;
    }

    .description {
        display: block;
        max-width: 100%;
        line-height: 2.5rem;
        margin-bottom: .75rem;
        margin-right: 0;
    }

    .accept_button_container {
        margin-top: 1rem;
    }
}