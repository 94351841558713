.tag_view {
    border-radius: 1rem;
    padding: .25rem .5rem;
    //margin-right: .6rem;
    color: var(--g-color-profile-gray);
    background: var(--g-color-profile-dark-gray);
    font-size: 1.25rem;
}

.tag_view::before {
    content: '#';
}

.tag_view:hover {
    background: var(--g-color-primary);
    color: white;
}

.keyword_input {
    padding: 0;
    color: var(--g-color-profile-dark-gray) !important;
}
.keyword_input:hover {
    color: var(--g-color-gray);
}