.overlay {
    display: none;
}

@media all and (max-width: 319px) {
    .overlay {
        display: block;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 100000;
        background: white;
        color: rgb(111, 111, 111);
        text-align: center;
    }
    
    .overlay span {
        position: absolute;
        top: 25%;
        left: 0;
        right: 0;
    }

    .icon {
        display: inline-block;
        width: 6.4rem;
        height: 6.4rem;
        background: url('../../resource/icon/tools.svg');
    }

    .description {
        display: inline-block;
        background-color: tomato;
        position: absolute;
        margin-top: 8rem;
        color: white;
    }
}