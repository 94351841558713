.badge {
    font-size: 1.3rem;
    color: white;
    border-radius: 15px;
    padding: .4rem 1rem;
    margin-left: .5rem;
    margin-bottom: .5rem;
}

.brand_communications {
    background: rgb(224, 62, 107);
}

.analytics {
    background: rgb(62, 70, 224);
}

.production {
    background: rgb(93, 194, 35);
}

.moderation {
    background: rgb(234, 186, 53);
}