.panel {
    padding: 3.2rem;
    width: 100%;
}

.title {
    font-size: 2.56rem;
    line-height: 3rem;
    margin-bottom: 1rem;
    letter-spacing: .04em;
}