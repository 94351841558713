.container {
    text-align: left;
}

.input {
    cursor: pointer;
    border-radius: var(--g-input-radius);
    border: 2px solid var(--g-border-color);
    color: black;
    font-size: 1.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    position: relative;
    transition: border-color 0.2s ease-in-out;
    text-align: left;
    border-radius: 2rem;
}

.input::after {
    content: "";
    position: absolute;
    right: 1rem; /* Adjust as necessary */
    top: 50%;
    transform: translateY(-50%);
    background: url("../../../../theme/resource/icon/chevron.svg") no-repeat;
    width: 1.6rem; /* Adjust to your image size */
    height: 1.6rem; /* Adjust to your image size */
    transition: transform 0.2s ease-in-out;
}

.input.open::after {
    transform: translateY(-50%) rotate(90deg); /* Rotate when open */
}

.input:hover {
    border-color: var(--g-color-primary);
}

.input:active {
    background-color: var(--g-border-color);
}

.dropdown {
    background-color: white;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    box-shadow: var(--g-regular-shadow);
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(100% + 0.8rem);
    overflow: hidden;

    transition: height 350ms, opacity 350ms, border-radius 350ms;
    opacity: 1;

    z-index: 1000000;
}

.dropdown.closed {
    opacity: 0;
}

.selected {
    padding: 0.1rem 1.2rem;
    @media (max-width: 800px) {
        padding: 1rem 1.2rem 1rem 1rem;
    }
}

.disabled {
    background: var(--g-border-color);
    border-color: transparent;

    cursor: not-allowed;
}

.label {
    display: inline-block;
    color: var(--g-light-gray);
    margin-bottom: 0.32rem;
    padding-left: 0.4rem;
    transition: color 0.2s ease-in-out;
}

.container:hover .label {
    color: var(--g-font-color);
}

.container:focus-within .label {
    color: var(--g-color-primary);
}
