.panel {
    display: inline-block;
    position: relative;
    color: var(--g-font-color);
    background: white;
    //box-shadow: var(--g-regular-shadow);
    border: 2px solid rgb(220, 220, 220);
    border: 1px solid rgb(233,233,233);
    overflow-wrap: break-word;
    text-align: left;
}

.defaultMargin {
    margin-bottom: 1.5em;
}

.defaultPadding {
    padding: 2rem;
}

.defaultBorderradius {
    border-radius: var(--g-panel-radius);
}