.chat_history_container {
    width: 100%;
    height: calc(100% - 11.4rem - .5rem - 1rem);
}

.chat {
    padding: 0 2rem;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
}

.chat_smaller {
    height: 87%;

}

.message_loading {
    position: relative;
    height: calc(70vh);
}