.avatar {
    width: 6rem;
    height: 6rem;
    background: white;
    position: absolute;
    top: 0;
    left: 1.5rem;
    bottom: 0;
    margin: auto 0;
    cursor: pointer;
    border-radius: 50%;
}

.avatar img {
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 3px solid white;
}

.unread_messages {
    position: absolute;
    right: -.7rem;
    top: -.2rem;
    color: white;
    border-radius: 50%;
    box-shadow: var(--g-regular-shadow);
    background: red;
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1.7rem;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
}