.container {
  cursor: pointer;
  border-radius: 5rem;
  display: flex;
  height: 4rem;
  width: 4rem;
  background: rgba(25,222, 149, .3);
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease, background 0.3s ease; /* Add transition properties */
}

.container_expanded {
  background: rgba(25,222, 149, .3);
  width: 8rem;
  height: 4rem;
  display: flex;
  cursor: default;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease, background 0.3s ease; /* Ensure transition properties match */
}

.icon {
  width: 2.6rem;
  height: 2.6rem;
  background: url("../../resource/icon/rocket.svg");
}

.container:active {
  border-color: transparent;
  filter: brightness(150%);
}

.container_expanded:active {
  border-color: transparent;
  filter: none;
}

.count {
  position: absolute;
}

.timer {
  margin-right: 1rem;
  display: inline-block; /* Ensure the timer is treated as a block-level element for flexbox */
}
