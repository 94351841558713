//.section {
//    max-width: 100%;
//    max-width: 100rem;
//    padding: 0;
//    padding-top: 1rem;
//    z-index: 5;
//}

.selector_spacer {
    display: block;
    width: 100%;
    height: 1rem;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2rem;
    background: rgb(35, 35, 35);
    max-width: 100%;
    max-width: 100rem;
    margin: 0rem auto;
    border-radius: 20px;
    z-index: 5;

    .info {
        flex: 1;
        margin-top: 1rem;
        margin: auto 0;
        margin-left: 2rem;
        color: white;
    }

    .selector_container {
        padding: 0 2rem;

        .selector {
            min-width: 18rem;
            margin-right: 2rem;
        }

        .confirm {
            padding-left: 2rem;
        }
    }
}

@media (max-width: 1020px) {
    .selector_spacer {
        display: none;
    }

    .section {
        padding-top: 0;
        margin: 0;
        width: 100%;
    }

    .container {
        margin: 0;
        border-radius: 0;
        max-width: 100%;
    }
}

@media (max-width: 780px) {
    .container {
        padding: 1rem 3rem;
        text-align: center;

        .info {
            text-align: left;
            margin-right: 1rem;
            margin-left: 0;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

        .selector_container {
            padding: 0;
            text-align: left;
            margin-bottom: 1rem;
            margin: auto 0;
        }
    }
}


@media (max-width: 700px) {
    .container {
        .selector_container {
            flex: 100%; /* Take full width */

            .selector {
                min-width: 14rem;
                margin-bottom: 1rem;
            }
        }
    }

    .info {
        flex: 100%; /* Take full width */
        min-width: 100%; /* Ensure full width */
    }
}

