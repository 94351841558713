.flex {
    display: flex;
    justify-content: space-between;
}

.inline {
    display: inline-block;
    margin-bottom: 1.6rem;
}

.component {
    border-radius: 15px;
    padding: 1rem 2rem;
    margin-right: 1rem;
}

.componentList {
}

.search {
    width: 100%;
    margin-bottom: 5rem;
    font-size: 2rem;
    padding: 2rem 2rem;
    border-radius: 15px;
    box-shadow: var(--g-regular-shadow);
}