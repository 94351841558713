.blogDetail {
    padding: 3rem 5rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 800px;

    h1 {
        font-size: 2.5rem;
        color: black;
        margin-bottom: 1rem;
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 5px;
        margin: 1rem 0;
    }

    h3 {
        font-size: 1.5rem;
        font-weight: 400;
        color: #333;
    }

    p {
        font-size: 1.5rem;
        line-height: 1.6;
        color: #666;
        margin-bottom: 1rem;
    }

    .status {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .createdAt {
        font-size: 2rem;
        color: #999;
        font-weight: bold;
    }

    // Media query for responsive padding
    @media (max-width: 800px) {
        padding: 1rem 2.5rem;
    }
}
