.badge {
    font-size: 1.3rem;
    color: white;
    border-radius: 15px;
    padding: .4rem 1rem;
    margin-left: .5rem;
    margin-bottom: .5rem;
}

.service {
    background: rgb(224, 62, 107);
}

.user_interface {
    background: rgb(62, 70, 224);
}