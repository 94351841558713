.avatar {
    display: inline-block;
    width: 16rem;
    height: 16rem;
    background: white;
    position: absolute;
    top: -125%;
    left: 2rem;
    border-radius: 100%;
    //box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .1);
    border: 2px solid rgb(220,220,220);
    border: 1px solid rgb(233,233,233);
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, .1); */
    overflow: hidden;
    padding: .5rem;
}

.avatar_image {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.expanded_overlay {
    border-radius: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
}

@media all and (max-width: 850px) {
    .avatar {
        width: 12rem;
        height: 12rem;
        top: -10.4rem;
        left: 0;
        right: 0;
        padding: .4rem;
    }
}