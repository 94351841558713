.content_loading {
    width: 100%;
    height: 80%;
    position: relative;
}

.navigator {
    display: inline-block;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: top;
    margin-top: .25rem;
    margin-right: 1rem;
    height: 2.9rem;
    width: 2.9rem;
    box-shadow: var(--g-regular-shadow);
    cursor: pointer;
    position: relative
}

.navigator:active {
  background-color: var(--g-border-color);
}

.navigator_archived {
    position: absolute;

    top: .6rem;
    left: 0;
    right: 0;
    
    height: 1.7rem;
    width: 1.7rem;
    background-image: url('../../../../theme/resource/icon/archive.svg');
}

.navigator_active {
    position: absolute;

    top: .55rem;
    left: 0;
    right: 0;
    
    height: 1.8rem;
    width: 1.8rem;
    background-image: url('../../../../theme/resource/icon/mail.svg');
}