.total {
    position: relative;
    margin-top: 1.6rem;
    font-size: 1.92rem;
    margin-left: 4rem;
}

.total::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -4rem;
    width: 2.4rem;
    height: 2.4rem;
    background: url("../../../../../../theme/resource/icon/statistics.svg");
}

.connectSelector {
    position: relative;
    width: 100%;
}

.networkDropdown {
    float: left;
    width: calc(100% - 11.2rem);
}

.connectButton {
    position: absolute;
    right: 0;
    width: 9.6rem;
}

.twitch::before {
    width: 2rem;
    height: 2rem;
    background: url("../../../../../../theme/resource/icon/twitch.svg");
}
.tiktok::before {
    background: url("../../../../../../theme/resource/icon/tiktok.svg");
}
.youtube::before {
    background: url("../../../../../../theme/resource/icon/youtube.svg");
}
.twitter::before {
    background: url("../../../../../../theme/resource/icon/twitter.svg");
}
.snapchat::before {
    background: url("../../../../../../theme/resource/icon/snapchat.svg");
}
.instagram::before {
    background: url("../../../../../../theme/resource/icon/instagram.svg");
}
.facebook::before {
    background: url("../../../../../../theme/resource/icon/facebook.svg");
}
