.container {
  border-radius: 15px;
  margin-top: 1rem;
  box-shadow: var(--g-regular-shadow);
  height: 40rem;
  max-width: 69rem;
  width: 100%;
  //overflow: hidden;
  position: relative;
  background: white;
}

.title_container {
  max-width: 69rem;
  width: 100%;
}

.menu_container {
  width: 22rem;
  height: 100%;
  vertical-align: top;
  background-color: white;
}

.details_container {
  width: calc(100% - 22rem);
  height: 100%;
  vertical-align: top;
  text-align: left;
  padding: 1rem;
  box-shadow: -5px 0px 15px 0px rgba(0, 0, 0, 0.07);
  background-color: white;

}

.backButton {
  width: 5rem;
  height: 2.5rem;
  display: none;
  border-radius: 12px;
  background-color: var(--g-color-dark-white);
  z-index: 1000;
  position: absolute;
  top: .5rem;
  right: .25rem;
}



.backButton::before {
  content: '';
  background: url("../../theme/resource/icon/chevron.svg") no-repeat center;
  background-size: 80% 80%;
  display: block;
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
}

.backButton:active {
  opacity: 0.7;
}

.backButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0,123,255,.5);
}


@media only screen and (max-width: 735px) {
  .container {
    max-width: 100%;
    height: auto;
  }

  .title_container {
    max-width: 90%;
  }

  .menu_container {
    display: block;
    width: 100%;
  }

  .details_container {
    align-items: center;
    width: 100%;
    padding-top: 0.5rem;
      height: 358.2px;
  }

  .open {
    visibility: unset;
  }

  .closed {
    visibility: hidden;
  }

  .backButton {
    display: inline;
  }
}
