.panel {
  border: 2px solid transparent;
  border-radius: var(--g-image-radius);
  height: fit-content;
  position: relative;
  color: var(--g-font-color);
  background: white;
  box-shadow: var(--g-regular-shadow);
  overflow-wrap: break-word;
  margin-bottom: 1.5em;
  width: 100%;
  padding: 1.25rem;
  text-align: left;
  cursor: pointer;
}


.panel:hover {
  border: 2px solid;
  .delete_icon {
    display: block;
  }
}

.name {
  margin-left: 2rem;
  font-size: 2rem;
  vertical-align: top;
  width: 100%;
}

.follower {
  margin-left: 2rem;
  font-size: 1.5rem;
  vertical-align: top;
  width: 100%;
}

  .follower::before {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: .4rem;
  }

.twitch::before { background: url('../../../../../../theme/resource/icon/twitch.svg') }
.tiktok::before { background: url('../../../../../../theme/resource/icon/tiktok.svg') }
.youtube::before { background: url('../../../../../../theme/resource/icon/youtube.svg') }
.twitter::before { background: url('../../../../../../theme/resource/icon/twitter.svg') }
.tiktok::before { background: url('../../../../../../theme/resource/icon/tiktok.svg') }

.snapchat::before { background: url('../../../../../../theme/resource/icon/snapchat.svg') }
.instagram::before { background: url('../../../../../../theme/resource/icon/instagram.svg') }
.facebook::before { background: url('../../../../../../theme/resource/icon/facebook.svg') }



.date {
  margin-top: 1rem;
}

.keyword_container {
  width: 100%;
  margin-left: 2rem;
}


.date::before {
  background-image: url('../../../../../../theme/resource/icon/ball.svg');
  width: .6rem;
  height: .6rem;
  margin-top: .75rem;
}


.name:hover {
}

.header {
  text-align: left;
  height: fit-content;
  margin-bottom: 2rem;
}


.avatar_container {
  width: 8rem;
  height: 8rem;
}

.info {
  vertical-align: top;
}

.application_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.application_text_expanded {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
}

.delete_icon {
  display: none;
  position: absolute;

  top: .6rem;
  right: 1rem;

  height: 1.7rem;
  width: 1.7rem;
  background-image: url('../../../../../../theme/resource/icon/delete.svg');
}

.delete_icon:hover {
  scale: 1.2;
}