.section {
    max-width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: center;
    margin-top: 15rem;
    background: rgb(244, 243, 246);
}

.logo_carwashpro {
    margin-top: 2rem;
    background: url("../../../../theme/resource/image/landing/carwashpro.png");
    width: 469px;
    height: 192px;
    background-size: 469px 192px;
    cursor: pointer;
    transition: scale .2s ease-in-out;

    @media (max-width: 500px) {
        width: 300px;
        height: 120px;
        background-size: 300px 120px;
    }
}

.logo_carwashpro:hover {
    scale: 1.05;
}