.panel{
    width: 100%;
    padding: 3.2rem;
}

.progressContainer {
    width: 100%;
    background-color: #e5e7eb; // light gray background
    border-radius: 9999px;
    height: 2.5rem;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
}

.progressBar {
    height: 100%;
    border-radius: 9999px;
    transition: width 0.3s ease-in-out;
    background: linear-gradient(90deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
    float: left;
}

.progressText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
}

.title {
    font-size: 2.56rem;
    line-height: 3rem;
    margin-bottom: 1rem;
    letter-spacing: 0.025em;
}

.completionText {
    margin-bottom: 1rem;
}

.listItem{
    width: 100%;
}

.checklistItem {
    width: 100%;
    display: flex;
    float: left;
    align-items: center;
    margin-bottom: 0.5rem;
}

.icon {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
}

.completed {
    color: rgba(0,255,121,1) !important; // Green color from the gradient
}

.incomplete {
    color: rgba(0,177,255,1) !important; // Blue color from the gradient
}

.icon {
    background: url("../../../../theme/resource/icon/green_checkmark.svg");
    width: 2.25rem;
    height: 2rem;
    margin-right: 2rem;
}

.iconGray {
    background: url('../../../../theme/resource/icon/warning_red.svg');
    width: 2.25rem;
    height: 2rem;
    margin-right: 2rem;
}