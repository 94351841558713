.relative { position: relative }
.absolute { position: absolute }
.static { position: static }


.l-relative { position: relative }
.l-absolute { position: absolute }
.l-static { position: static }

@media only screen and (max-width: $g-medium) {
    .m-relative { position: relative }
    .m-absolute { position: absolute }
    .m-static { position: static }
}

@media only screen and (max-width: $g-small) {
    .s-relative { position: relative }
    .s-absolute { position: absolute }
    .s-static { position: static }
}