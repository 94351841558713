.pagingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;

    button,
    ul {
        margin: 0;
        padding: 5px 10px;
        cursor: pointer;

        &:disabled {
            cursor: default;
        }
    }

    ul {
        display: flex;
        list-style-type: none;

        li {
            margin: 0 2px;
        }
    }
}
