.banner {
    width: 100%;
    height: calc(100vw / 4.2);
    min-height: 140px;
    max-height: 250px;
    border-radius: 15px 15px 0 0;
    border-left: 2px solid rgb(220,220,220);
    border-top: 2px solid rgb(220,220,220);
    border-right: 2px solid rgb(220,220,220);
    border-left: 1px solid rgb(233,233,233);
    border-top: 1px solid rgb(233,233,233);
    border-right: 1px solid rgb(233,233,233);
    //box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .1);
    /* box-shadow: 0px 8px 16px 0px2 rgba(0, 0, 0, .1); */
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-position: center center;
}


.bookmark_container {
    display: grid;
    place-items: center;
    position: absolute;
    background-color: white;
    right: 0;
    top:0;
    width: 10rem;
    height: 5rem;
    border-bottom-left-radius: 1rem;

    .bookmark {
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 1rem;
        right: 6rem;
    }

    .menu {
        font-size: 1.6rem;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        cursor: pointer;
    }

    .tooltip {
        /* bad code */
        z-index: 10000 !important; /* Ensure the tooltip is on top */
        font-size: 1.6rem;
        margin-top: 0.8rem;
        position: absolute; /* Keep the tooltip positioned absolutely */
        top: 100%; /* Position it below the bookmark */
        left: 50%; /* Center it horizontally */
        transform: translateX(-50%); /* Center it horizontally */
        white-space: nowrap; /* Prevent text from wrapping */
        background-color: black; /* Tooltip background color */
        color: white; /* Tooltip text color */
        padding: 0.5rem; /* Tooltip padding */
        border-radius: 0.25rem; /* Tooltip border radius */
        visibility: hidden; /* Initially hide the tooltip */
        opacity: 0; /* Initially hide the tooltip */
    }
}

.type_tag {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 12rem;
    height: 4rem;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .2rem;
    border-top-left-radius: 15px;  /* Oben links abrunden */
    border-bottom-right-radius: 10px;  /* Unten rechts abrunden */
    cursor: default;
}

.type_tag_text {
    color:white;
}

.type_tag_brand {
    margin-right: 0;
    width: 2.5rem;
    height: 2.5rem;
    background: url("../../../../../../theme/resource/icon/brand_white.svg") no-repeat center center;

}
.type_tag_influencer {
    margin-right: 0;

    width: 2.2rem;
    height: 2.2rem;
    background: url("../../../../../../theme/resource/icon/user_white.svg") no-repeat center center;
}


/*
.edit {
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
}

.label {
    display: inline-block;
    background-image: url('../../../../../../theme/resource/icon/camera.svg');
    width: 4rem;
    height: 4rem;
    left: 0;
    right: 0;
    transform: translate(-50%, -50%);
}
*/