.footer {
    padding: 4rem 0;
    margin: 1.6rem;
    margin-top: 4rem;

    max-width: 1044px;  
    width: calc(100% - 3.2rem);
    cursor: default;
}

.footer section div {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: 3.2rem;
}

.footer ul {
    margin: 0;
    min-width: 16rem;
    margin-left: -.5em;
}

.footer ul .title {
    font-size: 15px;
    color: var(--g-label-color);
    padding: .2em .5em;
    padding-right: 0;
    transition: color .2s ease-in-out;

    font-weight: 800;
}

.footer ul:hover .title {
    color: white;
    color: rgb(50,50,50);
}

.footer li {
    font-size: 18px;
    margin-bottom: .08rem;
}

.footer a {
    color: white;
    color: var(--g-dark-gray);
    font-size: 18px;
    padding: 0 .5em;
    text-decoration: none;
}

.footer a:hover {
    background: rgba(0,0,0,.1);
    border-radius: 5px;
    padding: 0 .5em;
}

.footer.gradient-enabled ul .title {
    color: rgb(230, 230, 230);
    text-shadow: 0px 2px 6px rgba(0,0,0,.25);
}

.footer.gradient-enabled a,
.footer.gradient-enabled ul:hover .title {
    color: white;
    text-shadow: 0px 2px 6px rgba(0,0,0,.25);
}

.footer .copy {
    text-align: right;
}

.footer .pre_icon:before {
    display: none;
}

@media only screen and (max-width: 900px) {
    .footer {
        position: static;
        width: 100%;
    }

    main {
        padding-bottom: 0;
    }

    .footer .copy {
        width: 100%;
        text-align: center;
        margin-top: 1.6rem;
    }
}

@media all and (max-width: 900px) {
    .footer {
        width: calc(100% - 3.2rem);
        padding-top: 2.4rem;
        padding-bottom: 0;
        margin: 1.6rem;
        border-radius: 8px;
    }

    .footer section {
        padding: 0 3.2rem;
    }
}

@media only screen and (max-width: 680px) {
    .footer {
        width: calc(100% - 3.2rem);
        padding-top: 2.4rem;
        padding-bottom: 0;
        margin: 1.6rem;
        border-radius: 8px;
    }

    .footer section div {
        margin-bottom: 0;
        overflow: hidden;
    }

    .footer section {
        display: inline-block;
        padding: 0 2rem;
    }

    .footer .copy {
        margin-top: .8rem;
    }

    .footer ul {
        padding-top: 0;
        padding-bottom: 0;
        margin-left: 0;
    }

    .footer ul:first-child {
        border-top: 1.5px solid rgb(183,183,183);
    }

    .footer ul .title {
        text-transform: capitalize;
        color: var(--g-light-gray);
        font-size: 1.6rem;
        padding: .8rem 1.2rem;
    }

    .footer ul li:not(.footer ul li:first-child) {
        margin-left: 2.4rem;
    }

    .footer ul li a {
        font-size: 1.6rem;
    }

    .footer .icon_plus::before {
        background-image: url('../../resource/icon/plus.svg');
        margin-top: .4rem;
        width: 1.6rem;
        height: 1.6rem;
        opacity: .5;
    }

    .footer ul {
        display: block;
        border-bottom: 1.5px solid rgb(183,183,183);
        margin-bottom: 0;
        cursor: pointer;
        width: 100%;
    }

    .footer ul:last-child {
        border: none;
    }

    .footer ul.collapsed li {
        display: none;
    }

    .footer ul.collapsed li:first-child {
        display: inline-block;
    }

    .footer ul .pre_icon::before {
        transition: transform .4s;
    }

    .footer ul.collapsed .pre_icon::before {
        transform: rotate(0deg);
    }

    .footer .pre_icon::before {
        display: block;
        transform: rotate(45deg);
    }

    .footer ul li:last-child {
        margin-bottom: 1.6rem;
    }
}