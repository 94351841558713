.modalOverlay {
    position: fixed;
    inset: 0; /* shorthand for top:0, right:0, bottom:0, left:0 */
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    /* Remove or reduce padding to avoid offset when measuring. */
    padding: 0;
    margin: 0;

    background-color: #fff;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    /* Ensure box-sizing is content-box or border-box (your preference). */
    box-sizing: border-box;
}

.modalTitle {
    margin: 0 0 15px 0;
    padding: 0;
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 70vh;

    /* No extra margins/padding that can offset your bounding box. */
    margin: 0;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;

    /* If you suspect the box-shadow might shift your layout, remove or reduce it. */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    box-sizing: border-box;
}

.selectedImage {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.modalFooter {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.cropBox {
    position: absolute;
    border: 2px solid #fff;
    box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
    cursor: move;
    box-sizing: border-box;
}

.resizeHandle {
    position: absolute;
    right: -10px;
    bottom: -10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: se-resize;
    box-sizing: border-box;
}

/* For circular profile resizing (if using a different handle position) */
.circleResize {
    right: 15%;
    bottom: 15%;
    cursor: nwse-resize;
}
