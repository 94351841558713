.iconWithText {
    display: flex;
    align-items: center;
    box-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.07);
    margin-top: 1rem;
    margin-bottom: 1rem;

}
.iconWithText > div {
    margin-left: 5px; /* Adjust this value to move the text further from the icon */
    margin-right: 5px;
}

.icon {
    width: 40px;
    height: 29px;
    background: url("../../../../../../theme/resource/icon/block.svg");
    background-repeat: no-repeat;
    @media only screen and (max-width: 735px) {
        margin-top: 1.5rem;
    }
}

.blocked_container {
    margin-top: 0;
    padding: 1rem;
    overflow: auto; /* Allows scrolling when content overflows */
    height: 30rem;
}



.info {
    color: gray;
}

.subscriptionContainer {
    text-align: center;
}

.rate {
    color: gray;
}

.nextBilling {
    color: gray;
}

.button_Right {
    bottom: 2rem;
    position: absolute;
    right: 2rem;
    z-index: 1000;
}
