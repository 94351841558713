.tag {
    user-select: none;
    font-size: 1.4rem;
    text-align: center;
    color: white;
    padding: .1rem 1.8rem;
    border-radius: 2rem;
    background-color: var(--g-color-green);
    margin-top: .4rem;
    margin-left: 1rem;
}

.green {
    background-color: var(--g-color-green);
}

.blue {
    background-color: var(--g-color-blue);
}