.container {
    float: right;
    width: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.container > div {
    margin-bottom: 10px;
}

.container > div > strong {
    font-weight: bold;
}

.clickable {
    cursor: pointer;
    color: var(--g-color-primary);
}

.button_container {
    text-align: center;
}
