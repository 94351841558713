.info {
    text-align: center;
    margin-top: 5rem;
}

.info span {
    background: var(--g-transparent-black);
    border-radius: var(--g-panel-radius);
    padding: 2rem;
    max-width: 50rem;
    color: white;
    text-align: left;
}

.info span::before {
    background-image: url('../../../../theme/resource/icon/info.svg');
}