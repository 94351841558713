.label {
    display: inline-block;
    color: var(--g-font-color);
    margin-bottom: 0.32rem;
    transition: color 0.2s ease-in-out;
    font-size: 20px;
    font-weight: 500;
}

.grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    align-items: center;


}

.title {
    text-align: center;
    margin: 0
}

.overlay {
    width:100%;
    max-width: 70rem;
    padding: 3rem 7rem 7rem;
}

@media screen and (max-width: 600px) { /* Adjust the max-width as needed for "handy" screens */
    .overlay {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .AIInput{
        width: 50% !important;
    }
}


.motivation {
    text-align: center;
    font-weight: bold;
    opacity: 0.7;
}

.header {
    margin-top: 4rem;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.image{
    align-content: center;
    width: 20rem;
    height: 20rem;
    border-radius: 16px;
}

.inputContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, auto));
    justify-items: start;
    justify-content: start;
    gap: 1.5rem;
    margin-top: 1rem;
}

.avatar {
    display: inline-block;
    width: 7rem;
    height: 7rem;
    left: 30%;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, .1); */
    overflow: hidden;
    align-items: center;
    padding: 0;
    border-radius: 20%;
    margin: 2rem;
}

.avatar_image {
    width: 100%;
    height: 100%;
    border-radius: 10%;
}

.image_upload {
    position: relative;
    top: 50%;
    opacity: 1.2;
    left: -7%;
}

.tag_view {
    border-radius: 1.5rem;
    padding: .1rem .8rem;
    margin-right: .6rem;

    margin-bottom: .5rem;
    background-color: var(--g-font-color);
    border: 2px solid var(--g-font-color);

    color: white;
}

.tag_view::before {
    content: '#';
}

.tag_view:hover {
    cursor: pointer;
    border: 2px solid var(--g-font-color);
    background-color: white;
    color: black;
    border-color: var(--g-border-color);
}

.AIBox{
    border: 2px solid var(--g-color-blue);
    background-color: var(--g-color-primary-light) ;
    border-radius: var(--g-panel-radius);
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}



.AIText {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: .5rem;
    font-size: 1.2em;
    width: 100%;
}

.AIInput {
    width: 75% !important;
    }

.AIButton{
    position: absolute;
    top: 0;
    right: 0;
}


.AIInnerBox{
    position: relative;
    width: 100%;
}

@media screen and (max-width: 800px) { /* Adjust the max-width as needed for "handy" screens */

    .AIInput{
        width: 100% !important;
    }
    .AIButton{
        position: relative;
        float: right;
    }
}