.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 11rem;
    height: 9rem;
    border-radius: 12px;
    cursor: pointer;
    background-color: #FAFAFA;
    border: 2px solid var(--g-color-light-gray);
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
    color: var(--g-color-dark-gray);
}

@media (hover: hover) {
    .box:hover {
        background-color: #E9F0FF;
        border-color: var(--g-color-blue);
        color: var(--g-color-white);
    }

    .box:hover .label {
        color: var(--g-color-white);
    }

    .box:hover .icon svg {
        fill: var(--g-color-white);
    }
}

.selected {
    background-color: var(--g-color-light-green);
    border-color: var(--g-color-green);
    color: var(--g-color-green);
}

.selected .label {
    color: var(--g-color-green);
}

.selected .icon svg {
    fill: var(--g-color-green);
}

.icon {
    font-size: 1.75rem;
    transition: fill 0.2s ease-in-out;
}

.label {
    margin-top: .25rem;
    font-size: 1.25rem;
    font-weight: 500;
}

.icon {
    height: 4rem;
    width: 4rem;
}

.influencer::before,
.product_placement::before,
.premium::before,
.community::before,
.youtube::before,
.instagram::before,
.twitch::before,
.influencer_gradient::before,
.twitter::before,
.tiktok::before,
.brand::before,
.influencer::before,
.brandAndInfluencer::before,
.free_placement::before {
    display: block;
    height: 100%;
    width: 100%;
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.influencer_gradient::before {
    background-image: url("../../../theme/resource/icon/influencer_gradient.svg");
}

.influencer::before {
    background-image: url("../../../theme/resource/icon/influencer.svg");
}

.premium::before{
    background-image: url("../../../theme/resource/icon/influencer.svg");
}

.youtube::before{
    background-image: url("../../../theme/resource/icon/youtube.svg");
}

.instagram::before{
    background-image: url("../../../theme/resource/icon/instagram.svg");
}

.twitter::before{
    background-image: url("../../../theme/resource/icon/twitter.svg");
}

.twitch::before{
    background-image: url("../../../theme/resource/icon/twitch.svg");
}

.tiktok::before{
    background-image: url("../../../theme/resource/icon/tiktok.svg");
}

.facebook::before{
    background-image: url("../../../theme/resource/icon/facebook.svg");
}

.community::before{
    background-image: url("../../../theme/resource/icon/community_gradient.svg");
}

.product_placement::before {
    background-image: url("../../../theme/resource/icon/placement.svg");
}

.free_placement::before {
    background-image: url("../../../theme/resource/icon/deal.svg");
}

.brand::before {
    background-image: url("../../../theme/resource/icon/brand.svg");
}

.brandAndInfluencer::before {
    background-image: url("../../../theme/resource/icon/heart.svg");
}