.pre-icon::before {
    content: '';
    display: block;
    background: url('../resource/icon/rank.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 2.4rem;
    height: 2.4rem;
    float: left;
    margin-top: -.16rem;
    margin-right: .56rem;
}

.post-icon::after {
    content: '';
    display: block;
    background: url('../resource/icon/rank.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 2.4rem;
    height: 2.4rem;
    float: right;

    margin-top: -.16rem;
    margin-left: .56rem;
}