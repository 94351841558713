.login {
    width: 100%;
    max-width: 35rem;
}

.login_icon {
    width: 6.8rem;
    height: 6.8rem;
    background: url('../../theme/resource/icon/account.svg');
}

.signup a {
    margin-left: .8rem;
}

.or {
    &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 2px;
        background: var(--g-border-color);
        position: relative;
        top: .6rem;
        opacity: .5;
    }

    .label {
        display: inline-block;
        padding: 0 .8rem;
        background: white;
        position: relative;
        top: -1.5rem;
        text-transform: uppercase;
        font-size: 1.2rem;
    }
}

.google_authentication_button {
    padding: .8rem 1.2rem;
    cursor: pointer;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    position: relative;
    transition: border-color .2s ease-in-out;

    &:hover {
        border-color: var(--g-primary-color);
    }

    .icon {
        position: absolute;
        left: 1.2rem;
        top: .8rem;
        bottom: .8rem;
        width: 2.4rem;
        height: 2.4rem;
    }

    .label {
        vertical-align: center;
    }
}

.apple_authentication_button {
    padding: .8rem 1.2rem;
    cursor: pointer;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    position: relative;
    transition: border-color .2s ease-in-out;

    &:hover {
        border-color: var(--g-primary-color);
    }

    .icon {
        position: absolute;
        left: 1.2rem;
        top: .3rem;
        bottom: .8rem;
        font-size: 2.6rem;
        color: black;
        width: 2.4rem;
        text-align: center;
    }

    .label {
        vertical-align: center;
    }
}

.line {
    width: 100%;
    height: 2px;
    background: var(--g-border-color);
    opacity: .5;
    position: relative;
    top: 0rem;
    text-align: center;
}

.google_terms_of_service_apply {
    display: block;
    color: var(--g-font-color);
    opacity: .5;
    padding-bottom: 1rem !important;

    .link {
        color: var(--g-font-color);
        text-decoration: underline;
    }
}




.container {
    max-width: 50rem;
    width: 100%;
    background: white;
    height: 82rem;
    border-radius: 2.5rem;
    overflow: hidden;
    box-shadow: var(--g-regular-shadow);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    text-align: center;

    @media (max-width: 600px) {
        width: 95%;
        height: 80rem;
    }

    @media (max-width: 430px) {
        width: 95%;
        height: 84rem;
    }
}

.header {
    color: white;
    font-size: 2rem;
    margin-top: 2.5rem;
    font-weight: bolder;
}

.mike_influence {
    position: absolute;
    left: 0;
    right: 0;
    top: 4rem;
    background: url("../../theme/resource/image/landing/mike_influence.png");
    width: 270px;
    height: 270px;
    background-size: 270px 270px;
}


.image {
    position: relative;
    background: linear-gradient(315deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
    height: 25rem;
    width: 100%;
    border-radius: 0 0 200% 200%;

}

.input_area {
    margin-top: 5rem;
    width: 80%;
}