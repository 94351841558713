.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 12rem;
    width: 100%;
    background: var(--g-font-color);
    background: linear-gradient(155deg, rgba(0,177,255,1) 0%, rgba(0,255,121,1) 100%);
    border-radius: 5px;
    transition: background-color 0.3s;
    padding: .3rem;

    .inner_container {
        height: 100%;
        width: 100%;
        border-radius: 3px;
        background: white;
        padding: 1.5rem 2rem;

        .networks {
            display: flex;
            justify-content: space-between;
            //background: yellow;

            .network {
                height: 3rem;
                width: 3rem;
                //background: #9c27b0;
            }

            .youtube {
                background: url('../../../../../../theme/resource/icon/youtube.svg');
            }

            .twitch {
                background: url('../../../../../../theme/resource/icon/twitch.svg');
            }

            .x {
                background: url('../../../../../../theme/resource/icon/twitter.svg');
            }

            .tiktok {
                background: url('../../../../../../theme/resource/icon/tiktok.svg');
            }

            .instagram {
                background: url('../../../../../../theme/resource/icon/instagram.svg');
            }

            .facebook {
                background: url('../../../../../../theme/resource/icon/facebook.svg');
            }

        }

        .cta {
            margin-left: 1.1rem;
            font-size: 2rem;
            font-weight: 500;
            margin-top: .5rem;
            color: var(--g-font-color);
        }

        .learn_more {
            margin-left: 1.2rem;
            font-size: 1.5rem;
            color: var(--g-label-color);

            &:hover {
                color: var(--g-color-primary);
            }
        }
    }
}

.border_none {
    background: white;
}