.dropdown {
    background-color: white;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    box-shadow: var(--g-regular-shadow);
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(100% + .8rem);
    overflow: hidden;
    text-align: left;

    transition: height 350ms, opacity 350ms, border-radius 350ms;
    opacity: 1;

    z-index: 1000000;

    max-height: 50rem;
}

.search_icon {
    background-image: url('../../../../../theme/resource/icon/go.svg');
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
    margin-left: .5rem;
}

.selected_hint {
    cursor: pointer;
    padding-left: 3rem;
    background: var(--g-border-color);
}

.hint {
    cursor: pointer;
    padding: 1rem;
}

.hint:hover {
    background: var(--g-border-color);
}
