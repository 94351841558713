:root {

    /* Colors */
    
    --g-transparent-black: rgba(35, 35, 35);

    --g-black: rgb(0, 0, 0);
    --g-dark-gray: rgb(54, 54, 54);




    --g-color-dark-gray: rgb(69, 69, 69);
    --g-color-regular-gray: rgb(100, 100, 100);
    --g-color-light-gray: rgb(150, 150, 150);
    --g-color-extra-light-gray: rgb(200, 200, 200);
    --g-color-dark-white: rgb(230, 230, 230);
    --g-light-regular-white: rgb(244, 243, 246);
    --g-color-light-white: rgb(255, 255, 255);

    /* --g-color-neutral => creme */

    --g-color-blue: rgb(0, 83, 255);
    --g-color-green: rgb(0, 206, 72);
    --g-color-yellow: rgb(255, 186, 0);
    --g-color-red: rgb(240, 33, 43);

    --g-color-green: rgb(52, 229, 115);
    --g-color-yellow: rgb(255, 212, 103);
    --g-color-failure-hover: rgb(218, 118, 122);
    /* Colors for Profile Tiles */
    --g-color-light-green: rgb(232, 250, 231);
    --g-color-green: rgb(17, 207, 15);
    --g-color-profile-dark-gray: rgb(233, 233, 233);
    --g-color-profile-mid-gray: rgb(166, 166, 166);
    --g-color-profile-gray: rgb(138, 138, 138);
    --g-color-orange: rgb(232, 144, 20);
    --g-color-light-orange: rgb(246, 211, 161);

    /* Colors with Names */
    
    --g-color-background: rgb(244, 243, 246); 
    --g-panel: rgb(255, 255, 255);

    --g-font-color: rgb(33, 33, 33);

    --g-color-info: var(--g-color-blue);
    --g-color-success: var(--g-color-green);
    --g-color-warning: var(--g-color-yellow);
    --g-color-failure: var(--g-color-red);



    /* Legacy Colors */
    
    --g-dark-gray: rgb(69, 69, 69);
    --g-light-gray: rgb(100, 100, 100);
    --g-label-color: rgb(150, 150, 150);
    --g-border-color: rgb(200, 200, 200);



    --g-color-primary: rgb(0, 83, 255);
    --g-color-primary-hover: rgb(0, 51, 255);
    --g-color-primary-light: rgba(0, 83, 255, 0.2);

    /* Radius */

    --g-small-radius: 5px;
    --g-regular-radius: 8px;
    --g-large-radius: 15px;

    --g-image-radius: 15px;
    --g-banner-radius: 15px;
    --g-panel-radius: 8px;
    //--g-input-radius: 5px;
    --g-input-radius: 8px;

    /* Shadows */
    
    --g-light-shadow: 0px 20px 40px -12px rgba(0, 0, 0, .1);
    --g-regular-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    --g-dark-shadow: 0px 0px 10px rgba(0, 0, 0, .5)
}

.lightmode { }

.darkmode { }