.container {
    padding: 5rem;
    border-radius: 30px;
    text-align: center;
    max-width: 100%;
    //box-shadow: 0 25px 45px #00000064;
    margin-bottom: 5rem;
    margin-top: 5rem;
    background: url("../../../../theme/resource/image/landing/join_background.svg")
        no-repeat center;
    background-size: 90%;
    height: 30rem;
    margin-bottom: 10rem;
    max-width: 1400px;

    h2 {
        font-weight: 600;
    }
}

.section {
    max-width: 140rem;
    padding: 0;
    text-align: center;
    margin-top: 15rem;
}

.join_button {
    padding: 1.3rem;
    border-radius: 30px;
    background: transparent linear-gradient(167deg, #00c0fc 0%, #03ff00 100%);
    width: 20rem;
    height: 5rem;
    color: white;
    cursor: pointer;
}
.join_button:hover {
    animation: rotateGradient 2s infinite linear; /* Animation effect */
    color: white;
}

h2 {
    font-size: 3rem;
    color: var(--g-font-color);
}

@keyframes rotateGradient {
    /* Define the keyframes with increments */
    /* Each keyframe represents a small step towards 77deg */
    /* The gradient angle increases gradually from 167deg to 77deg */
    0% {
        background: transparent
            linear-gradient(167deg, #00c0fc 0%, #03ff00 100%);
    }
    2.5% {
        background: transparent
            linear-gradient(calc(167deg + 9deg), #00c0fc 0%, #03ff00 100%);
    }
    5% {
        background: transparent
            linear-gradient(calc(167deg + 18deg), #00c0fc 0%, #03ff00 100%);
    }
    7.5% {
        background: transparent
            linear-gradient(calc(167deg + 27deg), #00c0fc 0%, #03ff00 100%);
    }
    10% {
        background: transparent
            linear-gradient(calc(167deg + 36deg), #00c0fc 0%, #03ff00 100%);
    }
    12.5% {
        background: transparent
            linear-gradient(calc(167deg + 45deg), #00c0fc 0%, #03ff00 100%);
    }
    15% {
        background: transparent
            linear-gradient(calc(167deg + 54deg), #00c0fc 0%, #03ff00 100%);
    }
    17.5% {
        background: transparent
            linear-gradient(calc(167deg + 63deg), #00c0fc 0%, #03ff00 100%);
    }
    20% {
        background: transparent
            linear-gradient(calc(167deg + 72deg), #00c0fc 0%, #03ff00 100%);
    }
    22.5% {
        background: transparent
            linear-gradient(calc(167deg + 81deg), #00c0fc 0%, #03ff00 100%);
    }
    25% {
        background: transparent
            linear-gradient(calc(167deg + 90deg), #00c0fc 0%, #03ff00 100%);
    }
    27.5% {
        background: transparent
            linear-gradient(calc(167deg + 99deg), #00c0fc 0%, #03ff00 100%);
    }
    30% {
        background: transparent
            linear-gradient(calc(167deg + 108deg), #00c0fc 0%, #03ff00 100%);
    }
    32.5% {
        background: transparent
            linear-gradient(calc(167deg + 117deg), #00c0fc 0%, #03ff00 100%);
    }
    35% {
        background: transparent
            linear-gradient(calc(167deg + 126deg), #00c0fc 0%, #03ff00 100%);
    }
    37.5% {
        background: transparent
            linear-gradient(calc(167deg + 135deg), #00c0fc 0%, #03ff00 100%);
    }
    40% {
        background: transparent
            linear-gradient(calc(167deg + 144deg), #00c0fc 0%, #03ff00 100%);
    }
    42.5% {
        background: transparent
            linear-gradient(calc(167deg + 153deg), #00c0fc 0%, #03ff00 100%);
    }
    45% {
        background: transparent
            linear-gradient(calc(167deg + 162deg), #00c0fc 0%, #03ff00 100%);
    }
    47.5% {
        background: transparent
            linear-gradient(calc(167deg + 171deg), #00c0fc 0%, #03ff00 100%);
    }
    50% {
        background: transparent
            linear-gradient(calc(167deg + 180deg), #00c0fc 0%, #03ff00 100%);
    }
    52.5% {
        background: transparent
            linear-gradient(calc(167deg + 188deg), #00c0fc 0%, #03ff00 100%);
    }
    55% {
        background: transparent
            linear-gradient(calc(167deg + 198deg), #00c0fc 0%, #03ff00 100%);
    }
    57.5% {
        background: transparent
            linear-gradient(calc(167deg + 207deg), #00c0fc 0%, #03ff00 100%);
    }
    60% {
        background: transparent
            linear-gradient(calc(167deg + 216deg), #00c0fc 0%, #03ff00 100%);
    }
    62.5% {
        background: transparent
            linear-gradient(calc(167deg + 225deg), #00c0fc 0%, #03ff00 100%);
    }
    65% {
        background: transparent
            linear-gradient(calc(167deg + 234deg), #00c0fc 0%, #03ff00 100%);
    }
    67.5% {
        background: transparent
            linear-gradient(calc(167deg + 243deg), #00c0fc 0%, #03ff00 100%);
    }
    70% {
        background: transparent
            linear-gradient(calc(167deg + 252deg), #00c0fc 0%, #03ff00 100%);
    }
    72.5% {
        background: transparent
            linear-gradient(calc(167deg + 261deg), #00c0fc 0%, #03ff00 100%);
    }
    75% {
        background: transparent
            linear-gradient(calc(167deg + 270deg), #00c0fc 0%, #03ff00 100%);
    }
    77.5% {
        background: transparent
            linear-gradient(calc(167deg + 279deg), #00c0fc 0%, #03ff00 100%);
    }
    80% {
        background: transparent
            linear-gradient(calc(167deg + 288deg), #00c0fc 0%, #03ff00 100%);
    }
    82.5% {
        background: transparent
            linear-gradient(calc(167deg + 297deg), #00c0fc 0%, #03ff00 100%);
    }
    85% {
        background: transparent
            linear-gradient(calc(167deg + 306deg), #00c0fc 0%, #03ff00 100%);
    }
    87.5% {
        background: transparent
            linear-gradient(calc(167deg + 315deg), #00c0fc 0%, #03ff00 100%);
    }
    90% {
        background: transparent
            linear-gradient(calc(167deg + 324deg), #00c0fc 0%, #03ff00 100%);
    }
    92.5% {
        background: transparent
            linear-gradient(calc(167deg + 333deg), #00c0fc 0%, #03ff00 100%);
    }
    95% {
        background: transparent
            linear-gradient(calc(167deg + 342deg), #00c0fc 0%, #03ff00 100%);
    }
    97.5% {
        background: transparent
            linear-gradient(calc(167deg + 351deg), #00c0fc 0%, #03ff00 100%);
    }
    100% {
        background: transparent
            linear-gradient(167deg, #00c0fc 0%, #03ff00 100%);
    }
}
