.container {
    text-align: left;
    //margin-bottom: 1.25rem;
    margin-bottom: .5rem;
    position: relative;
}

.label {
    display: inline-block;
    color: var(--g-light-gray);
    padding-left: .4rem;
    transition: color .2s ease-in-out;
}

.container:hover .label {
    color: var(--g-font-color)
}

.container:focus-within .label {
    color: var(--g-color-primary)
}

.error .label,
.error:focus-within .label {
    color: var(--g-color-failure)
}

.infoIcon {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: .3rem;
    right: 1.2rem;
    background: url('../../resource/icon/questionmark.svg');
    opacity: .5;
    transition: opacity .2s ease-in-out;
}

.infoIcon:hover {
    opacity: 1;
}

.infoMessage {
    color: var(--g-color-primary);
    font-weight: 400;
    font-size: 1.52rem;
    padding-left: .4rem;
    text-align: left;
}