.connect {
//    float: right;
    margin-right: 2rem;
    min-width: 10rem;
    vertical-align: top;
    margin-top: .8rem;

    padding: .64rem 1.6rem;
    border-radius: var(--g-panel-radius);

    font-size: 1.6rem;

    background: var(--g-color-primary);
    border: 2px solid transparent;
    color: white;
    cursor: pointer;
    -webkit-appearance: none;

    transition: filter 250ms ease-in-out;
}

.connect:active {
    background: rgb(0, 58, 178);
    color: rgb(220, 220, 220);
}

.connect.waiting {
    background: rgb(251, 188, 5);
}

.connect.waiting:active {
    background: rgb(178, 148, 0);
}

.connect.responded {
    background: rgb(23, 181, 53);
}

.connect.responded:active {
    background: rgb(16, 126, 37);
}

.connect.blocked {
    background: var(--g-color-failure);
}

.connect.cantMessage {
    background: black;
    cursor: no-drop;
}

.connect.blocked:active {
    background: var(--g-color-failure);
}

@media all and (max-width: 850px) {
    .connect {
        display: inline-block;
        margin: .8rem auto;
        margin-bottom: 1.2rem;
        width: 100%;
        position: static;
        float: none;
    }
}