.join {
    //overflow-x: hidden;
    overflow-y: visible;
    width: 100%;
    max-width: 35rem;
}

.join_icon {
    width: 6.8rem;
    height: 6.8rem;
    background: url('../../theme/resource/icon/account.svg');
    margin-bottom: 1.5rem;
}

.login a {
    margin-left: .5rem;
}

.animation_container {
    position: relative;
    transition: height 400ms;
}

.criteria_container {
    margin-top: 1.5rem;
}

//.section_label {
//    margin-top: 1rem;
//    color: var(--g-light-gray);
//    margin-bottom: 0.32rem;
//    padding-left: 0.4rem;
//    text-align: left;
//}

.or {
    &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 2px;
        background: var(--g-border-color);
        position: relative;
        top: .6rem;
        opacity: .5;
    }

    .label {
        display: inline-block;
        padding: 0 .8rem;
        background: white;
        position: relative;
        top: -1.5rem;
        text-transform: uppercase;
        font-size: 1.2rem;
    }
}

.google_authentication_button {
    padding: .8rem 1.2rem;
    cursor: pointer;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    position: relative;
    transition: border-color .2s ease-in-out;

    &:hover {
        border-color: var(--g-primary-color);
    }

    .icon {
        position: absolute;
        left: 1.2rem;
        top: .8rem;
        bottom: .8rem;
        width: 2.4rem;
        height: 2.4rem;
    }

    .label {
        vertical-align: center;
    }
}

.apple_authentication_button {
    padding: .8rem 1.2rem;
    cursor: pointer;
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    position: relative;
    transition: border-color .2s ease-in-out;

    &:hover {
        border-color: var(--g-primary-color);
    }

    .icon {
        position: absolute;
        left: 1.2rem;
        top: .3rem;
        bottom: .8rem;
        font-size: 2.6rem;
        color: black;
        width: 2.4rem;
        text-align: center;
    }

    .label {
        vertical-align: center;
    }
}

.line {
    width: 100%;
    height: 2px;
    background: var(--g-border-color);
    opacity: .5;
    position: relative;
    top: 0rem;
    text-align: center;
}

.info {
    margin-top: .4rem;
    padding-left: .4rem;
    font-weight: normal;
    font-size: 1.52rem;
    text-align: left;
    color: var(--g-color-light-gray);
}

.accept_terms_of_service {
    font-weight: normal;
    font-size: 1.52rem;
    text-align: left;
    color: var(--g-color-light-gray);
}

.google_terms_of_service_apply {
    display: block;
    color: var(--g-font-color);
    opacity: .5;

    .link {
        color: var(--g-font-color);
        text-decoration: underline;
    }
}

.back_button {
    top: -8.8rem;
    left: -.8rem;
    position: absolute;
    background: url("../../theme/resource/icon/chevron.svg");
    width: 3rem;
    height: 3rem;
    rotate: 180deg;
}
