.container {
    text-align: center !important;
}

.header {
    font-size: 3rem;
    font-weight: bold;
}

.switch {
    margin-top: 2rem;
    margin-bottom: 5rem;
}

.benefit {
    height: 35rem;
    text-align: left;
}

.line_business {
    width: 5rem;
    height: 1rem;
    border-top: #03ff00 0.2rem solid;
}

.line_influencer {
    width: 7rem;
    float: left;
    height: 1rem;
    border-top: #00c0fc 0.2rem solid;
}

.benefit_text {
    width: 90%;
    font-weight: 600;
}

.benefit_tile {
    width: 50%;
    vertical-align: top;
}

.phone1 {
    //background: url("../../../../theme/resource/image/landing/phone1.png");
    width: 100%;
    height: 45rem;
}

.subscriptionTile {
    background-color: #ffffff;
    box-shadow: 0px 25px 45px #00000029;
    border-radius: 3rem;
    padding: 20px;
    margin: 10px 0;
    transition: transform 0.2s ease-in-out;
    width: 42rem;
    height: 45rem;
    &:hover {
        transform: translateY(-5px);
    }
    @media (max-width: 800px) {
        width: 80%;
        height: 35rem;
    }
}

.subscriptionTileTitle {
    font-size: 20px;
    color: #333539;
    font-weight: bold;
}
.price {
    font-size: 36px;
    font-weight: bold;
    color: #333539;
}

.plansGrid {
    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        minmax(250px, 1fr)
    ); // Creates a responsive grid with columns that have a minimum width of 250px
    gap: 20px; // Spacing between grid items
    margin-top: 20px; // Space above the grid
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; // This centers the content vertically if there's enough space
    text-align: center;
    @media (max-width: 800px) {
        flex-direction: column;
    }
}

.benefits {
    list-style: none;
    padding: 0.5rem;
    margin: 1rem 0;
    height: 25rem;
    font-weight: 400;

    li {
        margin-bottom: 5px;
        margin: 0 3rem;
        padding: 0.5rem;
        position: relative;
        color: #333539;
        font-weight: 400;
        text-align: left;
        padding-left: 1.5rem;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #333539; // Change this color based on your design
        }
        @media (max-width: 800px) {
            margin: 0;
        }
    }
}

.action {
    font-size: 15px;
    color: #333;
    margin-bottom: 15px;
    :hover {
        opacity: 0.8;
        cursor: pointer;
    }
    @media (max-width: 800px) {
        margin-top: -6.5rem;
    }
}

.premiumSubscriptionTile {
    background: transparent linear-gradient(138deg, #00c0fc 0%, #03ff00 100%) 0%
        0% no-repeat padding-box;
    box-shadow: 0px 25px 45px #00000029;
    background-filter: blur(50px);
    opacity: 1;
    border-radius: 3rem;
    padding: 20px;
    margin: 10px 0;
    transition: transform 0.2s ease-in-out;
    width: 42rem;
    height: 45rem;

    &:hover {
        transform: translateY(-5px);
    }
    @media (max-width: 800px) {
        width: 80%;
        height: 50.5rem;
    }
}

.premiumSubscriptionTileTitle {
    font-size: 20px;
    color: white;
    font-weight: bold;
}

.premiumPrice {
    font-size: 36px;
    font-weight: bold;
    color: white;
}

.premiumBenefits {
    list-style: none;
    padding: 0.5rem;
    margin: 1rem 0;
    height: 25rem;
    font-weight: 400;

    li {
        margin-bottom: 5px;
        margin: 0 3rem;
        padding: 1rem;
        position: relative;
        color: white;
        text-align: left;
        padding-left: 1.5rem;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: white; // Change this color based on your design
        }
        @media (max-width: 800px) {
            margin: 0;
        }
    }
}

.safe_area {
    @media (max-width: 800px) {
        margin-bottom: 11rem;
    }
}

.premiumAction {
    margin-top: 3rem;
    font-size: 2.25rem;
    color: white;
    margin-bottom: 15px;
    font-weight: bolder;
    @media (max-width: 800px) {
        margin-top: 10rem;
    }
    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}
