.container, .inputContainer {
    display: inline-block;
    position: relative;
    width: 100%;
}

.input {
    padding: .8rem 1.6rem;
    padding-right: 3.2rem;
    border-radius: var(--g-input-radius);
    border-radius: 15px;

    font-size: 1.6rem;

    background: white;
    border: 2px solid var(--g-border-color);
    border: none;
    box-shadow: var(--g-regular-shadow);
    color: black;
    -webkit-appearance: none;

    transition: border-color 250ms ease-in-out;

    width: 100%;
}

.input:hover {
    border-color: var(--g-color-primary-hover);
}

.input:focus {
    border-color: var(--g-color-primary);
}

.input:disabled {
    background: var(--g-border-color);
    border-color: var(--g-border-color);

    cursor: not-allowed;
}

.input:disabled::placeholder {
        color: var(--g-dark-gray)
}

.error input, 
.error:focus-within input, 
.error:hover input {
    border-color: var(--g-color-failure);
}

.label {
    display: inline-block;
    color: var(--g-light-gray);
    margin-bottom: .32rem;
    padding-left: .4rem;
    transition: color .2s ease-in-out;
}

.container:focus-within .label {
    color: var(--g-color-primary)
}

.error .label,
.error:focus-within .label {
    color: var(--g-color-failure)
}

.errorMessage {
    display: inline-block;
    color: var(--g-color-failure);
    font-weight: 400;
    font-size: 1.52rem;
    margin-top: .4rem;
    padding-left: .4rem;
}

.infoMessage {
    display: inline-block;
    color: var(--g-color-primary);
    font-weight: 400;
    font-size: 1.52rem;
    margin-top: .4rem;
    margin-bottom: -.72rem;
    padding-left: .4rem;
}

.infoIcon {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: -2.4rem;
    right: .4rem;
    background: url('../../resource/icon/questionmark.svg');
    opacity: 1;
    transition: opacity .2s ease-in-out;
}

.infoIcon:hover {
    opacity: 1;
}

.default {
    border: 2px solid var(--g-border-color);
    border-radius: var(--g-input-radius);
    box-shadow: none;
}