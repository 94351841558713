.container {
    border-radius: 2rem;
    height: 5rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background-color: white; /* Optional: Adding a background color to make the hover effect visible */
}

.container:hover {
    background-color: lightgrey;
}

.unblock_button {
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    font-size: 1.3rem;
    margin-left: auto; /* Aligns the button to the right */
    margin-right: 2rem;
}

.avatar {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
}

.avatar_container {
    margin-top: .5rem;
    margin-right: 2rem; /* 2rem space between avatar and displayname */
    cursor: pointer;
    margin-left: 2rem;
}

.displayname {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1; /* Take up remaining space */
}
@media (max-width: 800px) {
    .unblock_button {
        margin-right: 0;
    }

    .avatar_container {
        margin-left: 0;
    }
}
