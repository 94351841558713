.filterBar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 2rem;

    > span {
        margin-right: 2rem;
        margin-bottom: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 800px) {
        justify-content: center;
        margin-left: 0;
        margin-top: 1rem;
        align-items: left !important;

        > span {
            flex-basis: 100%;
            text-align: left;
            margin-left: 4rem;
        }
    }
}

.locationFilter {
    @media (max-width: 800px) {
        width: 30% !important;
    }
}
