.container {
    max-width: 50rem;
    width: 100%;

    vertical-align: top;

    @media (max-width: 1000px) {
        max-width: 40rem;
        margin-top: 4rem;
        margin-bottom: 4rem;
        padding: 0 1.2rem;
    }
}

.logo {

}

.logo_container {
    float: left;
    width: 100%;
}

.logo_inner_container {
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2), 0 8px 16px rgba(0, 0, 0, .1);
    border-radius: 30px;
    border-radius: var(--radius8);
    padding: .5rem .75rem;
    padding-right: 1.5rem;
    margin: 0;
    margin-bottom: 2rem;

    .logo {
        background: url("../../../../../../theme/resource/icon/influspace.svg");
        width: 5rem;
        height: 5rem;
        margin: auto;
        margin-right: 1rem;
    }

    .brand {
        font: normal normal bold 22px/33px Poppins;
        //font-weight: 600;
        letter-spacing: 0;
        color: #333539;
        text-transform: uppercase;
        margin: auto 0;
        margin-top: 1rem;
    }
}

.section {
    position: relative;
    max-width: 140rem;
    padding: 0;
    margin-top: 5rem;
    padding-bottom: 10rem;
    @media (max-width: 800px) {
        overflow-x: hidden;
    }
}

.login_button,
.signup_button,
.signup_buttonHeader {
    width: 15rem;
    height: 5rem;
    text-align: center;
    padding: 1rem;
    cursor: pointer;
    border-radius: 3rem;
    color: white;
    border: white solid 0.2rem;
    background: transparent linear-gradient(167deg, #00c0fc 0%, #03ff00 100%);
    transition: background 0.5s, color 0.5s; /* Transition effect */

    @media (max-width: 640px) {
        width: 15rem;
    }
}

.login_button {
    background: #333539;
    color: white;
    width: 10rem;
    border: transparent solid 0.2rem;
}

.login_button:hover {
    background-color: white;
    color: #333539;
    border: #333539 solid 0.2rem;
}

.signup_button:hover {
    animation: rotateGradient 2s infinite linear; /* Animation effect */
    color: white;
}

.navbar_option {
    position: relative;
    font: normal normal medium 22px/33px Poppins;
    letter-spacing: 0;
    color: #333539;
    text-transform: uppercase;
    //margin-right: 2.5rem;
    margin: 0 1.25rem;
    height: 2rem;
    transition: opacity 0.3s; /* Hover effect transition */
    @media (max-width: 600px) {
        //margin-right: 1.25rem;
    }
}

.navbar_option:hover {
    cursor: pointer;
    opacity: 0.7; /* Hover effect */
}

.small_text {
    font: normal normal normal 26px/42px Poppins;
    color: #333539;
    margin: 0;
    margin-bottom: 2rem;
    font-size: 2rem;
    opacity: 0.7;
    width: fit-content;
}

.big_text {
    font-size: 4.5rem;
    margin: 0;
    margin-bottom: 1rem;
    width: fit-content;

    @media (max-width: 1000px) {
        font-size: 3rem;
    }
}

.button_bar {
    @media (max-width: 600px) {
        width: 100%;
        display: inline-flex;
        gap: 1rem;
    }
}

.signup_button {
    @media (max-width: 600px) {
        width: 46%;
        margin: 0;
    }
}

.navbar_option_container {
    @media (max-width: 600px) {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;

    }
}

.nav_bar {
    margin-bottom: 8rem;
}

.see_more_button {
    margin-left: 2rem;
    width: 15rem;
    height: 5rem;
    text-align: center;
    padding: 1rem;
    font-weight: bolder;
    cursor: pointer;
    border-radius: 3rem;
    border: #333539 solid 0.2rem;

    @media (max-width: 600px) {
        width: 46%;
        margin: 0;
        padding: 1rem .5rem;
    }

}

.see_more_button:hover {
    background: #333539;
    color: white;
}

.icon {
    background: url("../../../../../../theme/resource/icon/influspace.svg");
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 3rem;
}

.logo {
    float: left;
    @media (max-width: 600px) {
        margin-bottom: 2rem;
        width: 100%;
        text-align: center;
    }
}

.login_panel {
    margin-left: 8rem;
    width: 50rem;
    z-index: 1;
    position: relative;
}

@media all and (max-width: 1300px) {

    .section {
        text-align: center;
    }

    .hero_call {
        width: 100%;
        text-align: center;
    }

    .small_text {
        width: 100%;
    }

    .big_text {
        width: 100%;
    }

    .login_panel {
        margin-left: 0;
        margin-top: 10rem;
        display: inline-block;
        width: 100%;
        text-align: center;
    }
}

.looperBG{
    height: 100%;
    width: auto;
    position: absolute;
    right: 1rem;
    bottom: 0;
    opacity: .8;
    z-index: 0;
    overflow: hidden;
    @media all and (max-width: 1200px) {
        height: 50%;
    }
    @media all and (max-width: 39.3rem) {
        bottom: 5rem;
        height: 30rem;
        width: 100%;
    }
    @media all and (min-width: 1500px) {
        right: -20%;
    }
}

@keyframes rotateGradient {
    /* Define the keyframes with increments */
    /* Each keyframe represents a small step towards 77deg */
    /* The gradient angle increases gradually from 167deg to 77deg */
    0% {
        background: transparent
        linear-gradient(167deg, #00c0fc 0%, #03ff00 100%);
    }
    2.5% {
        background: transparent
        linear-gradient(calc(167deg + 9deg), #00c0fc 0%, #03ff00 100%);
    }
    5% {
        background: transparent
        linear-gradient(calc(167deg + 18deg), #00c0fc 0%, #03ff00 100%);
    }
    7.5% {
        background: transparent
        linear-gradient(calc(167deg + 27deg), #00c0fc 0%, #03ff00 100%);
    }
    10% {
        background: transparent
        linear-gradient(calc(167deg + 36deg), #00c0fc 0%, #03ff00 100%);
    }
    12.5% {
        background: transparent
        linear-gradient(calc(167deg + 45deg), #00c0fc 0%, #03ff00 100%);
    }
    15% {
        background: transparent
        linear-gradient(calc(167deg + 54deg), #00c0fc 0%, #03ff00 100%);
    }
    17.5% {
        background: transparent
        linear-gradient(calc(167deg + 63deg), #00c0fc 0%, #03ff00 100%);
    }
    20% {
        background: transparent
        linear-gradient(calc(167deg + 72deg), #00c0fc 0%, #03ff00 100%);
    }
    22.5% {
        background: transparent
        linear-gradient(calc(167deg + 81deg), #00c0fc 0%, #03ff00 100%);
    }
    25% {
        background: transparent
        linear-gradient(calc(167deg + 90deg), #00c0fc 0%, #03ff00 100%);
    }
    27.5% {
        background: transparent
        linear-gradient(calc(167deg + 99deg), #00c0fc 0%, #03ff00 100%);
    }
    30% {
        background: transparent
        linear-gradient(calc(167deg + 108deg), #00c0fc 0%, #03ff00 100%);
    }
    32.5% {
        background: transparent
        linear-gradient(calc(167deg + 117deg), #00c0fc 0%, #03ff00 100%);
    }
    35% {
        background: transparent
        linear-gradient(calc(167deg + 126deg), #00c0fc 0%, #03ff00 100%);
    }
    37.5% {
        background: transparent
        linear-gradient(calc(167deg + 135deg), #00c0fc 0%, #03ff00 100%);
    }
    40% {
        background: transparent
        linear-gradient(calc(167deg + 144deg), #00c0fc 0%, #03ff00 100%);
    }
    42.5% {
        background: transparent
        linear-gradient(calc(167deg + 153deg), #00c0fc 0%, #03ff00 100%);
    }
    45% {
        background: transparent
        linear-gradient(calc(167deg + 162deg), #00c0fc 0%, #03ff00 100%);
    }
    47.5% {
        background: transparent
        linear-gradient(calc(167deg + 171deg), #00c0fc 0%, #03ff00 100%);
    }
    50% {
        background: transparent
        linear-gradient(calc(167deg + 180deg), #00c0fc 0%, #03ff00 100%);
    }
    52.5% {
        background: transparent
        linear-gradient(calc(167deg + 188deg), #00c0fc 0%, #03ff00 100%);
    }
    55% {
        background: transparent
        linear-gradient(calc(167deg + 198deg), #00c0fc 0%, #03ff00 100%);
    }
    57.5% {
        background: transparent
        linear-gradient(calc(167deg + 207deg), #00c0fc 0%, #03ff00 100%);
    }
    60% {
        background: transparent
        linear-gradient(calc(167deg + 216deg), #00c0fc 0%, #03ff00 100%);
    }
    62.5% {
        background: transparent
        linear-gradient(calc(167deg + 225deg), #00c0fc 0%, #03ff00 100%);
    }
    65% {
        background: transparent
        linear-gradient(calc(167deg + 234deg), #00c0fc 0%, #03ff00 100%);
    }
    67.5% {
        background: transparent
        linear-gradient(calc(167deg + 243deg), #00c0fc 0%, #03ff00 100%);
    }
    70% {
        background: transparent
        linear-gradient(calc(167deg + 252deg), #00c0fc 0%, #03ff00 100%);
    }
    72.5% {
        background: transparent
        linear-gradient(calc(167deg + 261deg), #00c0fc 0%, #03ff00 100%);
    }
    75% {
        background: transparent
        linear-gradient(calc(167deg + 270deg), #00c0fc 0%, #03ff00 100%);
    }
    77.5% {
        background: transparent
        linear-gradient(calc(167deg + 279deg), #00c0fc 0%, #03ff00 100%);
    }
    80% {
        background: transparent
        linear-gradient(calc(167deg + 288deg), #00c0fc 0%, #03ff00 100%);
    }
    82.5% {
        background: transparent
        linear-gradient(calc(167deg + 297deg), #00c0fc 0%, #03ff00 100%);
    }
    85% {
        background: transparent
        linear-gradient(calc(167deg + 306deg), #00c0fc 0%, #03ff00 100%);
    }
    87.5% {
        background: transparent
        linear-gradient(calc(167deg + 315deg), #00c0fc 0%, #03ff00 100%);
    }
    90% {
        background: transparent
        linear-gradient(calc(167deg + 324deg), #00c0fc 0%, #03ff00 100%);
    }
    92.5% {
        background: transparent
        linear-gradient(calc(167deg + 333deg), #00c0fc 0%, #03ff00 100%);
    }
    95% {
        background: transparent
        linear-gradient(calc(167deg + 342deg), #00c0fc 0%, #03ff00 100%);
    }
    97.5% {
        background: transparent
        linear-gradient(calc(167deg + 351deg), #00c0fc 0%, #03ff00 100%);
    }
    100% {
        background: transparent
        linear-gradient(167deg, #00c0fc 0%, #03ff00 100%);
    }
}
