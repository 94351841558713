.container {
    text-align: left;
}

.input {
    cursor: pointer;
    border-radius: var(--g-input-radius);
    border: 2px solid var(--g-border-color);
    color: black;
    font-size: 1.6rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    transition: border-color .2s ease-in-out;
    text-align: left;
    background: white;
}

.input::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.6rem;
    width: auto;
    height: 1.6rem;
    background: url('../../resource/icon/chevron.svg') 98% / 1.6rem no-repeat #fff;
}

.input:hover,
.input:hover .dropdown {
    border-color: var(--g-color-primary);
}

.input:active {
    border-color: var(--g-color-primary);
}

.dropdown {
    background-color: white;
    border: 2px solid var(--g-border-color);
    border-top: none;
    border-radius: var(--g-panel-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: var(--g-regular-shadow);
    width: calc(100% + 4px);
    position: absolute;
    left: -.2rem;
    top: calc(100% + -.4rem);
    overflow: hidden;

    transition: all 350ms;
    opacity: 1;

    z-index: 1000000;

    overflow-y: scroll;
}

.dropdown:hover {
    border: 2px solid var(--g-color-primary);
    border-top: none;
}

.dropdown.closed {
    opacity: 0;
}

.selected {
    padding: .8rem 1.6rem;
}

.disabled {
    background: var(--g-border-color);
    border-color: transparent;

    cursor: not-allowed;
}

.label {
    display: inline-block;
    color: var(--g-light-gray);
    margin-bottom: .32rem;
    padding-left: .4rem;
    transition: color .2s ease-in-out;
}

.container:hover .label {
    color: var(--g-font-color);
}

.container:focus-within .label {
    color: var(--g-color-primary)
}

.errorMessage {
    display: inline-block;
    color: var(--g-color-failure);
    font-weight: 400;
    font-size: 1.52rem;
    margin-top: .4rem;
    padding-left: .4rem;
    text-align: left;
}