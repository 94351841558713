.container {
    text-align: center !important;
    margin-top: 5rem;
}

.header {
    font-size: 3rem;
    font-weight: bold;
}

.switch {
    margin-top: 2rem;
    margin-bottom: 5rem;
}

.subscriptionTile {
    position: relative;
    box-shadow: 0px 25px 45px #00000029;
    border-radius: 3rem;
    margin: 10px 0;
    @media (max-width: 800px) {
        width: 80%;
        min-height: 35rem;
        gap: 0px !important;
        margin: 0px !important;
    }
}

.subscriptionTileTitle {
    font-size: 20px;
    color: #333539;
    font-weight: bold;
}
.price {
    font-size: 36px;
    font-weight: bold;
    color: #333539;
}

.plansGrid {
    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        minmax(250px, 1fr)
    ); // Creates a responsive grid with columns that have a minimum width of 250px
    gap: 20px; // Spacing between grid items
    margin-top: 20px; // Space above the grid
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; // This centers the content vertically if there's enough space
    text-align: center;
    @media (max-width: 800px) {
        flex-direction: column;
        gap: 0px !important;
        margin: 0px !important;
    }
}

.benefits {
    text-align: center;
    list-style: none;
    padding: 0.5rem;
    margin: 1rem 0;
    font-weight: 400;

    li {
        margin-bottom: 5px;
        margin: 0 3rem;
        padding: 0.5rem;
        position: relative;
        color: #333539;
        font-weight: 400;
        font-size: x-large;
        text-align: left;
        padding-left: 1.5rem;
        &:before {
            top: 50%;
            left: -2rem;
            transform: translateY(-50%);
            position: absolute;
            width: 2.5rem !important;
            height: 2.5rem !important;

            @media (max-width: 800px) {
                padding-left: 2rem;
            }
        }

        @media (max-width: 800px) {
            margin: 0;
            font-size: large;
            padding-left: 4rem;
        }
    }
    @media (max-width: 800px) {
        margin-left: 3rem;
    }
}

.earlyAccess {
    &:before {
        content: url("../../resource/icon/rank_gradient.svg");
    }
}

.easilyConnect {
    &:before {
        content: url("../../resource/icon/deal.svg");
    }
}

.safeTime {
    &:before {
        content: url("../../resource/icon/booster.svg");
    }
}

.premiumOffers {
    &:before {
        content: url("../../resource/icon/letter_gradient.svg");
    }
}

.profileBadge {
    &:before {
        content: url("../../resource/icon/gradient_checkmark.svg");
    }
}

.higherVisibility {
    &:before {
        content: url("../../resource/icon/community_gradient.svg");
    }
}

.betterOffer {
    &:before {
        content: url("../../resource/icon/support_chat_gradient.svg");
    }
}

.priority {
    &:before {
        content: url("../../resource/icon/link_gradient.svg");
    }
}

.action {
    margin-top: 3rem;
    font-size: 2.25rem;
    color: #333539;
    margin-bottom: 15px;
    font-weight: bolder;
    @media (max-width: 800px) {
        margin-top: -6rem;
    }
    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

.premiumSubscriptionTile {
    padding: 1rem;
    margin: 10px 2rem;
    @media (max-width: 800px) {
        width: 80%;
        gap: 0 !important;
    }
}

.premiumSubscriptionTileTitle {
    font-size: 12px;
    color: white;
    font-weight: bold;
    background-color: lightgray;
    width: fit-content;
    padding: 0.5rem;
}

.premiumPrice {
    width: fit-content;
    font-size: 36px;
    font-weight: bold;
    color: #333539;
    margin-top: 1rem;
}

.timeFrame {
    margin-top: -1rem;
    font-size: 18px;
    color: #333539;
    font-weight: bold;
    width: fit-content;
    padding-bottom: 2rem;
}

.benefitSection {
    margin: 0 !important;
    text-align: center !important;
}
.premiumBenefits {
    list-style: none;
    padding: 0.5rem;
    margin: 1rem 0;
    height: 25rem;
    font-weight: 400;

    li {
        margin-bottom: 5px;
        margin: 0 3rem;
        padding: 1rem;
        position: relative;
        color: white;
        text-align: left;
        padding-left: 1.5rem;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: white; // Change this color based on your design
        }
        @media (max-width: 800px) {
            margin: 1rem;
        }
    }
}

.premiumAction {
    font-size: 2rem;
    color: white;
    margin-bottom: 15px;
    font-weight: 400;
    border-radius: 12px;
    background-color: #00e664;
    border-color: #00e664;
    border-width: 1.5px !important;
    border-style: solid;
    width: fit-content;
    padding: 0.25rem 3rem;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

.premiumActionMontly {
    font-size: 2rem;
    color: #00e664;
    font-weight: 400;
    margin-bottom: 15px;
    border-radius: 12px;
    border-color: #00e664;
    border-width: 1.5px !important;
    border-style: solid;
    width: fit-content;
    padding: 0.25rem 3rem;
    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}
