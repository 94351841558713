.avatar {
    height: 19.2rem;
    width: 19.2rem;
    border-radius: 15px;
    border: 5px solid #fff;
}

.banner {
    height:30rem;
    width: 90rem;
    border-radius: 15px;
    border: 5px solid #fff;
    overflow: hidden;
}
