@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../../resource/font/manrope/Manrope-ExtraLight.ttf);
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../resource/font/manrope/Manrope-Light.ttf);
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../resource/font/manrope/Manrope-Regular.ttf);
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../../resource/font/manrope/Manrope-Medium.ttf);
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../resource/font/manrope/Manrope-SemiBold.ttf);
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../resource/font/manrope/Manrope-Bold.ttf);
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../../resource/font/manrope/Manrope-ExtraBold.ttf);
}