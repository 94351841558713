.navbar {
    position: sticky;
    top: 0;

    left: 0;

    width: 100%;

    height: 56px;

    z-index: 1000;

    margin-bottom: 0;

    padding-top: .75rem;

    background: white;
    box-shadow: 0 6px 6px -6px rgba(140, 149, 159, 0.15);
}

.safe_area {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;

    padding-top: env(safe-area-inset-top, 0);
    background: white;
}

